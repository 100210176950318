<!-- // login section \\ -->
<section class="login-section">
  <div class="comman-width">
    <a routerLink="/Home" routerLinkActive="" class="logo">
      <img loading="lazy" src="../assets/images/header_logo.svg"
           alt="hdrx-logo" />
    </a>
    <form method="post" name="login_form" action="" id="loginForm">
      <input type="email" [(ngModel)]="emailmodel" name="email" id="email_" class="form-control" placeholder="you@example.com" (keyup)="removeerror($event)" autocomplete="off" required />
      <div class="colorRed" *ngIf="emptyEmail">
        Email required
      </div>
      <input type="password" name="password" id="password_" class="form-control" placeholder="Enter your client access code" (keyup)="removeerror($event)" autocomplete="off" required />
      <div class="colorRed" *ngIf="emptyPassword">
        Password required
      </div>
      <div class="forgot-access">
        <a [routerLink]="['/recoverAccessCode']" routerLinkActive="active"
          class="enrolment">Forgot Your Access Code?</a>
      </div>
      <div class="colorRed" *ngIf="incorrect">
        Incorrect Username or Password.
      </div>
      <div class="btn-section">
        <button type="submit" class="btn"
                (click)="validate()">
          Sign In
        </button>
        <div *ngIf="loader" class="load-container"><div class="theme-loader"></div></div>
      </div>
    </form>
  </div>
</section>
