<!-- // welcome section \\ -->
<section class="welcome-section download-edit aboutbox_header">
  <div class="comman-width">
    <div class="welcome-center">
      <h2>{{ secondBrandName }} now available on Apple and Android </h2>
      <span class="rated">Download our FREE top rated app</span>
      <!--
      <a href="https://apps.apple.com/us/app/honest-rx/id1439777162" target="_blank"><img class="gap" src="assets/images/app-store.png" /></a>
      <a href="https://play.google.com/store/apps/details?id=com.firebase.honest" target="_blank"><img src="assets/images/play-store.png" /></a> -->
      <div class="app-link">
        <a href="https://apps.apple.com/us/app/valpak-rx/id1494193663" target="_blank">
          <img loading="lazy" class="gap" src="assets/images/ios.svg" alt="ios" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.valpak.valpakrx" target="_blank">
          <img loading="lazy" src="assets/images/android.png" alt="android" />
        </a>
      </div>
      <!--
      <span class="get">or get the download link</span>
      <form class="download_page {{ sendmessageClass }}">
        <input type="text" class="form-control" name="mno_p" [(ngModel)]="sendno" placeholder="Enter your phone number"
          (keypress)="removemsgerr($event)" (focus)="removemsgerr($event)" autocomplete="off" maxlength="14"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
        <button type="submit" class="btn btn-hover-effect btnshover" (click)="sendmsg(sendno, '', $event)">
          <div class="insider"></div>
          Get Link
        </button>
        <span class="mar-top-2em">{{ sendmessageStatus }}</span>
      </form>
    -->
    </div>
  </div>
  <app-getcard id="daffiliate"></app-getcard>
</section>
<!-- \\ welcome section // -->

<section class="welcome-section how-works">
  <div class="comman-width">
    <div class="welcome-center">
      <div class="search">
        <span class="about">About the app</span>
        <h2>How it works</h2>
        <ul>
          <li>
            <div>
              <a class="step1-click" (click)="pharmacynavigate()" routerLinkActive="active">
                <img src="../assets/images/hero_sec_location_gray.svg" alt="hero" />
                </a>
            </div>
            <a class="step1-click" (click)="pharmacynavigate()" routerLinkActive="active">
            <span>Search for medications at pharmacies near you.</span>
            <p>
            Our network spans the entire United States and territories. Just
            enter you zip code and find the pharmacy nearest you.
            </p>
            </a>
          </li>
          <li>
            <div>
              <img src="../assets/images/hero_discount_gray.svg" alt="discount" />
            </div>
            <span>Use discount card to save up to $95 off.</span>
            <p>
              On your behalf, we’ve negotiated a discount rate with a national
              network of drugstores and retail outlets. All you have to do in
              find your medication and a discount card is generated instantly.
            </p>
          </li>
          <li>
            <div>
              <img src="../assets/images/download_header_gray.svg" alt="headerGray" />
            </div>
            <span>Show pharmacist your card at purchase.</span>
            <p>
              All you need is your phone —no print outs, no medical cards, no
              hassle— simple as that.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- // carosuel section \\ -->
<section class="carosuel-section homePageSecFour testimonial green-theme">
  <div class="comman-width">
    <div class="testimonial_inner">
      <h3>Testimonials</h3>
      <h2>What customers are saying about the app.</h2>
      <div class="row">
        <div class="col-lg-12">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig3">
            <div ngxSlickItem *ngFor="let slide of slides3" class="slide">
              <div class="shadow">
                <!-- <img src="{{ slide.img }}" alt="pharmacist" /> -->
                <h3>{{ slide.heading }}</h3>
                <p>{{ slide.text }}</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- \\ carosuel section // -->

<div class="clearfix"></div>
<!-- <app-getcard></app-getcard> -->