import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  isSidebarVisible: boolean;
  apptoHome: boolean;
  pharmacy_page: boolean;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
  apptohome: Subject<boolean> = new Subject<boolean>();
  pharmacytoapp: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.sidebarVisibilityChange.subscribe(value => {
      this.isSidebarVisible = value;
    });
    this.apptohome.subscribe(value => {
      this.apptoHome = value;
    });
    this.apptohome.subscribe(value => {
      this.pharmacy_page = value;
    });
  }
  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }
  toggleapptohome() {
    this.apptohome.next(!this.apptoHome);
  }
  togglepharmacy() {
    this.pharmacytoapp.next(!this.pharmacy_page);
  }
}
