import { Component, OnInit } from "@angular/core";
import { SaveService } from "../services/save.service";
import * as $ from "jquery";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
  providers: [SaveService]
})
export class AboutUsComponent implements OnInit {
  brandName;

  constructor(public SaveService: SaveService) {}

  ngOnInit() {
    this.brandName = this.SaveService.getBrandName();
    $(window).scroll(function() {
      if (window.location.href.indexOf("/aboutUs") > -1) {
      if (
        $(window).scrollTop() >=
        $(".honest-section.green-theme").offset().top - 100
      ) {
        $(".getCard.green-theme ").addClass("fixed_pos_card");
      } else {
        $(".getCard.green-theme ").removeClass("fixed_pos_card");
      }
    }
    });
  }
  slideToggle() {
    if (screen.width <= 767) {
      // $('.navbar-nav').slideToggle('slow');
      // $('.navbar').toggleClass('less');    
      if ($('.navbar').hasClass('less')) {
        $('#slide-icon').attr('src', 'assets/images/close.png').delay("slow").fadeIn();
      } else {
        // $('#slide-icon').attr('src','assets/images/menu.png').delay("slow").fadeIn();
      }
    }
  }
}
