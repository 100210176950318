<div class="drug-section">
  <div class="container">

    <h2>Drugs Covered by the Program</h2>
    <p *ngIf="loading">Loading drug list. Please wait...</p>

    <div class="row" *ngIf="!loading">
      <div class="sticky characters">
        <div class="character num active" (click)="scroll('#')">#</div>
        <div class="character a" (click)="scroll('a')">a</div>
        <div class="character b" (click)="scroll('b')">b</div>
        <div class="character c" (click)="scroll('c')">c</div>
        <div class="character d" (click)="scroll('d')">d</div>
        <div class="character e" (click)="scroll('e')">e</div>
        <div class="character f" (click)="scroll('f')">f</div>
        <div class="character g" (click)="scroll('g')">g</div>
        <div class="character h" (click)="scroll('h')">h</div>
        <div class="character i" (click)="scroll('i')">i</div>
        <div class="character j" (click)="scroll('j')">j</div>
        <div class="character k" (click)="scroll('k')">k</div>
        <div class="character l" (click)="scroll('l')">l</div>
        <div class="character m" (click)="scroll('m')">m</div>
        <div class="character n" (click)="scroll('n')">n</div>
        <div class="character o" (click)="scroll('o')">o</div>
        <div class="character p" (click)="scroll('p')">p</div>
        <div class="character q" (click)="scroll('q')">q</div>
        <div class="character r" (click)="scroll('r')">r</div>
        <div class="character s" (click)="scroll('s')">s</div>
        <div class="character t" (click)="scroll('t')">t</div>
        <div class="character u" (click)="scroll('u')">u</div>
        <div class="character v" (click)="scroll('v')">v</div>
        <div class="character w" (click)="scroll('w')">w</div>
        <div class="character x" (click)="scroll('x')">x</div>
        <div class="character y" (click)="scroll('y')">y</div>
        <div class="character z" (click)="scroll('z')">z</div>
      </div>
      <div class="scroll-infinite">
        <ul *ngFor="let info of details" attr.id="{{info.Sequence | lowercase}}">
          <li class="title">{{ info.Sequence }}</li>
          <li class="drug-name" *ngFor="let i of info.Drugs">
            <p>{{ i.Drug }}</p>
          </li>
          <!-- <li class="drug-name">{{ info.Drugs[10].Drug }} </li>
          <li class="drug-name">{{ info.Drugs[20].Drug }} </li>
          <li class="drug-name">{{ info.Drugs[30].Drug }} </li>
          <li class="drug-name">{{ info.Drugs[40].Drug }} </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>