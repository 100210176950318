import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { SaveService } from '../services/save.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logo;

  slideToggle() {
    if (screen.width <= 767) {
      $('.navbar-nav').slideToggle('slow');
      $('.navbar').toggleClass('less');
      if ($('.navbar').hasClass('less')) {
        $('#slide-icon').attr('src', 'assets/images/close.png').delay("slow").fadeIn();
      } else {
        // $('#slide-icon').attr('src','assets/images/menu.png').delay("slow").fadeIn();
      }
    }
  }
  constructor(private saveService: SaveService) { }
  ngOnInit() {
    $('.navbar-nav a').click(function () {
      $('.navbar-nav a').removeClass('home-active');
    });
    var path = window.location.pathname.split("/").pop();
    if (path == '') {
      $('.navbar-nav li:first-child a').addClass('home-active');
    }
    this.logo = this.saveService.getLogo();
  }
}
