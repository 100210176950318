<div id="myModal" class="modal fade green-theme-modal" role="dialog" (click)="removeErrorOut()">
  <div class="modal-dialog">

    <section class="contact-section card-modal getCardSectionOne green-card">
      <button type="button" class="btn btn-default close" data-dismiss="modal" (click)="closecard($event)">
        X
      </button>
      <div class="content getContainer" *ngIf="!cardsuccess">
        <!-- <form> -->
        <div class="getCardHeading">
          <img src="../assets/images/discount_icon.svg" alt="discount-icon" />
          <h2>Get your card.</h2>
        </div>
        <p id="getsucess">Thank you!</p>
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="first_name" value="Shane" id="first_name"
            (focus)="clearField($event)" placeholder="First Name" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="fnameerror">{{
            errormessageFName
          }}</span>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="last_name" value="Mike" id="last_name"
            (focus)="clearField($event)" placeholder="Last Name" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="lnameerror">{{
            errormessageLName
          }}</span>
        </div>
        <div class="form-group">
          <input type="email" class="form-control" value="shane.mike@gmail.com" [(ngModel)]="email" id="email"
            (focus)="clearField($event)" placeholder="Email" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="emailerror">{{
            errormessageEmail
          }}</span>
        </div>
        <button class="btn btnshover" (click)="submitCard($event)">Submit</button>
        <!-- <button class="btn" id="GetSubmit">Submit</button> -->
        <!-- </form> -->
      </div>
      <div class="showsuccess content" *ngIf="cardsuccess">
        <!-- <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
          (click)="closecard($event)"
        >
          X
        </button> -->
        <div>
          <img src="../assets/images/successicon.svg" alt="sucess" />
          <p>Your request has been submitted</p>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- <a
  href="javascript:;"
  data-toggle="modal"
  data-target="#myModal"
  class="getCard"
  ><span>Get Your Card</span></a> -->

<!-- Disabled temporarily -->
<!-- <a href="javascript:;" data-toggle="modal" data-target="#myModal" class="getCard green-theme"><span>Get your discount
    card now</span></a> -->