<!-- // header \\ -->
<div class="mobileheader_download" (click)="nagigatetodownload()">
  <img loading="lazy" width="12" height="22" src="../assets/images/mobile_mobile_header.svg" alt="" />
  Download the App for Andriod or iPhone today
  <img loading="lazy" width="8" height="8" src="../assets/images/mobile_right_arrow.svg" alt="" />
</div>
<div *ngIf="loginPage" class="loginh">
  <header class="header-section" *ngIf="daffiliate">
    <div class="comman-width menuFJC">
      <a routerLink="/Home" routerLinkActive="" aria-label="hdrx logo" class="logo">
        <img loading="lazy" src="../assets/images/Logo_ValpakRx_FullColor.png" alt="hdrx-logo" />
      </a>
      <button class="slideToggle menu_bar_btn" aria-label="menu" name="menu" (click)="humbergMenu()">
        <img loading="lazy" id="slide-icon" src="assets/images/menu_bar.svg" alt="" />
        <span>MENU</span>
      </button>
      <nav class="navbar pull-right margin-leftAuto green-theme">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a [routerLink]="['/how-it-works']" (click)="slideToggle()" routerLinkActive="active">
              How it
              Works<span>&nbsp;</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="/how-it-works/#faq" fragment="faq" (click)="slideToggle()" class="faq"  routerLinkActive="active">FAQs</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/aboutUs']" (click)="slideToggle()" routerLinkActive="active">
              About
              Us<span>&nbsp;</span>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="slideToggle(); pharmacynavigate()" routerLinkActive="active"
              [ngClass]="activepricing == true ? 'active' : ''">Pricing Tool<span>&nbsp;</span></a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/contact']" (click)="slideToggle()" routerLinkActive="active">
              Contact
              Us<span>&nbsp;</span>
            </a>
          </li>
          <li class="nav-item download">
            <a [routerLink]="['/download-mobile-app']" (click)="slideToggle()" routerLinkActive="active">
              Download Mobile
              App<span>&nbsp;</span>
            </a>
          </li>
        </ul>
      </nav>

      <!-- <div class="login" *ngIf="notLoggedin">
        <a href="javascript:;" class="signUp btnshover" (click)="openlogin_signup($event, 'sign_up')">Sign Up</a>
        <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in')" class="last signIn">Sign In</a>
      </div> -->
      <!-- Logged In header -->
      <div class="header" Id="Search_Header" *ngIf="isLoggedin">
        <div class="headermenu">
          <div class="login_btn TestGroup" style="display: none;">
            <a href="javascript:;" (click)="openlogin_signup($event, 'groupnumber')"></a>
          </div>

          <div class="userArea" (click)="hidemenubar(); $event.stopPropagation()">
            <span>Hello, {{ signeduseremailbeforeAt }}</span>
          </div>
          <!-- Dropdown Menu -->
          <div class="menu_main" [hidden]="!menubar">
            <p>General</p>
            <ul>
              <li>
                <i><img src="../assets/images/emailMyCard.svg" alt="" /></i>
                {{ sign_in_user_email }}
              </li>
              <li (click)="MySavedCards()">
                <i><img src="../assets/images/card.svg" alt="" /></i>
                My Cards
              </li>
            </ul>
            <p>Security</p>
            <ul>
              <li (click)="showchangepass($event)">
                <i><img src="../assets/images/change_pass.svg" alt="" /></i>
                Change Password
              </li>
              <li (click)="MakeSignOut()">
                <i><img src="../assets/images/logout.svg" alt="" /></i>
                Logout
              </li>
              <li class="closememu">
                <img src="../assets/images/menu_close.svg" alt="" />
                Close
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="login_btn TestGroup" style="display: none;">
        <a href="javascript:;" (click)="openlogin_signup($event, 'groupnumber')"></a>
      </div>
    </div>

    <div class="mobMenu mMenu">
      <nav>
        <div class="m-header-deny">
          <img loading="lazy" src="../assets/images/Logo_ValpakRx_FullColor.png" alt="" />

          <span class="m-close" (click)="humbergMenu()"></span>
        </div>
        <ul class="appear_menu">
          <li>
            <a [routerLink]="['/how-it-works']" (click)="slideToggle()" routerLinkActive="active"
              class="How-it-works-i">How it Works</a>
          </li>
          <li class="nav-item">
            <a href="/how-it-works/#faq" fragment="faq" (click)="slideToggle()" class="faq"  routerLinkActive="active">FAQs</a>
          </li>
          <li>
            <a [routerLink]="['/aboutUs']" (click)="slideToggle()" routerLinkActive="active" class="about-i">About
              Us</a>
          </li>
          <li>
            <a (click)="slideToggle(); pharmacynavigate()" routerLinkActive="active"
              [ngClass]="activepricing == true ? 'active' : ''">Pricing Tool</a>
          </li>
          <li>
            <a [routerLink]="['/contact']" (click)="slideToggle()" routerLinkActive="active" class="contact-i">
              Contact
              Us
            </a>
          </li>
          <li>
            <a [routerLink]="['/download-mobile-app']" (click)="slideToggle()" routerLinkActive="active"
              class="downapp-i">Download Mobile App</a>
          </li>
        </ul>
        <!-- <ul class="m-border" >
          <li>
            <a
              href="javascript:void(0)"
              class="mycard-p-i"
              (click)="MySavedCards(); humbergMenu()"
              >My Cards</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="change-p-i"
              (click)="showchangepass($event); humbergMenu()"
              >Change Password</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="logout-i"
              (click)="MakeSignOut(); humbergMenu()"
              >Logout</a
            >
          </li>
        </ul> -->
        <div class="menu_main" *ngIf="isLoggedin">
          <p>General</p>
          <ul>
            <li>
              <i><img src="../assets/images/emailMyCard.svg" alt="" /></i>
              {{ sign_in_user_email }}
            </li>
            <li (click)="MySavedCards()">
              <i><img src="../assets/images/card.svg" alt="" /></i>
              My Cards
            </li>
          </ul>
          <p>Security</p>
          <ul>
            <li (click)="showchangepass($event)">
              <i><img src="../assets/images/change_pass.svg" alt="" /></i>
              Change Password
            </li>
            <li (click)="MakeSignOut()">
              <i><img src="../assets/images/logout.svg" alt="" /></i>
              Logout
            </li>
          </ul>
        </div>
        <!-- <div class="login-link" *ngIf="notLoggedin">
          <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in'); humbergMenu()">Sign In</a>
          <a href="javascript:;" (click)="openlogin_signup($event, 'sign_up'); humbergMenu()">Sign Up</a>
        </div> -->
      </nav>
    </div>
  </header>
</div>
<header class="header-section brokerpageheader" *ngIf="daffiliate && brokerpage">
  <div class="header-content">
    <a href="javascript:void('0')" class="shape" (click)="sidebarToggle()"><span class="">&nbsp;</span><span
        class="">&nbsp;</span><span class="">&nbsp;</span></a>
    <a routerLink="/enrollmentcenter" routerLinkActive="" aria-label="hdrx logo" class="logo">
      <img loading="lazy" src="../assets/images/header_logo.svg" alt="hdrx-logo" />
    </a>
    <div class="username_sec">
      <div class="ppic">{{PicName}}</div>
      <div class="name">
        <p>{{whname}}</p>
        <p>Broker</p>
      </div>
    </div>
    <!--
      <button class="slideToggle menu_bar_btn" (click)="humbergMenu()">
        <img id="slide-icon" src="assets/images/menu_bar.svg" />
        <span>MENU</span>
      </button>
      <nav class="navbar pull-right margin-leftAuto green-theme">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a  href="/Pharmacy" target="_blank"
               [ngClass]="activepricing == true ? 'active' : ''">Pricing Tool<span>&nbsp;</span></a>
          </li>
          <li class="nav-item">

              <a href="/contact/#faq" target="_blank">FAQs </a><span>&nbsp;</span>
          </li>
        </ul>
      </nav>
  -->
    <!-- <div class="login" *ngIf="notLoggedin">
    <a href="javascript:;" class="signUp btnshover" (click)="openlogin_signup($event, 'sign_up')">Sign Up</a>
    <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in')" class="last signIn">Sign In</a>
  </div> -->
    <!-- Logged In header -->
    <div class="header" Id="Search_Header" *ngIf="isLoggedin">
      <div class="headermenu">
        <div class="login_btn TestGroup" style="display: none;">
          <a href="javascript:;" (click)="openlogin_signup($event, 'groupnumber')"></a>
        </div>

        <div class="userArea" (click)="hidemenubar(); $event.stopPropagation()">
          <span>Hello, {{ signeduseremailbeforeAt }}</span>
        </div>
        <!-- Dropdown Menu -->
        <div class="menu_main" [hidden]="!menubar">
          <p>General</p>
          <ul>
            <li>
              <i><img src="../assets/images/emailMyCard.svg" alt="" /></i>
              {{ sign_in_user_email }}
            </li>
            <li (click)="MySavedCards()">
              <i><img src="../assets/images/card.svg" alt="" /></i>
              My Cards
            </li>
          </ul>
          <p>Security</p>
          <ul>
            <li (click)="showchangepass($event)">
              <i><img src="../assets/images/change_pass.svg" alt="" /></i>
              Change Password
            </li>
            <li (click)="MakeSignOut()">
              <i><img src="../assets/images/logout.svg" alt="" /></i>
              Logout
            </li>
            <li class="closememu">
              <img src="../assets/images/menu_close.svg" alt="" />
              Close
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="login_btn TestGroup" style="display: none;">
      <a href="javascript:;" (click)="openlogin_signup($event, 'groupnumber')"></a>
    </div>
  </div>

  <div class="mobMenu mMenu">
    <nav>
      <div class="m-header-deny">
        <img loading="lazy" src="../assets/images/header_logo.svg" alt="" />

        <span class="m-close" (click)="humbergMenu()"></span>
      </div>
      <ul class="appear_menu">
        <li>
          <a [routerLink]="['/how-it-works']" (click)="slideToggle()" routerLinkActive="active"
            class="How-it-works-i">How it Works</a>
        </li>
        <li>
          <a [routerLink]="['/aboutUs']" (click)="slideToggle()" routerLinkActive="active" class="about-i">About Us</a>
        </li>
        <li>
          <a (click)="slideToggle(); pharmacynavigate()" routerLinkActive="active"
            [ngClass]="activepricing == true ? 'active' : ''">Pricing Tool</a>
        </li>
        <li>
          <a [routerLink]="['/contact']" (click)="slideToggle()" routerLinkActive="active" class="contact-i">
            Contact
            Us
          </a>
        </li>
        <li>
          <a [routerLink]="['/download-mobile-app']" (click)="slideToggle()" routerLinkActive="active"
            class="downapp-i">Download Mobile App</a>
        </li>
      </ul>
      <!-- <ul class="m-border" >
        <li>
          <a
            href="javascript:void(0)"
            class="mycard-p-i"
            (click)="MySavedCards(); humbergMenu()"
            >My Cards</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            class="change-p-i"
            (click)="showchangepass($event); humbergMenu()"
            >Change Password</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            class="logout-i"
            (click)="MakeSignOut(); humbergMenu()"
            >Logout</a
          >
        </li>
      </ul> -->
      <div class="menu_main" *ngIf="isLoggedin">
        <p>General</p>
        <ul>
          <li>
            <i><img src="../assets/images/emailMyCard.svg" alt="" /></i>
            {{ sign_in_user_email }}
          </li>
          <li (click)="MySavedCards()">
            <i><img src="../assets/images/card.svg" alt="" /></i>
            My Cards
          </li>
        </ul>
        <p>Security</p>
        <ul>
          <li (click)="showchangepass($event)">
            <i><img src="../assets/images/change_pass.svg" alt="" /></i>
            Change Password
          </li>
          <li (click)="MakeSignOut()">
            <i><img src="../assets/images/logout.svg" alt="" /></i>
            Logout
          </li>
        </ul>
      </div>
      <!-- <div class="login-link" *ngIf="notLoggedin">
        <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in'); humbergMenu()">Sign In</a>
        <a href="javascript:;" (click)="openlogin_signup($event, 'sign_up'); humbergMenu()">Sign Up</a>
      </div> -->
    </nav>
  </div>
</header>
<!-- \\ header // -->

<div *ngFor="let message of messages" class="alert alert-success">
  {{ message.text }}
</div>
<div [class.broker-exist]="brokerexist">
  <div class="sidebar" *ngIf="brokerexist">
    <div class="hide">
      <div class="username_sec">
        <div class="ppic">{{PicName}}</div>
        <div class="name">
          <p>{{whname}}</p>
          <p>Broker</p>
        </div><img class="closeSidebar" (click)="closeSidebar()" src="../assets/images/closeWhite.svg" alt="" />
      </div>
    </div>
    <ul>
      <li><a [routerLink]="['/enrollmentcenter']" (click)="slideToggle()" routerLinkActive="active"
          class="enrolment">Enrollment Center</a></li>
      <!-- <li><a href="/Pharmacy" target="_blank" [ngClass]="activepricing == true ? 'active pricing' : 'pricing'">Pricing Tool</a></li> -->
      <li><a href="javascript:;"  (click)="slideToggle();pharmacynavigate()"  [ngClass]="activepricing == true ? 'active pricing' : 'pricing'">Pricing Tool</a></li>
      <li><a href="https://reporting.glichealth.com/" target="_blank" (click)="slideToggle()" class="dashboard">Reporting Dashboard</a></li>
      <li><a [routerLink]="['/guidebook-and-resources']" (click)="slideToggle()" routerLinkActive="active" class="marketing">Guidebook and Resources</a></li> 
      <li><a href="http://glichealth.go.customprintcenter.com/register" target="_blank"  class="physical">Order Physical Cards</a></li>
      <li><a href="/how-it-works/#faq" fragment="faq" (click)="slideToggle()" target="_blank"  class="faq"  routerLinkActive="active">FAQ’s</a></li>
      <li><a href="javascript:;" (click)="MakeSignOut()" (click)="slideToggle()" class="logout">Logout</a></li>
    </ul>
    <div class="agent-support">
      <h4>Agent Support Contacts</h4>
      <address>
        <span>Payment Questions</span>
        <a href="mailto:{{support_email}}">{{support_email}}</a><br />
        <a class="phone" href=tel:{{support_phone}}>{{support_phone}}</a>
      </address>
      <address>
        <span>ValpakRx™ Program</span>
        <a href="mailto:agentsupport@glichealth.com">agentsupport@glichealth.com</a><br />
        <a class="phone" href=tel:844-630-8300>844-630-8300</a>
      </address>
    </div>
  </div>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <div *ngIf="loginPage">
    <app-footer *ngIf="daffiliate"></app-footer>
  </div>
  <!-- <app-homepage></app-homepage> -->
</div>
<!-- <div *ngIf="!brokerexist">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <div *ngIf="loginPage">
    <app-footer *ngIf="daffiliate"></app-footer>
  </div>
</div> -->

<!-- Tabs panel -->
<div class="signupbox" *ngIf="showloginbox">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="removesignup($event)"></div>
    <!-- <div class="background_area">

    </div> -->
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <p *ngIf="signup_fields" class="skip_signup" (click)="SkipSignUp()">Skip Signup</p>
        <h2 *ngIf="signup_fields">Sign up for {{ _AppName }}.</h2>
        <h2 *ngIf="signin_fields">Sign into {{ _AppName }}.</h2>
        <nav>
          <a href="javascript:;" (click)="openlogin_signup($event, 'sign_up')" [class.active]="signup_fields">Sign
            Up</a>
          <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in')" [class.active]="signin_fields">Sign
            In</a>
        </nav>
        <div class="signup_parent" *ngIf="signup_fields">
          <div class="form_sec">
            <!-- <div class="skip_btn" (click)="SkipSignUp()">Skip Sign up</div> -->

            <div class="field">
              <input type="email" name="email_signup" id="email_signup" placeholder="Enter Your Email"
                [(ngModel)]="sign_up_email" autocomplete="off" (keyup)="Keyup_ClearField($event)"
                (blur)="OnBlur_CheckPattern($event)" />
              <!-- <label for="email_signup">Email Address</label> -->
            </div>
            <!-- <span>{{  }}</span> -->
            <div class="field">
              <input [type]="show_password ? 'text' : 'password'" name="password_signup" id="password_signup"
                [(ngModel)]="sign_up_password" minlength="6" maxlength="20" autocomplete="off"
                placeholder="Create a Password" (keyup)="Keyup_ClearField($event)" (blur)="OnBlur_CheckPattern($event)"
                (keypress)="StopWhiteSpace($event)" />
              <span class="show_password" (click)="showPassword()">{{show_password_msg}}</span>

              <!-- <label for="password_signup">Password</label> -->
            </div>
            <!-- <span>{{ sign_up_password_error }}</span> -->
            <div class="field">
              <input [type]="show_cpassword ? 'text' : 'password'" name="c_password_signup" id="c_password_signup"
                [(ngModel)]="sign_up_cpassword" minlength="6" maxlength="20" autocomplete="off"
                placeholder="Retype Password" (keyup)="Keyup_ClearField($event)" (blur)="OnBlur_CheckPattern($event)"
                (keypress)="StopWhiteSpace($event)" />
              <span class="show_password" (click)="showCPassword()">{{show_cpassword_msg}}</span>

              <!-- <label for="c_password_signup">Confirm Password</label> -->
            </div>
            <!-- <span>{{ sign_up_cpassword_error }}</span> -->
          </div>
          <p class="tncbox">
            By clicking "Sign Up" below, you confirm that you accept the
            <a [routerLink]="['/terms-and-conditions']" target="_blank">Terms and Conditions of Use</a>.
          </p>
          <div class="signup_btn_sec">
            <div class="already">
              Actually, I already have an account.
              <a href="javascript:;" (click)="openlogin_signup($event, 'sign_in')">Sign In here</a>
            </div>
            <div class="btns btnshover" (click)="MakeSignUp()">
              Sign Up
            </div>
          </div>
          <div class="Login_Signup_Error" *ngIf="
              sign_up_email_error ||
              sign_up_error ||
              sign_up_password_error ||
              sign_up_cpassword_error
            ">
            Oops, something is incorrect please check your email or password.
          </div>
        </div>

        <div class="signin_parent" *ngIf="signin_fields">
          <div class="form_sec">
            <!-- <h2>Sign In to {{ _AppName }}</h2> -->
            <div class="field">
              <input type="email" name="email_signin" id="email_signin" placeholder="Enter Your Email"
                [(ngModel)]="sign_in_email" autocomplete="off" (keyup)="Keyup_ClearField($event)"
                (blur)="OnBlur_CheckPattern($event)" />
              <!-- <label for="email_signin">Enter Your Email</label> -->
            </div>
            <!-- <span>{{ sign_in_email_error }}</span> -->
            <div class="field">
              <input [type]="show_signin_password ? 'text' : 'password'" name="password_signin" id="password_signin"
                placeholder="Type Password" [(ngModel)]="sign_in_password" (keyup)="Keyup_ClearField($event)"
                (blur)="OnBlur_CheckPattern($event)" autocomplete="off" (keypress)="StopWhiteSpace($event)" />
              <span class="show_password" (click)="showSigninPassword()">{{show_signin_password_msg}}</span>

              <!-- <label for="password_signin">Password</label> -->
            </div>
            <!-- <span>{{ sign_in_password_error }}</span> -->
          </div>
          <a href="javascript:;" class="forgot_btn" (click)="ForgotPassword()">I forgot my <span>password</span>.</a>
          <div class="signup_btn_sec">
            <div class="already">
              Actually, I don’t have an account.
              <a href="javascript:;" (click)="openlogin_signup($event, 'sign_up')">Sign up here</a>
            </div>
            <div class="btns btnshover" (click)="MakeSignIn()">
              Sign In
            </div>
          </div>
          <!-- <div class="Login_Signup_Error">{{ sign_in_error }}</div> -->
          <div class="Login_Signup_Error" *ngIf="
              sign_in_email_error || sign_in_error || sign_in_password_error
            ">
            Oops, something is incorrect please check your email or password.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="signupbox groupnumber" *ngIf="groupnumber">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="removeGroupNumber($event)"></div>
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <img loading="lazy" src="../assets/images/Logo_ValpakRx_FullColor.png" width="190" alt="hdrx-logo" />
        <h3>Enter Your Details</h3>
        <div class="signup_parent">
          <div class="form_sec">
            <div class="changepassword_main">
              <div class="input_box">
                <div class="field">
                  <input type="text" id="currentFistName" name="currentFullname" placeholder="First Name"
                    [(ngModel)]="current_first_name" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                </div>
                <div class="field">
                  <input type="text" id="currentLastName" name="currentFullname" placeholder="Last Name"
                    [(ngModel)]="current_last_name" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                </div>

                <div class="field">
                  <input type="text" id="currentEmail" name="currentEmail" placeholder="Email"
                    [(ngModel)]="current_email" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                </div>

                <div class="field" *ngIf="group_number">
                  <input type="text" id="currentGroupNumber" name="currentGroupNumber" placeholder="Access Code"
                    [(ngModel)]="current_group_number" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                </div>
                <div class="reset_button change_gno_btn reset_text" (click)="setDefaultGroup()" *ngIf="group_number">
                  {{"Don't have an access code?"}}
                </div>
                <div class="reset_button change_gno_btn" [ngClass]="{ less_top: group_number === true }" (click)="setGroupNumber()">
                  {{'Submit'}}
                </div>
              </div>
              <div class="disclaimerrr">
                <b>ValpakRx™ is <span style="text-decoration: underline;">not</span> insurance.</b> By clicking above you are agreeing to our terms and conditions and privacy
                policy, which can be found  <a href="https://valpakrx.com/terms-and-conditions">here</a>.
              </div>
              <div class="Login_Signup_Error" *ngIf="incorrect_fullname">
                Please enter correct details
              </div>
              <div class="Login_Signup_Error" *ngIf="incorrect_email">
                Please enter valid Email
              </div>
              <div class="Login_Signup_Error" *ngIf="incorrect_groupnumber">
                Please enter a valid access code
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="success_group_validate" *ngIf="success_group_validate">
  <p>
    {{!current_group_number ? 'Enter Access Code' : 'Submit'}}

  </p>
</div>
<!-- Change Password Box -->
<div class="signupbox change_password" *ngIf="changepassword">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="removechangepass($event)"></div>
    <!-- <div class="background_area">
 
    </div> -->
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <h2>Change Password</h2>
        <div class="signup_parent">
          <div class="form_sec">
            <div class="changepassword_main">
              <div class="input_box">
                <div class="field">
                  <input type="password" id="currentpass" name="currentpass" placeholder="Current Password"
                    [(ngModel)]="current_password" autocomplete="off" (keyup)="Keyup_ClearField($event)"
                    (blur)="OnBlur_ChangePassword($event)" (keypress)="StopWhiteSpace($event)" />
                  <!-- <label for="currentpass">Current Password</label> -->
                  <i (click)="changeIcon('1')" id="status1"></i>
                </div>
                <!-- <span>{{ current_password_error }}</span> -->
                <div class="field">
                  <input type="password" id="newpass" name="newpass" placeholder="New Password"
                    [(ngModel)]="new_password" autocomplete="off" (keyup)="Keyup_ClearField($event)"
                    (blur)="OnBlur_ChangePassword($event)" minlength="6" maxlength="20"
                    (keypress)="StopWhiteSpace($event)" />
                  <label for="newpass">New Password</label>
                  <i (click)="changeIcon('2')" id="status2"></i>
                </div>
                <!-- <span>{{ new_password_error }}</span> -->
                <div class="field">
                  <input type="password" id="confirmpass" name="confirmpass" placeholder="Retype New Password"
                    [(ngModel)]="c_new_password" minlength="6" maxlength="20" autocomplete="off"
                    (keyup)="Keyup_ClearField($event)" (keypress)="StopWhiteSpace($event)" />
                  <label for="confirmpass">Confirm New Password</label>
                  <i (click)="changeIcon('3')" id="status3"></i>
                </div>
                <!-- <span>{{ c_new_password_error }}</span> -->
                <!-- <span style="color:red">{{ change_password_error }}</span> -->
                <div class="reset_button change_pwd_btn" (click)="UpdatePassWord()">
                  Reset
                </div>
              </div>
              <div class="Login_Signup_Error" *ngIf="
                  change_password_error ||
                  new_password_error ||
                  current_password_error ||
                  c_new_password_error
                ">
                Oops, something is incorrect please check your current, new or
                confirm password.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- success message after change password -->
<div *ngIf="mobsuccess" class="mobsuccess">
  <!-- <div class="mobsuccess"> -->
  <div>
    <img src="../assets/images/mob-success.svg" alt="" />
    <P>Your password has been changed successfuly</P>
    <span (click)="mobCloseToggle()">Go to Sign In</span>
  </div>
</div>
<!-- Forgot Password Box -->
<div class="signupbox change_password" *ngIf="forgotpassword_box">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="removeforgot($event)"></div>
    <!-- <div class="background_area">

    </div> -->
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <div class="signup_parent">
          <div class="form_sec">
            <div class="changepassword_main forgot_pass">
              <h2>Oops, I forgot my password again.</h2>
              <div>
                <div class="field">
                  <div>
                    <input type="email" id="forgotemail" name="forgotemail" placeholder="Enter Your Email"
                      [(ngModel)]="forgot_email" autocomplete="off" (click)="Keyup_ClearField($event)"
                      (keyup)="Keyup_ClearField($event)" (blur)="OnBlur_ChangePassword($event)" />
                    <!-- <label for="forgotemail">Email</label> -->
                    <!-- <span style="color:red">{{ forgot_email_error }}</span> -->
                  </div>
                </div>
                <div *ngIf="otp_sent">
                  <div class="field">
                    <input type="text" id="OTP" name="OTP" placeholder="Enter OTP" [(ngModel)]="input_otp"
                      autocomplete="off" (click)="Keyup_ClearField($event)" (keyup)="Keyup_ClearField($event)"
                      (blur)="OnBlur_ChangePassword($event)" />
                    <!-- <label for="OTP">Enter OTP</label> -->
                    <!-- <span style="color:red">{{ input_otp_error }}</span> -->
                  </div>
                </div>
                <div class="reset_button btnshover" (click)="Submit_Email_OTP()">
                  Submit
                </div>
              </div>
              <div class="Login_Signup_Error" *ngIf="input_otp_error || forgot_email_error">
                Oops, something is incorrect
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-------Group Number Box------------>
<!-- <div class="change_password" *ngIf="Group_Number_Box">
   <div class="close_search_pharmacy_popup" (click)="removegroup($event)"></div> 
  <div class="changepassword_main forgot_pass group_Number__box">
    <div class="skip_btn" (click)="SkipGroupNumber()">Skip Group Number</div>
    <h2>Group Number</h2>
    <div class="input_box">
      <div>
        <span class="staticgroup">HDRX</span><input type="number" id="InputGroup" name="InputGroup" placeholder=" "
          [(ngModel)]="InputGroupNumber" (click)="ClearErrorMessage_InputGroup()" (keyup)="Keyup_ClearField($event)"
          (keypress)="_GrnumberValidate($event)" />
        <label for="currentpass">Group Number</label>
      </div>
      <span>{{ input_groupnumber_error }}</span>
      <div class="reset_button" (click)="SubmitGroupNumber()">Submit</div>
    </div>
  </div>
</div> -->
<!------------------Reset Password---------------->
<div class="signupbox change_password" *ngIf="resetpassword_Box">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="removechangepass($event)"></div>
    <!-- <div class="background_area">

    </div> -->
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <h2>Oops, I forgot my password again.</h2>
        <div class="signup_parent">
          <div class="form_sec">
            <div class="changepassword_main resetpass_box">
              <!-- <h2>Reset Password</h2> -->
              <div class="input_box">
                <div class="field">
                  <input type="password" id="resetnewpass" name="resetnewpass" placeholder="Create a New Password"
                    [(ngModel)]="reset_new_password" autocomplete="off" (keyup)="Keyup_ClearField($event)"
                    (blur)="OnBlur_ChangePassword($event)" minlength="6" maxlength="20"
                    (keypress)="StopWhiteSpace($event)" />
                  <!-- <label for="newpass">New Password</label> -->
                  <i (click)="changeIcon('4')" id="status4"></i>
                </div>
                <!-- <span>{{ reset_new_password_error }}</span> -->
                <div class="field">
                  <input type="password" id="resetconfirmpass" name="resetconfirmpass" placeholder="Retype New Password"
                    autocomplete="off" minlength="6" maxlength="20" [(ngModel)]="reset_c_new_password"
                    (keyup)="Keyup_ClearField($event)" (keypress)="StopWhiteSpace($event)" />
                  <!-- <label for="resetconfirmpass">Confirm New Password</label> -->
                  <i (click)="changeIcon('5')" id="status5"></i>
                </div>
                <!-- <span>{{ reset_c_new_password_error }}</span> -->
                <!-- <span style="color:red">{{ reset_update_password_error }}</span> -->
                <div class="reset_button btnshover" (click)="ResetPassword()">
                  Save New Password
                </div>
              </div>
              <div class="Login_Signup_Error" *ngIf="
                  reset_update_password_error ||
                  reset_c_new_password_error ||
                  reset_new_password_error
                ">
                Oops, something is incorrect please check new or confirm new
                password
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Search pharmacy popup-->
<div class="top_search">
  <div class="search_pharmacy_popup" *ngIf="ShowDrugdetails">
    <div class="close_search_pharmacy_popup" (click)="CloseDrugDetails()"></div>
    <div class="search_pharmacy_select">
      <h2>{{ SelectedDrug }}</h2>
      <div class="filter_box">
        <div class="lists_box" [ngClass]="{ singleDoseForm: DrugNameTypeSingle }">
          <div (click)="hideme[0] = !hideme[0]; $event.stopPropagation()">
            <label>Type</label>
            <div class="filtertext">
              {{ SelectedDrug }}
            </div>
            <i></i>
          </div>
          <div class="lists" [hidden]="!hideme[0]">
            <ul>
              <li *ngFor="let item of DrugTypeList" (click)="addActive_select($event, 0, item)"
                [ngClass]="{ active: item == Swap_Drug_With_MultiSource }">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lists_box" [ngClass]="{ singleDoseForm: DrugFormSingle }">
          <div (click)="hideme[1] = !hideme[1]; $event.stopPropagation()">
            <label>Form</label>
            <div class="filtertext">
              {{ DrugType }}
            </div>
            <i></i>
          </div>
          <div class="lists" [hidden]="!hideme[1]">
            <ul>
              <li *ngFor="let item of optionlist" (click)="addActive_select($event, 1, item.text)"
                [ngClass]="{ active: item.text == DrugType }">
                {{ item.text }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lists_box" [ngClass]="{ singleDoseForm: DrugDoseSingle }">
          <div (click)="hideme[2] = !hideme[2]; $event.stopPropagation()">
            <label>Dosage</label>
            <div class="filtertext">
              {{ DrugDose }}
            </div>
            <i></i>
          </div>
          <div class="lists" [hidden]="!hideme[2]">
            <ul>
              <li *ngFor="let item of DrugDoseList" (click)="addActive_select($event, 2, item)"
                [ngClass]="{ active: item == DrugDose }">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lists_box">
          <div (click)="hideme[3] = !hideme[3]; $event.stopPropagation()">
            <label>Quantity</label>
            <div class="filtertext">
              {{ DrugQuant }}
            </div>
            <i></i>
          </div>
          <div class="lists" [hidden]="!hideme[3]" hidden>
            <ul>
              <li *ngFor="let item of DrugQuantityList" (click)="addActive_select($event, 3, item)"
                [ngClass]="{ active: item == DrugQuant }">
                {{ item }}
              </li>
              <li (click)="addActive_select($event, -3, QuantityManual)">
                Enter a Quantity
              </li>
            </ul>
          </div>
        </div>
        <div class="lists_box PharmacyNear">
          <div>
            <label>Pharmacies Near</label>

            <!-- <div class="filtertext">Oakland, CA 94618, USA</div> -->
            <div class="filtertext">
              <input type="tel" id="getzipcodeTop" placeholder="Type zipcode" value="{{ _CurrLocation }}"
                [(ngModel)]="_UserZip" maxlength="5" (click)="ClearErrorMessage()" (blur)="ClearErrorClass($event)"
                (keyup)="zipCodeLenthInit()" (keypress)="ZipCodeValidate($event)" />
            </div>
            <!-- <i></i> -->
            <span>{{ ZipCodeMessage }}</span>
          </div>
        </div>
        <div class="autopic_location">
          <input type="checkbox" id="pick_location" (change)="GetAutoLocation($event)" />
          <label for="pick_location"><span></span>Auto pick my location</label>
        </div>
        <div class="find_button" (click)="Redirect_LoadPharmacy()">
          Find Lowest Price
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Enter Quantity Manual Box -->
<div class="change_password" *ngIf="ManualQuantity_box">
  <!-- <div class="close_search_pharmacy_popup" (click)="removeforgot($event)"></div> -->
  <div class="changepassword_main forgot_pass">
    <h2>Enter a quantity</h2>
    <div class="input_box">
      <div>
        <input type="number" id="Quantity_Manual" name="Quantity_Manual" placeholder=" " [(ngModel)]="Quantity_Manual"
          (keypress)="DrugQuantityValidate($event)" />
        <label for="Quantity_Manual">Quantity</label>
      </div>
      <span id="Quant_Error">{{ Quantity_error }}</span>
      <div class="reset_button" (click)="SubmitQuantity()">Submit</div>
    </div>
  </div>
</div>
<!-- <div *ngIf="this.ms.toggle"></div> -->
<!-- <app-getcard id="daffiliate"></app-getcard> -->
<!-- <app-getcard *ngIf="this.ms.toggle"></app-getcard> -->
<!-- main pop-up -->
<div class="main-popup" *ngIf="AffiliatePopUp">
  <div class="popup-inner">
    <span class="close-btn"><img src="../assets/images/closeicon.svg" alt="" (click)="Close_Affiliate()" /></span>
    <div class="heading">
      <h3>A Special Message</h3>
      <h2>{{ PopDescText }}</h2>
    </div>

    <div class="coupan-image">
      <img [src]="imagetransform()" alt="" />
    </div>
    <button aria-label="saving" name="saving" class="start-saving" (click)="Close_Affiliate()">
      START SAVING
    </button>
  </div>
</div>
<!-- main pop-up -->