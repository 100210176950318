<!-- // footer section \\ -->
<section class="footer-section">
  <div class="comman-width">
    <div class="footerlogo">
      <a class="cool-link" [routerLink]="['/Home']" routerLinkActive="active" (click)="slideToggle()">
        <img loading="lazy" src="../assets/images/footerlogo.png" alt="" />
      </a>
    </div>
    <div class="">
      <div class="footer-adjust">
        <div class="first">
          <!-- <h2 class="HonestDiscountFooter">{{ brandName }}</h2> -->
          <ul class="footer-nav">
            <li>
              <a class="cool-link" routerLink="/aboutUs" (click)="slideToggle()" routerLinkActive="active">
                About
                Us<span>&nbsp;</span>
              </a>
            </li>
            <!--
            <li class="nav-item">
              <a class="cool-link" [routerLink]="['/how-it-works']" (click)="slideToggle()" routerLinkActive="active">
                How it
                Works<span>&nbsp;</span>
              </a>
            </li>
            -->
            <li>
              <a class="cool-link" routerLink="/contact" (click)="slideToggle()" routerLinkActive="active">
                Contact
                Us<span>&nbsp;</span>
              </a>
            </li>
            <li>
              <a class="cool-link" [routerLink]="['/MyCards']" routerLinkActive="active">My Cards<span>&nbsp;</span></a>
            </li>
            <!--
            <li>
              <a class="cool-link" [routerLink]="['/compliance']" routerLinkActive="active">
                Compliance
                Hotline<span>&nbsp;</span>
              </a>
            </li>
            -->
          </ul>
        </div>
        <div class="second">
          <ul>
            <li>
              <a class="cool-link" [routerLink]="['/terms-and-conditions']" (click)="slideToggle()"
                routerLinkActive="active">Terms of Use<span>&nbsp;</span></a>
            </li>
            <li>
              <a class="cool-link" href="https://www.valpak.com/" target="_blank">Valpak<span>&nbsp;</span></a>
            </li>
            <!--
            <li>
              <a class="cool-link" [routerLink]="['/privacy-policy']" (click)="slideToggle()"
                routerLinkActive="active">Privacy Policy<span>&nbsp;</span></a>
            </li>
            <li>
              <a class="cool-link" [routerLink]="['/program-description']" (click)="slideToggle()"
                routerLinkActive="active">Program Description<span>&nbsp;</span></a>
            </li>
            <li>
              <a class="cool-link" [routerLink]="['/drugs-covered']" (click)="slideToggle()"
                routerLinkActive="active">Drugs Covered<span>&nbsp;</span></a>
            </li>
            <li>
              <a class="cool-link" href="assets/Glic.CCPA.Privacy.Notice.at.Collection.pdf" target="_blank">Consumer CCPA Privacy Notice at Collection<span>&nbsp;</span></a>
            </li>
            -->
            <!-- <li>
              <a
                class="cool-link"
                [routerLink]="['/contact']"
                (click)="slideToggle()"
                routerLinkActive="active"
                >Help<span>&nbsp;</span></a
              >
            </li> -->
          </ul>
        </div>
        <div class="third">
          <h2>Follow ValpakRx™</h2>
          <ul class="social">
            <li>
              <a class="cool-link" href="https://www.facebook.com/Valpak/" target="_blank">
                <img loading="lazy" width="33" height="33" alt="facebook" src="../assets/images/facebookfooter.svg" />
                Facebook</a>
            </li>
            <!-- <li>
              <img src="../../../assets/images/linked-in.svg" />
              <a class="cool-link" href="#" target="_blank">Linked In</a>
            </li> -->
            <!-- <li>
              <img src="../../../assets/images/twitter.svg" />
              <a class="cool-link" href="#" target="_blank">Twitter</a>
            </li> -->
            <li>
              <a class="cool-link" href="mailto:support@valpakrx.com">
                <img loading="lazy" width="33" height="33" alt="email-icon" src="../assets/images/emailfooter.svg" />
                Email</a>
            </li>
          </ul>
        </div>
        <div class="fourth">
          <h2>ValpakRx™ on Your Phone</h2>
          <div class="socialbtns">
            <a href="https://apps.apple.com/us/app/valpak-rx/id1494193663" target="_blank">
              <img loading="lazy" width="22" height="25" src="../assets/images/itunesfooter.svg" alt="" />
              iTunes
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.valpak.valpakrx" target="_blank">
              <img loading="lazy" width="20" height="22" src="../assets/images/playstorefooter.svg" alt="" />
              Google Play
            </a>
          </div>
          <!--
          <div class="walletDiv">
            <div class="discountWallet" (click)="openpass(applegenericpassval)"  *ngIf="ios">
              <img class="google-wallet" src="../assets/images/apple-wallet.png">
            </div>
            <div class="discountWallet google-wall" (click)="openpass(googlegenericpassval)" *ngIf="android">
              <img src="../assets/images/google-wallet.png">
            </div>    
          </div>  
        -->
        </div>
      </div>
    </div>
  </div>

  <div class="copyright">
    <div class="comman-width">
      <ul>
        <!-- <li>
          <a class="cool-link" href="#">Terms</a>
        </li>
        <li>
          <a class="cool-link" href="#">Privacy</a>
        </li> -->
        <!-- <li>
          <a class="cool-link" [routerLink]="['/domain-affiliate']">Domain Affiliate</a>
        </li> -->
        <li>Pharmacy names, logos, brands, and other trademarks are the property of their respective owners.</li>
          <li><b>The ValpakRx™ prescription discount program is NOT insurance.</b> It is also not a Medicare prescription drug plan. The range of prescription discounts provided under this discount program will vary depending on the prescription and pharmacy where the prescription is purchased and can be up to $95 off the retail price. <b>Purchases using the ValpakRx™ program do not count towards insurance or Medicare deductibles or out-of-pocket maximums.</b> You are fully responsible for paying your prescriptions at the pharmacy at the time of service, but you will be entitled to receive a discount from the pharmacy in accordance with the specific pre-negotiated discounted rate schedule. Glic Health LLC is the Administrator of the ValpakRx™ prescription discount program with its office located at One Marina Park Drive, Suite 1410, Boston MA 02210. Toll Free at <a href="tel: (844) 944-4542">(844) 944-4542</a> and email at <a target="_blank" href="mailto: support@glichealth.com">support@glichealth.com.</a> For additional information, including an up-to-date list of pharmacies and covered prescriptions, or assistance with any problems related to this prescription drug discount plan, please contact our member support line toll free at <a href="tel: (800) 984-4031">(800) 984-4031</a> or visit valpakrx.com. By using the ValpakRx™ prescription discount program or app, you agree to the Glic Health Terms and Conditions found at <a [routerLink]="['/terms-and-conditions']" (click)="slideToggle()"
            routerLinkActive="active">https://www.valpakrx.com/terms.</a></li>
          <li>© {{currentYear}} Glic Health LLC. All Rights Reserved.</li>
      </ul>
    </div>
  </div>
</section>
<!-- \\ footer section // -->

<!-- // Email popup \\ -->
<div class="signupbox groupnumber sharepass" *ngIf="showemailpopup">
  <div class="Signup_login">
    <div class="close_search_pharmacy_popup" (click)="appleWalletPopup()"></div>
    <div class="form_area">
      <div class="signup_signin_parent_box">
        <h3>Share Pass</h3>
        <div class="signup_parent">
          <div class="form_sec">
            <div class="changepassword_main">
              <p>Please share your email below to receive pass link on email</p>
              <div class="email_field" style="position: relative">
                <input type="email" id="UserInputEmailcard" name="UserInputEmailcard" placeholder="Enter your E-mail" [(ngModel)]="UserInputEmailcard"
                  (click)="ClearMessage($event)" (blur)="ClearMessage($event)" (keypress)="ClearMessage()"
                  autocomplete="off" />
                <div class="reset_button change_gno_btn" (click)="SharePassonemail()">{{Sendtext}}</div>
                <div class="loadProcess"></div>
              </div>
              <p class="error" *ngIf="UserInputEmailcarderror !=''">{{UserInputEmailcarderror}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- \\ Email popup // -->
