<section class="card-section termsofuse green-theme">
  <div class="comman-width" style="padding-bottom: 4em">
    <div class="over">
      <h2>Compliance Hotline</h2>
    </div>
    <!-- <h3 class="terms">Compliance Hotline</h3> -->
    <div class="repeat">
      <p>
        At Glic Health, we take compliance very seriously.  If you have any questions or concerns concerning the
        distribution or use of our discount program, please contact <a class="u"
          href="mailto:compliance@glichealth.com">compliance@glichealth.com</a>
      </p>
      <p>If you would prefer to make a confidential/anonymous report, please feel free to use one of these reporting
        options:</p>
    </div>

    <ul>
      <li>
        <b>Website</b>
      </li>
      <li>
        <a href="https://www.lighthouse-services.com/glichealth"
          target="_blank">www.lighthouse-services.com/glichealth</a>
      </li>
      <li>
        <b>Toll-Free Telephone</b>
      </li>
      <li>English speaking: <a href="tel://833-401-0002">833-401-0002</a></li>
      <li>Spanish speaking: <a href="tel://800-216-1288">800-216-1288</a></li>
    </ul>

  </div>
</section>