import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { MedicinService } from "../services/index";
import { map } from "rxjs/operators";
import { SaveService } from "../services/save.service";
import { from } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-domain-affiliate",
  templateUrl: "./domain-affiliate.component.html",
  styleUrls: ["./domain-affiliate.component.scss"],
})
export class DomainAffiliateComponent implements OnInit {
  public vender: any;
  public group: any;
  public pcn: any;
  public subText: any;
  public sucess_message: any;
  public logo: any;
  public stext: any;
  public domainAffilateData: any;
  public searchInput: any;
  //--------------Image Upload Dilip start Here--------------------
  public imageUrl: any =
    "https://www.freeiconspng.com/uploads/document-file-page-paper-sheet-up-upload-icon-3.png";
  editFile: boolean = true;
  removeUpload: boolean = false;
  public CurrentImageBase64: string = "";
  public CurrentPDFBase64: string = "";
  public CurrentPDFId: string = "";
  public DescText: any;
  //--------------Image Upload Dilip end Here--------------------
  constructor(
    public ms: MedicinService,
    public SaveService: SaveService,
    private cd: ChangeDetectorRef,
    public fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    this.logo = this.SaveService.getLogo();
    this.getData();
  }

  transform(base64Image) {
    // ;
    if (base64Image) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/png;base64, " + base64Image
      );
    }
  }
  onSubmit() {
    this.vender = $("#vender").val();
    this.group = $("#group").val();
    this.pcn = $("#pcn").val();
    this.subText = $("#subText").val();
    this.DescText = $("#descText").val();
    if (!this.vender) {
      $("#vender").addClass("error");
      $("#vender").next().show();
    } else {
      $("#vender").removeClass("error");
      $("#vender").next().hide();
    }
    if (!this.group) {
      $("#group").addClass("error");
      $("#group").next().show();
    } else {
      $("#group").removeClass("error");
      $("#group").next().hide();
    }
    if (!this.pcn) {
      $("#pcn").addClass("error");
      $("#pcn").next().show();
    } else {
      $("#pcn").removeClass("error");
      $("#pcn").next().hide();
    }

    if (!this.DescText) {
      $("#descText").addClass("error");
      $("#descText").next().show();
    } else {
      $("#descText").removeClass("error");
      $("#descText").next().hide();
    }

    if (!this.CurrentImageBase64) {
      $("#imageUpload").addClass("error");
      $("#imageUpload").next().show();
    } else {
      $("#imageUpload").removeClass("error");
      $("#imageUpload").next().hide();
    }

    if (!this.subText) {
      $("#subText").addClass("error");
      $("#subText").next().show().text("Affiliate text is required");
      return false;
    }
    if (this.subText.length < 3) {
      $("#subText").addClass("error");
      $("#subText").next().show().text("Minimum enter 3 letter");
      return false;
    } else {
      $("#subText").removeClass("error");
      $("#subText").next().hide();
    }
    $(".sucess").fadeIn("fast");
    this.ms
      .PostDomainAffiliate(
        this.vender,
        this.group,
        this.pcn,
        this.subText,
        this.CurrentImageBase64,
        this.DescText
      )
      .pipe(map((res) => res.json()))
      .subscribe((data) => {
        if (data.length > 0) {
          if ((data[0].StatusCode = "1")) {
            // this.IsSave = "Save";
            this.sucess_message = data[0].StatusMessage;
            this.domainAffilateData = [];
            this.getData();
            $("input[type=text], textarea").val("");
            setTimeout(function () {
              $(".sucess").fadeOut("fast");
            }, 2000);
          } else {
            //error
          }
        } else {
          //error
        }
      });
  }
  openpdf(ftext){
    this.ms.OpenPdf(ftext)
    // .pipe(map((res) => res.json()))
    .subscribe((data) => {
      // ;
      let pdfWindow = window.open("")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(data[0].pdf64) + "'></iframe>"
    )

    });
  }
  clearMsg(val) {
    let idGet = "#" + val;
    $(idGet).removeClass("error");
    $(idGet).next().hide();
  }
  getData() {
    this.stext = "";
    this.ms
      .GetDomainAffiliate(this.stext)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        if (data.length > 0) {
          ;
          this.domainAffilateData = data;
        } else {
        }
      });
  }
  onSearch(value) {
    this.stext = value;
    if (this.stext.length < 3) {
      $("#searchID").addClass("error");
      $("#searchID").next().show().css({ left: "0" });
      return false;
    } else {
      $("#searchID").removeClass("error");
      $("#searchID").next().hide();
    }
    this.ms
      .SearchDomainAffiliate(this.stext)
      // .pipe(map((res) => res.json()))
      .subscribe((res) => {
        if (res.length > 0) {
          this.domainAffilateData = res;
        } else {
        }
      });
  }

  //////---------------------------Upload Image------------------
  uploadFile(event) {
    let reader = new FileReader();
    let file1 = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file1);
      reader.onload = () => {
        this.imageUrl = reader.result;

        this.editFile = false;
        this.removeUpload = true;
      };
      reader.onloadend = this._handleReaderLoaded.bind(this);
      this.cd.markForCheck();
    }
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.CurrentImageBase64 = reader.result.substr(
      reader.result.indexOf(",") + 1
    );
  }
  //------------------------Upload PDF Handling------------------------
  uploadPdf(event) {
    this.CurrentPDFId = event.target.id;
    let reader = new FileReader();
    let file1 = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file1);
      reader.onload = () => {
        this.imageUrl = reader.result;

        this.editFile = false;
        this.removeUpload = true;
      };
      reader.onloadend = this._handleReaderLoaded_ForPDF.bind(this);
      this.cd.markForCheck();
    }
  }
  _handleReaderLoaded_ForPDF(e) {
    let reader = e.target;
    this.CurrentPDFBase64 = reader.result.substr(
      reader.result.indexOf(",") + 1
    );
    this.ms
      .PostDomainPDF(this.CurrentPDFId, this.CurrentPDFBase64)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        if (data.length > 0) {
          if ((data[0].StatusCode = "1")) {
            this.getData();
          } else {
          }
        } else {
        }
      });
  }
  transformpdf(base64pdf) {
    if (base64pdf) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:application/pdf;base64," + base64pdf
      );
    }
  }
}
