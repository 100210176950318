import { Component, OnInit } from '@angular/core';
import { ApiService } from "../services/api.service";
import { MedicinService } from "../services/medicin.service";
import { SaveService } from "../services/save.service";
import { map, tap } from "rxjs/operators";

@Component({
    selector: 'app-logged-in',
    templateUrl: './logged-in.component.html',
    styleUrls: ['./logged-in.component.scss'],
    providers: [ApiService, MedicinService]
})
export class LoggedInComponent implements OnInit {
    curPage: number = 1;
    pageSize: number = 4;
    dotAfter: number = 5;
    pagin: any = [];
    showPagin: any = [];
    saveLoader: boolean = false;
    resendLoader: boolean = false;
    ShowSignature: boolean = false;
    ShowPastEnroll: boolean = false;
    saveFormSuccess: boolean = false;
    BrokerInfo: boolean = true;
    formsuccess: boolean = true;
    errormessageFName: any = "";
    errormessageLName: any = "";
    errormessageEmail: any = "";
    errormessagePhone: any = "";
    errormessageApi: any = "";
    cardsuccess: boolean = false;
    emailmodel: any = "";
    first_name: '';
    last_name: '';
    enroll_first_name: '';
    enroll_last_name: '';
    enroll_emailmodel: '';
    enroll_phone_number: '';
    loader: boolean = false;
    btnLoader: boolean = false;
    signatureLoader: boolean = false;
    phone_number: any = '';
    current_Phone_number: any = '';
    sendmessageStatus: any = '';
    phonefield: any = false;
    eccounts: any = 0;
    totalPrescription: any = 0;
    totalPatient: any = 0;
    errormessageFullName: any = "";
    errormessageCompany: any = "";
    signstatus: any = false;
    brokername: any = "";
    companyName: any = "";
    phone: any = ""
    current_full_name: any = "";
    current_company_name: any = "";
    emailmodell: any = "";
    historicallength: any = [];
    userRowId: any = '';
    current_enroll_emailmodel: any = '';
    current_enroll_phone_number: any = '';
    nodatafound: any = false;
    resendemailloader: any = false;
    pastsuccessmessage: any = "";

    constructor(private ms: ApiService, private med: MedicinService, private saveService: SaveService) {
        setTimeout(() => {
            if (window.localStorage.getItem('brokerdetail')) {
                this.eccount();
                let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
                let uid = brokerdetail.brokerDetails[0].distId;
                let groupno = window.localStorage.getItem('brokergroup');
                let brokeremail = window.localStorage.getItem('brokeremail');
                this.gethistoriacal(uid, brokeremail, groupno);
            }
        }, 600);
    }

    ngOnInit() {

        if (window.localStorage.getItem('understand')) {
            this.BrokerInfo = false;
        }
        else {
            this.BrokerInfo = true;
        }
    }
    ngOnDestroy() {
        $('body').removeClass('broker-pages');
    }
    activeClass(e) {
        let endElement = parseInt($('.pagination').find('li').eq(-1).text());
        let fElement = parseInt($('.pagination').find('li').eq(0).text());
        if (this.pagin.length > 5) {
            if (e === endElement) {
                this.showPagin = this.pagin.slice(e - 4, e + 1);
            } else if (fElement === e && fElement != 1) {
                this.showPagin = this.pagin.slice(e - 2, e + 3);
            }
        }
        let cIndex = this.showPagin.indexOf(e);
        $('.pagination').find('li').removeClass('active');
        setTimeout(() => {
            $('.pagination').find('li').eq(cIndex).addClass('active');
        }, 300);
    }
    firstPagin(e) {
        this.showPagin = this.pagin.slice(0, 5);
        setTimeout(() => {
            $('.pagination').find('li').removeClass('active');
            $('.pagination').find('li').eq(0).addClass('active');
            this.curPage = e;
        }, 500);
    }
    lastPagin(e) {
        this.showPagin = this.pagin.slice(-5);
        setTimeout(() => {
            $('.pagination').find('li').removeClass('active');
            $('.pagination').find('li').eq(-1).addClass('active');
            this.curPage = Math.ceil(e);
        }, 500);
    }
    prevPage(e) {
        let fElement = parseInt($('.pagination').find('li').eq(0).text());
        if (fElement === e && fElement != 1) {
            this.showPagin = this.pagin.slice(e - 2, e + 3);
        }
        let cIndex = this.showPagin.indexOf(e);
        $('.pagination').find('li').removeClass('active');
        setTimeout(() => {
            $('.pagination').find('li').eq(cIndex).addClass('active');
            this.curPage = e;
        }, 300);
    }
    nextPage(e) {
        let endElement = parseInt($('.pagination').find('li').eq(-1).text());
        if (this.showPagin.length >= 5) {
            if (endElement === e) {
                this.showPagin = this.pagin.slice(e - 4, e + 1);
            }
        }
        let cIndex = this.showPagin.indexOf(e);
        $('.pagination').find('li').removeClass('active');
        setTimeout(() => {
            $('.pagination').find('li').eq(cIndex).addClass('active');
            this.curPage = e;
        }, 300);
    }
    Pagin() {
        this.pagin = [];
        let totalPages = Math.ceil(this.historicallength.length / this.pageSize);
        for (let i = 0; i < totalPages; i++) {
            // if (i > this.dotAfter) {
            //     this.pagin.push('...');
            //     break;
            // } else {
            this.pagin.push(i + 1);
            // }
        }
        // filter first 5 records
        this.showPagin = this.pagin.slice(0, this.dotAfter);

        setTimeout(() => {
            $('.pagination').find('li').eq(this.curPage - 1).addClass('active');
        }, 500);
    }
    numberOfPages() {
        return Math.ceil(this.historicallength.length / this.pageSize);
    }
    gethistoriacal(distid, emailid, groupno) {
        this.med.gethistorical(distid, emailid, groupno)
            // .pipe(map((res) => res.json()))
            .subscribe(data => {
                if (data) {
                    this.historicallength = data;
                    this.nodatafound = false;
                    this.Pagin();

                }
                else {
                    this.nodatafound = true;
                }
            })
    }
    ShowSignaturePopup() {

        this.ShowSignature = true;
        let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
        this.signstatus = JSON.parse(window.localStorage.getItem('signstatus'));
        this.current_full_name = brokerdetail.brokerDetails[0].name;
        this.current_company_name = brokerdetail.brokerDetails[0].companyName
        this.current_Phone_number = brokerdetail.brokerDetails[0].phone;
        this.emailmodell = brokerdetail.brokerDetails[0].signemail;
    }
    ShowPastEnrollPopup(fName, lName, email, phone, userRowId) {
        this.enroll_first_name = fName;
        this.enroll_last_name = lName;
        this.enroll_emailmodel = email;
        this.enroll_phone_number = phone.replace(/-/g, "");;
        this.ShowPastEnroll = true;
        this.userRowId = userRowId;
        this.current_enroll_emailmodel = email
        this.current_enroll_phone_number = phone;
    }
    CloseSignaturePopup() {
        this.ShowSignature = false;
    }
    ClosePastEnrollPopup() {
        this.ShowPastEnroll = false;
    }
    Save(e) {
        if (e == 'save') {
            this.saveLoader = true;
        } else {
            this.resendLoader = true;
        }
        let fName = $("#enroll_first_name").val().toString().trim();
        let lName = $("#enroll_last_name").val().toString().trim();
        let email = $("#enroll_email_").val().toString().trim();
        let phone = '';
        // if ($("#enroll_phone_").val() !=undefined) {
        //     
        //     phone = $("#enroll_phone_").val().toString().trim();
        // }
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let count = 0;

        if (fName == '' || fName == undefined) {
            $("#enroll_fnameerror").show();
            this.errormessageFName = 'First name required'
            this.saveLoader = false;
            this.resendLoader = false;
            count++;
        }
        if (lName == '' || lName == undefined) {
            $("#enroll_lnameerror").show();
            this.errormessageLName = 'Last name required'
            this.saveLoader = false;
            this.resendLoader = false;
            count++;
        }
        if (email == '') {
            $("#enroll_emailerror").show();
            this.errormessageEmail = 'Email required'
            this.saveLoader = false;
            this.resendLoader = false;
            count++;
        }
        if (!emailReg.test(email)) {

            $("#enroll_emailerror").show();
            this.errormessageEmail = 'Invalid Email'
            this.saveLoader = false;
            this.resendLoader = false;
            count++;
        }
        if (count > 0) {
            return false;
        }
        else {
            if (e == 'save') {
                if (this.current_enroll_emailmodel != email) {

                    this.ms.validatebrokeremail(email)
                        // .pipe(map((res) => res.json()))
                        .subscribe(data => {
                            if (data.result.verdict != 'Valid' && data.result.verdict != 'Risky') {

                                $('#enroll_emailerror').removeAttr('style');
                                this.errormessageEmail = "Invalid Email!"
                                this.saveLoader = false;
                                this.resendLoader = false;
                            }
                            else {

                                if (this.current_enroll_phone_number != phone && phone != '') {

                                    this.ms.validatephone(phone)
                                        .subscribe(data => {
                                            if (data.status != 404) {

                                                let phno = JSON.parse(data["_body"]);
                                                phno = phno.phone_number;
                                                this.savehistoricaldata(this.userRowId, fName, lName, email, phno, 'save')
                                            }
                                            else {
                                                this.errormessagePhone = "Invalid Phone No!"
                                                $('#enroll_phoneerror').removeAttr('style');
                                                this.saveLoader = false;
                                                this.resendLoader = false;
                                            }
                                        }
                                            ,
                                            error => {
                                                this.errormessagePhone = "Invalid Phone No!"
                                                $('#enroll_phoneerror').removeAttr('style');
                                                this.saveLoader = false;
                                                this.resendLoader = false;
                                            },
                                        );
                                }
                                else {
                                    this.savehistoricaldata(this.userRowId, fName, lName, email, phone, 'save')
                                }
                            }
                        });
                }
                else if (this.current_enroll_phone_number != phone && phone != '') {

                    this.ms.validatephone(phone)
                        .subscribe(data => {

                            if (data.status != 404) {

                                let phno = JSON.parse(data["_body"]);
                                phno = phno.phone_number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
                                this.savehistoricaldata(this.userRowId, fName, lName, email, phno, 'save')
                            }
                            else {

                                this.errormessagePhone = "Invalid Phone No!"
                                $('#enroll_phoneerror').removeAttr('style');
                                this.saveLoader = false;
                                this.resendLoader = false;
                            }
                        },
                            error => {
                                this.errormessagePhone = "Invalid Phone No!"
                                $('#enroll_phoneerror').removeAttr('style');
                                this.saveLoader = false;
                                this.resendLoader = false;
                            },
                        )
                }
                else {

                    this.savehistoricaldata(this.userRowId, fName, lName, email, phone, 'save')
                }
            }
            if (e == 'resend') {
                if (this.current_enroll_emailmodel != email) {
                    this.ms.validatebrokeremail(email)
                        // .pipe(map((res) => res.json()))
                        .subscribe(data => {
                            if (data.result.verdict != 'Valid' && data.result.verdict != 'Risky') {

                                $('#enroll_emailerror').removeAttr('style');
                                this.errormessageEmail = "Invalid Email!"
                                this.saveLoader = false;
                                this.resendLoader = false;
                            }
                            else {

                                if (this.current_enroll_phone_number != phone && phone != '') {

                                    this.ms.validatephone(phone)
                                        .subscribe(data => {
                                            if (data.status != 404) {

                                                let phno = JSON.parse(data["_body"]);
                                                phno = phno.phone_number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
                                                this.unsubscribe_subscribe(email, fName, lName, phno, '');
                                            }
                                            else {
                                                this.errormessagePhone = "Invalid Phone No!"
                                                $('#enroll_phoneerror').removeAttr('style');
                                                this.saveLoader = false;
                                                this.resendLoader = false;
                                            }
                                        }
                                            ,
                                            error => {
                                                this.errormessagePhone = "Invalid Phone No!"
                                                $('#enroll_phoneerror').removeAttr('style');
                                                this.saveLoader = false;
                                                this.resendLoader = false;
                                            },
                                        );
                                }
                                else {
                                    this.unsubscribe_subscribe(email, fName, lName, phone, '');
                                }
                            }
                        });
                }
                else if (this.current_enroll_phone_number != phone && phone != '') {

                    this.ms.validatephone(phone)
                        .subscribe(data => {
                            if (data.status != 404) {
                                let phno = JSON.parse(data["_body"]);
                                phno = phno.phone_number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
                                this.unsubscribe_subscribe(email, fName, lName, phno, '');
                            }
                            else {
                                this.errormessagePhone = "Invalid Phone No!"
                                $('#enroll_phoneerror').removeAttr('style');
                                this.saveLoader = false;
                                this.resendLoader = false;
                            }
                        },
                            error => {
                                this.errormessagePhone = "Invalid Phone No!"
                                $('#enroll_phoneerror').removeAttr('style');
                                this.saveLoader = false;
                                this.resendLoader = false;
                            },
                        );
                }
                else {
                    this.unsubscribe_subscribe(email, fName, lName, phone, '');
                }
            }
        }
    }
    returnphoneno(value) {
        if (value != '') {
            return value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
        }
        else {
            return '-'
        }
    }
    unsubscribe_subscribe(email, fname, lname, phone, savetype) {

        let logindata = JSON.parse(window.localStorage.getItem('brokerdetail'));
        let brokerdetail = logindata.brokerDetails[0];
        this.ms.unsubscribe_klaviyo(email)
            .subscribe(data => {

                this.ms.subscribetobrokerlist(email, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail)
                    .subscribe(data => {
                        this.ms.getProfileByEmail(email).subscribe((emaildata) => {
                            this.ms.addTobrokerListKlaviyo(email, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail, emaildata['data'][0].id)
                            .subscribe((savedata) => {
                                this.savehistoricaldata(this.userRowId, fname, lname, email, phone, savetype)
                            })
                        })
                    })
                // this.ms.addTobrokerListKlaviyo(email, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail)

                //     .subscribe(data => {
                //         this.savehistoricaldata(this.userRowId, fname, lname, email, phone, savetype)
                //     })
            },
                error => {

                }
            );
    }
    resendklaviyo(email, fname, lname, phone) {
        this.resendemailloader = true;
        $('.enrollparent.past').css('pointer-events', 'none');
        let logindata = JSON.parse(window.localStorage.getItem('brokerdetail'));
        let brokerdetail = logindata.brokerDetails[0];
        this.ms.unsubscribe_klaviyo(email)
            .subscribe(data => {

                // this.ms.addTobrokerListKlaviyo(email, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail)
                //     // .pipe(map((res) => res.json()))
                //     .subscribe(data => {
                //         this.resendemailloader = false;
                //         $('.enrollparent.past').css('pointer-events', 'fill');
                //     })
            });
    }
    savehistoricaldata(userRowId, fName, lName, email, phone, savetype) {

        this.ms.savehistoricaldata(userRowId, fName, lName, email, phone)
            // .pipe(map((res) => res.json()))
            .subscribe(data => {

                this.saveLoader = false;
                this.resendLoader = false;
                if (data[0].statusCode != "-1") {
                    this.saveFormSuccess = true;
                    let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
                    let uid = brokerdetail.brokerDetails[0].distId;
                    let groupno = window.localStorage.getItem('brokergroup');

                    let brokeremail = window.localStorage.getItem('brokeremail');
                    this.gethistoriacal(uid, brokeremail, groupno);

                    if (savetype == "save") {

                        this.pastsuccessmessage = "Saved"
                    }
                    else {
                        this.pastsuccessmessage = "Resent"
                    }
                    setTimeout(() => {
                        this.ShowPastEnroll = false;
                        this.saveFormSuccess = false;
                        $('.pagination ul li:first-child a span').click()
                    }, 4000);
                }
                else {
                    $("#enroll_emailerror").show();
                    this.errormessageEmail = data[0].statusMessage;
                }

            })
    }
    CloseInfoPopup() {
        this.BrokerInfo = false;
    }
    success() {
        $('.success-btn').fadeOut();
        $("#first_name").val('');
        $("#last_name").val('');
        $("#email_").val('');
        $("#phone_").val('');
        setTimeout(() => {
            $('.success-btn').fadeIn();
            this.cardsuccess = false;
        }, 300);
    }
    validateSignature() {
        this.btnLoader = true;
        var fullname = $("#currentFullName").val().toString().trim();
        var cname = $("#currentCompanyName").val().toString().trim();
        var email = $("#currentEmail").val().toString().trim();
        var phone = this.current_Phone_number.toString().trim();
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        /* full name validation */
        if (fullname == null || fullname == undefined || fullname == "") {
            this.errormessageFullName = "Full name required";
            $("#errormessageFullName").show();
            $("#currentFullName").addClass('errorIcon');
            this.btnLoader = false;
        }
        /* company name validation */
        if (cname == null || cname == undefined || cname == "") {
            this.errormessageCompany = "Company name required";
            $("#errormessageCompany").show();
            $("#currentCompanyName").addClass('errorIcon');
            this.btnLoader = false;
        }
        /* email validation */
        if (!this.emailmodell.match(emailReg) && email != "") {
            this.errormessageEmail = "Please enter a valid email address";
            $("#errormessageEmail").show();
            $("#currentEmail").addClass('errorIcon');
            this.btnLoader = false;
        }
        if (email == null || email == undefined || email == "") {
            this.errormessageEmail = "Email required";
            $("#errormessageEmail").show();
            $("#currentEmail").addClass('errorIcon');
            this.btnLoader = false;
        }
        /* phone validation */
        if (phone != "") {
            if (phone.length < 7) {
                this.errormessagePhone = "Invalid phone number";
                $("#errormessagePhone").show();
                $("#currentPhoneNumber").addClass('errorIcon');
                this.btnLoader = false;
            }
        }
        if (phone == "") {
            this.errormessagePhone = "Please enter phone no.";
            $("#errormessagePhone").show();
            $("#currentPhoneNumber").addClass('errorIcon');
            this.btnLoader = false;
        }
        if (
            !(fullname == null || fullname == undefined || fullname == "") &&
            !(cname == null || cname == undefined || cname == "") &&
            !(email == null || email == undefined || email == "") &&
            !(emailReg.test(this.emailmodell) == false) && !(phone == "" || phone.length < 7)
        ) {
            this.btnLoader = true;
            let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
            let uid = brokerdetail.brokerDetails[0].distId;
            let groupno = window.localStorage.getItem('brokergroup');
            let brokeremail = window.localStorage.getItem('brokeremail');
            this.med.addsignature(uid, brokeremail, groupno, fullname, cname, email, phone)
                // .pipe(map((res) => res.json()))
                .subscribe(data => {


                    setTimeout(() => {

                        this.loginjapicall();
                    }, 600);
                })
        }

    }
    loginjapicall() {
        this.med.brokerLogin(window.localStorage.getItem('brokeremail'), window.localStorage.getItem('brokergroup'))
            // .pipe(map((res) => res.json()))
            .subscribe(data => {
                if (data[0].success == true) {
                    this.btnLoader = false;
                    this.ShowSignature = false;
                    window.localStorage.setItem('brokerdetail', JSON.stringify(data[0]));
                    window.localStorage.setItem('portallink', data[0].brokerDetails[0].portalLink);
                    window.localStorage.setItem('liid', data[0].brokerDetails[0].sandGridListId);
                    window.localStorage.setItem('signstatus', data[0].brokerDetails[0].signstatus);
                    setTimeout(() => {
                        let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
                        this.signstatus = JSON.parse(window.localStorage.getItem('signstatus'));
                        this.brokername = brokerdetail.brokerDetails[0].name;
                        this.companyName = brokerdetail.brokerDetails[0].companyName
                        this.phone = brokerdetail.brokerDetails[0].phone
                        this.emailmodell = brokerdetail.brokerDetails[0].signemail;
                    }, 300);
                }
            })
    }
    validation() {

        this.loader = true;
        var fname = $("#first_name").val().toString().trim();
        var lname = $("#last_name").val().toString().trim();
        var email = $("#email_").val().toString().trim();
        var phone = this.phone_number;
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        /* first name validation */
        if (fname == null || fname == undefined || fname == "") {
            this.errormessageFName = "First name required";
            $("#fnameerror").show();
            $("#first_name").addClass('errorIcon');
        }
        /* last name validation */
        if (lname == null || lname == undefined || lname == "") {
            this.errormessageLName = "Last name required";
            $("#lnameerror").show();
            $("#last_name").addClass('errorIcon');
            this.loader = false;
        }
        /* email validation */
        if (!this.emailmodel.match(emailReg) && email != "") {
            this.errormessageEmail = "Please enter a valid email address";
            $("#emailerror").show();
            this.loader = false;
        }
        if (email == null || email == undefined || email == "") {
            this.errormessageEmail = "Email required";
            $("#emailerror").show();
            $("#email_").addClass('errorIcon');
            this.loader = false;
        }
        /* phone validation */
        if (phone != "") {
            if (phone.length < 7) {
                this.errormessagePhone = "Invalid phone number";
                $("#phoneerror").show();
                $("#phone_").addClass('errorIcon');
                this.loader = false;
            }
        }
        if (
            !(fname == null || fname == undefined || fname == "") &&
            !(lname == null || lname == undefined || lname == "") &&
            !(email == null || email == undefined || email == "") &&
            !(emailReg.test(this.emailmodel) == false)
        ) {
            let logindata = JSON.parse(window.localStorage.getItem('brokerdetail'));
            let brokerdetail = logindata.brokerDetails[0];
            this.ms.subscribetobrokerlist(this.emailmodel, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail)
                .subscribe(data => {
                    this.ms.getProfileByEmail(this.emailmodel).subscribe((emaildata) => {
                        this.ms.addTobrokerListKlaviyo(this.emailmodel, fname, lname, brokerdetail.name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, brokerdetail.phone, brokerdetail.companyName, brokerdetail.address1, brokerdetail.address2, brokerdetail.signemail, emaildata['data'][0].id)
                            // .pipe(map((res) => res.json()))
                            .subscribe(data => {
                                this.loader = false;
                                this.cardsuccess = true;


                                this.sendmsg(this.phone_number, brokerdetail.deepLink)


                                this.med.SaveDeeplink(this.emailmodel, this.first_name, this.last_name, window.localStorage.getItem('brokergroup').toUpperCase(), brokerdetail.deepLink, "Yes")
                                    // .pipe(map((res) => res.json()))
                                    .subscribe((data2) => {
                                        this.eccount();
                                        setTimeout(() => { this.success(); }, 4000);
                                    })
                            })
                    })

                })


        }
    }
    sendmsg(val, deeplink) {

        if (val != "" && val.length >= 7) {
            this.ms
                .sendSmsbroker(val, deeplink)
                .pipe(
                    tap(
                        data => {
                        },
                        error => {

                            let errorData = (this.sendmessageStatus = JSON.parse(
                                error._body
                            ));
                            if (errorData.code == "21408") {
                                this.errormessagePhone = "Please enter a valid country code!";
                                $("#phoneerror").show();
                                $("#phone_").addClass('errorIcon');
                                this.loader = false;
                                this.phone_number = ''

                            } else if (errorData.code == "21211") {
                                this.errormessagePhone =
                                    "Please enter a valid phone number";
                                $("#phoneerror").show();
                                $("#phone_").addClass('errorIcon');
                                this.loader = false;
                                this.phone_number = ''
                            } else {
                                this.errormessagePhone = "Try a different number";
                                $("#phoneerror").show();
                                $("#phone_").addClass('errorIcon');
                                this.loader = false;
                                this.phone_number = ''
                            }
                        }
                    )
                )
                .subscribe(data => {
                    this.phone_number = ''
                });
        }
    }

    removeerror(event) {
        $(event.target).siblings('span').hide();
        $(event.target).removeClass('errorIcon');
    }
    Keyup_ClearField(event) {
        let id = event.target.id;
        id = '';
        $(event.target).next().hide();
        $(event.target).removeClass('errorIcon')

    }
    eccount() {

        let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
        let uid = brokerdetail.brokerDetails[0].distId;
        let groupno = window.localStorage.getItem('brokergroup');
        let brokeremail = window.localStorage.getItem('brokeremail');

        this.signstatus = JSON.parse(window.localStorage.getItem('signstatus'));
        this.brokername = brokerdetail.brokerDetails[0].name;
        this.companyName = brokerdetail.brokerDetails[0].companyName
        this.phone = brokerdetail.brokerDetails[0].phone
        this.emailmodell = brokerdetail.brokerDetails[0].signemail;

        this.med.eccount(brokeremail, groupno, uid)
            // .pipe(map((res) => res.json()))
            .subscribe(data => {

                this.eccounts = data[0].details[0].enrolls_count;
                let total_Prescription = data[0].details[0].total_prescription_discounts;
                let total_Patient = data[0].details[0].total_patient_savings;
                if (total_Prescription == null || total_Prescription == 0 || total_Prescription <= 0) {
                    this.totalPrescription = 0;
                } else {
                    this.totalPrescription = parseInt(total_Prescription);
                }
                if (total_Patient == null || total_Patient == 0 || total_Patient <= 0) {
                    this.totalPatient = 0;
                } else {
                    this.totalPatient = total_Patient;
                }
                let brokeremail = window.localStorage.getItem('brokeremail');
                this.gethistoriacal(uid, brokeremail, groupno);
            })
    }
    changesignstatus() {
        let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
        let uid = brokerdetail.brokerDetails[0].distId;
        let groupno = window.localStorage.getItem('brokergroup');
        let brokeremail = window.localStorage.getItem('brokeremail');
        let pref;
        this.signatureLoader = true;
        if (this.signstatus == true) {
            pref = 'no';
            this.signstatus = false;
            window.localStorage.setItem('signstatus', 'false');
        }
        else {
            pref = 'yes';
            this.signstatus = true;
            window.localStorage.setItem('signstatus', 'true');
        }

        this.med.changesignstatus(uid, brokeremail, groupno, pref)
            // .pipe(map((res) => res.json()))
            .subscribe(data => {
                this.signatureLoader = false;
                this.loginjapicall();
            })
    }
    markunderstood(event) {
        if (event.target.checked) {
            window.localStorage.setItem('understand', 'understood')
        }
        else {
            window.localStorage.removeItem('understand');
        }
    }
}