import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { SaveService } from "../services/save.service";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
  providers: [ApiService, SaveService]
})
export class ContactUsComponent implements OnInit {
  emptyInput: boolean = false;
  emptySelect1: boolean = false;
  emptySelect2: boolean = false;
  emptyInputLname: boolean = false;
  emptyEmail: boolean = false;
  invalidEmail: boolean = false;
  emptyMessage: boolean = false;
  emptyCheckbox: boolean = false;
  btnDisabled: boolean = false;
  emailmodel: any = "";
  recaptchaStr = '';
  public src = "../../../assets/images/contact-us-bg.jpg";
  maxlength: any = 500;
  message_content: any = '';
  public hearUs: string = 'How did you hear about us?';  
  public howdidhear:string[] = ['How did you hear about us?','Insurance Agent','Friend or Family Member','Web Search','Other']
  public howcanhelp:string[] = ['How can we help you?', 'I need help with my ValpakRx™ card', 'I would like to partner with Glic', 'I need help distributing ValpakRx™ cards', 'Other']
  public helpYou:string = 'How can we help you?';
  constructor(private apiService: ApiService) {}

  /* focus validation */
  focusValidation(inputField) {
    if (inputField == "f-name") {
      this.emptyInput = false;
      $("#f-name_")
        .parent()
        .removeClass("error_msg");
    }
    if (inputField == "l-name") {
      this.emptyInputLname = false;
      $("#l-name_")
        .parent()
        .removeClass("error_msg");
    }
    if (inputField == "email") {
      this.emptyEmail = false;
      this.invalidEmail = false;
      $("#email_")
        .parent()
        .removeClass("error_msg");
    }
    if (inputField == "message") {
      this.emptyMessage = false;
      $("#message")
        .parent()
        .removeClass("error_msg");
    }
  }

  checkboxFocusValidation() {
    if ($('input[type="checkbox"]').prop("checked") == true) {
      this.emptyCheckbox = false;
    } else {
      this.emptyCheckbox = true;
    }
  }
  onLoginClick(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }
  /* form validation */
  public resolved(captchaResponse: string): void {
    // ;
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      // ;
    }

  }
  onChange(e){
    this.hearUs = e.slice(3);
    this.emptySelect1 = false;
    $("#hearUs")
      .parent()
      .removeClass("error_msg");
}
  onChange2(e){
    this.helpYou = e.slice(3);
    this.emptySelect2 = false;
    $("#helpYou")
      .parent()
      .removeClass("error_msg");
  }
  validate() {
    this.btnDisabled = true;
    var fname = $("#f-name_").val();
    var lname = $("#l-name_").val();
    var email = $("#email_").val();
    var message = $("#message").val();
    var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // var hearUs = this.hearUs;    
    // var helpUs = this.helpYou;
    
    /* name */
    if (fname == null || fname == undefined || fname == "") {
      this.emptyInput = true;
      $("#f-name_")
        .parent()
        .addClass("error_msg");
    }
    if (lname == null || lname == undefined || lname == "") {
      this.emptyInputLname = true;
      $("#l-name_")
        .parent()
        .addClass("error_msg");
      }
      
    /* email */
    if (!this.emailmodel.match(emailReg) && email != "") {
      this.emptyEmail = false;
      this.invalidEmail = true;
      $("#email_")
        .parent()
        .addClass("error_msg");
      $("#email").focus();
    }
    if (email == null || email == undefined || email == "") {
      this.emptyEmail = true;
      $("#email_")
        .parent()
        .addClass("error_msg");
    }
    /* hear-us */
    // if (hearUs == null || hearUs == undefined || hearUs == "" || hearUs == "" || hearUs == "How did you hear about us?") {
    //   this.emptySelect1 = true;
    //   $("#hearUs")
    //     .parent()
    //     .addClass("error_msg");
    // }
    // /* help us */
    // if (helpUs == null || helpUs == undefined || helpUs == "" || helpUs == "How can we help you?") {
    //   this.emptySelect2 = true;
    //   $("#helpYou")
    //     .parent()
    //     .addClass("error_msg");
    // }

    /* message */
    if (message == null || message == undefined || message == "") {
      this.emptyMessage = true;
      $("#message")
        .parent()
        .addClass("error_msg");
    }

    if (!$('input[type="checkbox"]').prop("checked") == true) {
      this.emptyCheckbox = true;
    }
  
    if (
      !(fname == null || fname == undefined || fname == "") &&
      !(lname == null || lname == undefined || lname == "") &&
      !(email == null || email == undefined || email == "") &&
      !(emailReg.test(this.emailmodel) == false) &&
      !(message == null || message == undefined || message == "") &&
      $('input[type="checkbox"]').prop("checked") == true
    ) {
      // if(this.recaptchaStr) {
      let username = fname+ " " + lname;
      this.apiService
        .sendEmailContact(email, username, message)
        // .pipe(map(res => res.json()))
        .subscribe(data => {
          if (data[0].statuscode == 1) {
            this.message_content = '';
            $("#f-name_").val("");
            $("#l-name_").val("");
            $("#email_").val("");
            $("#message").val("");
            $("#contactsucess").show();
            
            this.checkboxFocusValidation();
            setTimeout(() => {
              $("#contactsucess").fadeOut("slow");
              $('input[name="captcha"]').click();
              this.emptyCheckbox = false;
              this.btnDisabled = false;
          }, 5000);
          }
        });
      // }
    } else {
      this.btnDisabled = false;
    }  
  }
  /* form submission */
  // FormSubmit() {
  //   try {
  //     $.ajax({
  //       type: "POST",
  //       url: "http://marriagepartyinvitation.com/contact-form-handler.php",
  //       data: $("form").serialize(),
  //       success: function(data) {
  //         console.log("done");
  //       }
  //     });
  //   } catch (e) {
  //     alert("You messed something up!");
  //   }
  // }

  ngOnInit() {
    $(window).scroll(function () {
      if (window.location.href.indexOf("/contact") > -1) {
        // if (
        //   $(window).scrollTop() >=
        //   $(".contactSecFour.green-theme").offset().top - 100
        // ) {
        //   $(".getCard.green-theme ").addClass("fixed_pos_card");
        // } else {
        //   $(".getCard.green-theme ").removeClass("fixed_pos_card");
        // }
      }
    });
  }
}