<section class="card-section termsofuse green-theme">
  <div class="comman-width">
    <span class="terms">Terms and Conditions of Use</span>
    <div class="repeat">
      <p>EFFECTIVE DATE: November 30, 2023</p>
      <p>This Web site <a href="https://www.valpakrx.com/">www.valpakrx.com</a> and the ValpakRx™ mobile app (“Glic Health App”) are owned and operated by Glic Health, LLC, a Delaware Limited Liability Company‎ (“Glic Health,” “we,” “our,” and “us”). Throughout these Terms and Conditions of Use (“Terms and Conditions”), the words “you” and “your” refer to the user using the Services (defined below).These Terms and Conditions set forth the legally binding terms for your use of the Services. By accessing or using this Web site or, more broadly, the Services in any manner, you agree to: (i) these Terms and Conditions, (ii) our Privacy Policy (the “Privacy Policy”), and (iii) any other legal notices, conditions or guidelines accessible through the Services. These Terms and Conditions apply to all of our Services. When we use the term “Services” in these Terms and Conditions, we mean (collectively):</p>
      <ul>
        <li>our Web site,</li>
        <li>any ValpakRx™ mobile app,</li>
        <li>the ValpakRx™ prescription discount program, as described below and here (“Glic Health Program”), and</li>
        <li>any other Glic Health service or application that posts a link to these Terms and Conditions, as well as any features, ‎widgets, ‎plug-ins, content, downloads or other services that are offered.</li>
      </ul>
      <p></p>
      <p>These Terms and Conditions will apply regardless of how you access ‎or ‎use the Services, whether it be via computer, tablet, mobile device, smart watch, console, mobile application, paper card, or otherwise.‎ By downloading the ValpakRx™ App, accessing the Web site or using the Services you are agreeing to these Terms and Conditions, which includes a limited license, disclaimers of warranty, ‎limitations of liability and binding arbitration and class action and ‎jury waivers.‎ Accepting these Terms and Conditions is required to use the Services. If you do not agree to any of these Terms and Conditions, or the <a class="u link-color" [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>, please do not use the Services and exit now.</p>
      <p>For the avoidance of doubt, the Program Description <a class="u link-color"
          [routerLink]="['/program-description']" routerLinkActive="active">(found here)</a> and the Privacy Policy
        <a class="u link-color" [routerLink]="['/privacy-policy']" routerLinkActive="active">(found here)</a> are
        expressly incorporated into these Terms and Conditions must be read together with them.
      </p>
    </div>

    <div class="repeat">
      <h3>Important Legal Information about Privacy</h3>
      <p>The terms and conditions of Glic Health’s Privacy Policy, which is available at <a class="u" [routerLink]="['/privacy-policy']" routerLinkActive="active">https://valpakrx.com/privacy-policy</a>, apply to your use of the Services, form an integral part of your agreement with us, and are incorporated by reference into these Terms and Conditions. You should read the Privacy Policy carefully before you use our Services since, by using the Services, you will be agreeing to everything in these Terms and Conditions, including the terms contained in our Privacy Policy.</p>
    </div>
    <div class="repeat">
      <h3>Modification to These Terms and Conditions of Use, and Our Services</h3>
      <p>We may add to, update, delete from or modify the Services at any time in our sole discretion. We reserve the right, at any time and from time to time, for any reason, to change these Terms and Conditions. We may post or display notices of changes to these Terms and Conditions on the Web site or otherwise through our Services or may notify you of such changes. Once posted, these changes to these Terms and Conditions become effective immediately. If any change that we make is not acceptable to you, promptly stop using the Services. Any use by you of the Services after any changes to our Terms and Conditions become effective will signify your agreement to those changes. You should check back regularly and review these Terms and Conditions so that you are aware of the most current rights and obligations that apply to your agreement with us. Nothing in these Terms and Conditions grants you any rights or benefits under our contracts or relationships with third parties.</p>
      <p>
        IF YOU ARE A CONSUMER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER PROTECTION LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”</p>
    </div>
    <div class="repeat">
      <h3>Access and Use of Services</h3>
      <p>These Terms and Conditions apply to all users of the Services. We provide the Services Content, including all information, media, images, and text available through our Services (“Services Content”), as a courtesy to our users and intend it for general, informational and educational purposes only. All information provided through the Services is subject to change without notice. We permit you to access and use the Services Content for personal, non-commercial purposes only. You may download and print Services Content for the sole purposes of viewing, reading, and retaining the materials for non-commercial use. Any other copying, distribution, retransmission, or modification of Services and/or Services Content, whether in electronic or other form, is prohibited without our express prior written permission. You agree that you will not disassemble, ‎decompile, reverse engineer or otherwise modify the Services Content, any material on this Web site or any software supporting or part of the Services. Any unauthorized or prohibited use may subject the offender to civil liability and ‎criminal prosecution under applicable laws.‎</p>
    </div>
    <div class="repeat">
      <h3>User Accounts</h3>
      <p>To use certain features of the Services you may need to create a personal username and password. You are responsible ‎for any use of the Services that takes place through your personal account, regardless of whether such use ‎is unauthorized or by a third party. Please keep your username and password secure and do not let anyone else ‎use your account information to access the Services.</p>
      <p>In some cases, you may receive, print or download our ValpakRx™ prescription discount card (“ValpakRx™  Card”) without registering with us or creating an account. You may also have received a ValpakRx™ Card from a third-party marketing organization, with or without having provided that third party with your name or other identifying information. If you request to have a ValpakRx™ Card emailed, texted, or mailed to your personal device or home address, you will be required to provide us with your name, email address, home address, and/or telephone number. The information that you provide will be used as set forth in these Terms and Conditions (including our <a class="u" [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>).</p>
      <p>Using the ValpakRx™ Program via the ValpakRx™ App requires the download of the ValpakRx™ App from the Apple iTunes App Store or the Google Play Store. Downloading the ValpakRx™  App is subject to the applicable end user license agreement and the information that you provide through the ValpakRx™ App will be used as set forth in these Terms and Conditions (including our <a class="u" [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>).</p>
    </div>
    <div class="repeat">
      <h3>ValpakRx™ Prescription Discount Program</h3>
      <p>For more information about our ValpakRx™ Program, please see our ValpakRx™ Program Description, which is
        available here: <a href="https://valpakrx.com/program-description"
          class="u">https://valpakrx.com/program-description</a>.
      </p>
      <p><b>THE ValpakRx™ PROGRAM IS NOT INSURANCE.</b> THROUGH THE ValpakRx™ PROGRAM, ValpakRx™  PRESCRIPTION DISCOUNTS AND COUPONS CAN BE USED INSTEAD OF, BUT NOT IN CONJUNCTION WITH, INSURANCE. The ValpakRx™ Program is not intended as a substitute for health insurance. The range of the discounts will vary depending on the prescription drug and the participating pharmacy. We cannot guarantee any discount we provide is the lowest price available. Only participating pharmacies will honor our ValpakRx™ Program. You are required to pay for all prescription drugs at the time of purchase. Glic Health does not make payments to any pharmacy or health care provider. THE ValpakRx™ PROGRAM DOES NOT MEET MINIMUM CREDITABLE COVERAGE REQUIREMENTS UNDER STATE HEALTHCARE INSURANCE COVERAGE REGULATIONS.</p>
      <p>IF YOU DO NOT HAVE HEALTH INSURANCE, you can use our ValpakRx™ Program to obtain discounts for applicable brand name or generic prescriptions you or a household member pays for out-of-pocket, unless other restrictions apply. The ValpakRx™ Program cannot be used simultaneously with other prescription drug discount cards or for prescriptions paid for through a health benefit or pharmacy benefit plan. IF YOU HAVE HEALTH INSURANCE, or any other funded benefit such as Medicaid or Medicare, you can use the ValpakRx™ Program for certain prescriptions that are not covered by your plan when you would otherwise be purchasing those prescriptions without alternative coverage or discounts. You should confirm whether you are eligible to use the Glic Health Program if you are covered by health ‎insurance by checking with your health insurance provider.‎</p>
      <p>Please note that any payments that you make for medications using the ValpakRx™ Program do not count toward your insurance, Medicare or other prescription drug coverage out-of-pocket costs or deductibles or out-of-pocket maximums.</p>
      <p>ValpakRx™ Program prescription discounts, coupons and prices shown are based on multiple sources, including published price lists, purchases, claims records, and data provided by pharmacies. ValpakRx™ PROGRAM PRESCRIPTION DISCOUNTS AND PRICES MAY CHANGE AT ANY TIME. THE PRICES WE SHOW ARE OUR BEST ESTIMATE; WHILE WE BELIEVE OUR DATA TO BE GENERALLY ACCURATE, WE CANNOT GUARANTEE THAT THE PRICE WE DISPLAY WILL EXACTLY MATCH THE PRICE YOU RECEIVE AT THE PARTICIPATING PHARMACY. For an exact price, please contact the participating pharmacy.</p>
    </div>
    <div class="repeat">
      <h3>Not Medical Advice</h3>
      <p>The Services, incorporating the ValpakRx™ Program and any Services Content that provide medical or health information are for informational purposes only. The Services do not provide medical advice and are not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health professionals or providers with any questions you may have regarding a medical condition or medicine. Never disregard professional medical advice or delay in seeking it because of the Services.</p>
      <p>Glic Health does not recommend or endorse any specific prescription drug or pharmacy that may be mentioned in relation to any services provide through the Services. Reliance on any information provided by Glic Health or found through our Services is solely at your own risk.</p>
      <p>If you have or suspect that you have a medical problem or condition, please contact a qualified health care professional immediately.</p>
    </div>
    <div class="repeat">
      <h3>Eligibility</h3>
      <p>You must be at least 18 years old to use the Services. By using the Services, you affirm that you are 18 years of age or ‎older and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, ‎and warranties set forth in these Terms and Conditions, and to abide by and comply with these Terms and Conditions.</p>
    </div>
    <div class="repeat">
      <h3>Intellectual Property Rights </h3>
      <p>Subject to your adherence to these Terms and Conditions, we grant you a limited non-exclusive, non-sublicensable, revocable, non-transferable license to access and use the Services, solely in connection with your personal, non-commercial use of our prescription discounting program at participating pharmacies and solely for the purpose of obtaining discounts (if available) on prescription drugs and related items at participating pharmacies in accordance with these Terms and Conditions. We expressly reserve any and all rights in the Services that are not expressly granted to you in the previous sentence.</p>
      <p>The Services, Services Content and the trademarks, service marks and logos contained therein (“Marks”), are owned by or licensed to Glic Health, subject to applicable intellectual property rights under United States and foreign laws and international conventions. You in no way will obtain any ownership interest or rights in the Services Content or Marks contained on this Web site or accessed through the Services. Glic Health reserves all rights not expressly granted in and to the Services and the Services Content. If you download or print a copy of the Services Content for personal or educational use, you must retain all copyright and other proprietary notices contained therein.</p>
    </div>
    <div class="repeat">
      <h3>Restrictions, Limitations and Conditions of Use</h3>
      <p>You agree not to circumvent, disable or otherwise interfere with any security related
        features of the Services or features that prevent or restrict use or copying of any Services Content or enforce
        limitations on use of the Services or the Services Content.
      </p>
      <p>Any unauthorized use of our Services or Services Content for any purpose is prohibited. Our grant of rights to
        use the Services is on the condition that you use the Services in accordance with these Terms and Conditions.
        You may not use the Services for any unlawful purpose and you may not:</p>
      <ul>
        <li>access, solicit, collect or use any personally identifying information about any other users of the Services
          or
          anyone else;</li>
        <li>restrict or inhibit others from using the Services;</li>
        <li>transmit to or introduce through the Services any viruses, harmful software, or other modifications or
          changes
          to the Services or reverse engineer, decompile, disassemble, reverse assemble or modify any Services source or
          object code or any software or other services or processes accessible through any portion of the Services or
          Services Content;</li>
        <li>submit, upload, email or otherwise transmit any content or other materials, to or through the Services which
          (a)
          is threatening, abusive, defamatory, obscene, vulgar, offensive, or otherwise objectionable, or which may harm
          minors; (b) infringes or violates the rights of others, or (c) has an advertising, political marketing,
          promotional or other commercial purpose;</li>
        <li>engage in any activities that interfere with another user’s access to the Services or the proper operation
          of
          the Services; or</li>
        <li>distribute or copy any Services Content manually or by using any robot, scraper, crawler, or any other
          automatic
          device or process; frame or utilize framing techniques to enclose any Services Content; or use content from
          the
          Services in a manner that suggests an unauthorized association with any of our or our licensor’s products,
          services or brands.
        </li>
      </ul>
      <p>
        Glic has no obligation to monitor your interaction with the Services, but reserves the right to review or
        monitor the Services and any user submissions in its sole discretion.
      </p>
    </div>
    <div class="repeat">
      <h3>Electronic (and other) Communication</h3>
      <p>We may provide Communications to you by one or more of the following methods: (1) via e-mail; (2) via telephone call; (3) on the App; (4) via text message or mobile message service; or (5) any other method to the extent permissible by law. With respect to text message and mobile messaging service Communications, your carrier may charge you a fee for receiving the message. “Communications” means all notices, reports, documents, disclosures or other information that we are required to provide to you by law, or as reasonably necessary to provide the Service. Communications also means any marketing offers we may send through any of the methods identified below or any other electronic medium allowed by law, or surveys or questionnaires seeking input and feedback on the Services.</p>
      <p>You may withdraw your consent to receive marketing Communications electronically by emailing us at <a href="mailto:support@glichealth.com">support@glichealth.com</a> or by using the unsubscribe link in any e-mail or text Communication. You agree to allow us a reasonable amount of time to process your request. You may request a paper copy of any Communication by emailing us at <a href="mailto:support@glichealth.com">support@glichealth.com</a>. We may charge you a reasonable service fee for a paper copy. If you cannot access your Communications, you must notify us so we can help identify the issue, or arrange to have the Communications delivered via alternative means.</p>
      <p>It is your responsibility to provide us with accurate contact information, which may include, but is not limited to a valid e-mail address. You must promptly advise us of any changes in your contact information. To view any Communication, you will need a compatible device with internet access.</p>
    </div>
    <div class="repeat">
      <h3>Indemnification </h3>
      <p>By accessing the Services, you agree to indemnify us and any parent, subsidiary or affiliated entities, and
        respective directors, officers, employees, agents and consultants, and hold them harmless from any and all
        third-party claims and expenses, including attorney’s fees, arising from your use of the Services or your
        violation of any term of these Terms and Conditions.
      </p>
      <p>IF YOU ARE A CONSUMER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER PROTECTION
        LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”
      </p>
    </div>
    <div class="repeat">
      <h3>Disclaimer</h3>
      <p>
        THE SERVICES, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, THE ValpakRx™ APP, AND ValpakRx™ PROGRAM, ARE
          PROVIDED ON AN “AS-IS” AND
          “AS-AVAILABLE”
          BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR
          NON-INFRINGEMENT OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WARRANTY
          ARISING
          BY COURSE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE, ANY WARRANTY THAT THE SERVICES WILL MEET YOUR
          REQUIREMENTS OR ANY WARRANTY REGARDING THE QUALITY, CONTENT, ACCURACY OR VALIDITY OF THE INFORMATION OR DATA
          RESIDING ON OR PASSING THROUGH OR OVER OUR NETWORK. ALL SUCH WARRANTIES ARE HEREBY DISCLAIMED. NO ORAL OR
          WRITTEN ADVICE OR INFORMATION BY GLIC'S OFFICERS, EMPLOYEES, AGENTS OR CONTRACTORS SHALL CREATE A
          WARRANTY,
          AND
          YOU MAY NOT RELY ON ANY SUCH INFORMATION
      </p>
      <p>
          We do not warrant that the Services will be uninterrupted or error-free, that defects will be corrected, or
          that the Services or the server(s) that make them available (or any hyperlinked site) are free of viruses or
          other harmful components or that our Services, server(s), or hyperlinked sites will be accessible at all
          times. Further, we do not warrant that errors in the Services will be fixed by Glic. Reliance on any Services
          Content is done so entirely at your own risk with no liability to Glic.
          You
          assume the entire cost of all necessary servicing, repair or correction to any equipment you use to access the
          Services. To the extent that applicable law may not allow the exclusion of implied warranties, the above
          exclusions may not apply to you.
      </p>
      <p>Services Content, documents, information, graphics and other materials appearing on the Services may
        include
        technical inaccuracies, miscalculations, typographical errors, and out-of-date information and use of such
        documents, information, graphics or other materials is at your own risk.
      </p>
      <p>By using the Services, you acknowledge and agree that we will have no responsibility ‎or liability for any
        health care services provided to you or for the lack of any health care services provided to you. You are
        responsible for all payments to your doctors, ‎dentists, and pharmacists and any other health care and
        professional providers. We are not responsible for prescriptions written by your doctor, nurse, or any other
        health care provider, and are not responsible for any adverse effects ‎caused by the medicines you purchase
        using the ValpakRx™ Program</p>
      <p>IF YOU ARE A CONSUMER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER PROTECTION
        LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”</p>
    </div>
    <div class="repeat">
      <h3>Consumer Protection Notice </h3>
      <p>If you are a consumer, the provisions in these Terms and Conditions are ‎intended to be only as broad and
        ‎inclusive ‎as is permitted by the laws of your ‎state of residence. Certain states, like New Jersey, ‎have
        ‎heightened consumer ‎protection laws that may make certain portions of these Terms and Conditions
        ‎‎inapplicable ‎to you. Specifically, if you are a New Jersey consumer, certain ‎provisions do not ‎limit or
        waive your ‎rights as a consumer under New Jersey law. The provisions in these Terms and Conditions ‎are
        intended to be ‎only as broad ‎and inclusive as is permitted by the laws of the State of New ‎Jersey. No
        ‎provision of ‎these Terms and Conditions shall limit or waive your rights as a ‎consumer under the ‎law of your
        state of residence. ‎In any event, Glic reserves ‎all rights, defenses and ‎permissible limitations under
        the
        law
        of your ‎state of ‎residence.‎
      </p>
    </div>
    <div class="repeat">
      <h3>Limitation of Liability </h3>
      <p>To the fullest extent permissible pursuant to applicable law, Glic and any parent, subsidiary or
          affiliated
          entities, and respective directors, officers, employees, agents and consultants (collectively the Glic
          Parties”)
          shall not be liable for any damages (including, but not limited to, direct, indirect, incidental, special or
          consequential damages), whether based on warranty, contract, tort, or any other legal theory, including, but
          not
          limited to, damages for loss of data or other damage to any other intangible property (even if the Glic
          Parties
          have been advised of the possibility of such damages) resulting from: (i) the use or inability to use the
          Services or Services Content; or (ii) the disclosure of, unauthorized access to, or alteration of any
          transmission or data.
      </p>
      <p>IF YOU ARE A CONSUMER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER PROTECTION
        LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”
      </p>
    </div>
    <div class="repeat">
      <h3>Links to Other Websites and Services </h3>
      <p>To the extent that the Services or this Web site contains links to other Web sites and outside services and
        resources, we do not control the availability and content of those Web sites, services or resources, nor do we
        necessarily review or endorse materials available at or through such other Web sites. Viewing other Web sites or
        utilizing outside services and resources is at your own risk. Accordingly, we encourage you to be aware when you
        leave our Services and to read the terms, conditions and privacy policy of each Web site that you visit. We
        shall not be liable for any loss or damage caused or allegedly caused by or in connection with use of or
        reliance on any content, goods or services available on or through any such Web site or resource.

      </p>
    </div>
    <div class="repeat">
      <h3>Other Restrictions on Conduct </h3>
      <p>You must provide true and accurate information to our Services. You are prohibited from misrepresenting your
        information or tampering with our Services. You agree not to disrupt, modify or interfere with the functioning
        of our Services or any services provided on or through them. You further agree not to disrupt, modify or
        interfere with any software, hardware or servers associated with our Services, and you agree not to impede or
        interfere with others’ use of our Services. You also agree not to alter or tamper with any information or
        materials on, or associated with, our Services or services provided on or through it.
      </p>
      <p>You agree that any prescriptions or pharmaceutical products that you acquire or obtain through the Services and
        any services provided therein will be solely for your (or your family member’s or dependent’s, as applicable to
        whom the prescription is written for) personal use and not for resale to others. Glic may cancel,
        suspend, or
        terminate your access to our Services or any of our services at any time and for any reason, including any
        violation of these Terms and Conditions.
      </p>
    </div>
    <div class="repeat">
      <h3>Governing Law, Forum; Entire Agreement </h3>
      <p>These Terms and Conditions are governed by the laws of the State of Delaware, without giving effect to
        principles of conflicts of law, except as may be otherwise provided in the Arbitration Agreement below. However
        the choice ‎of law provision regarding these terms is not intended to create any other substantive right to
        ‎assert claim except as provided through the Arbitration Agreement.‎ These Terms and Conditions, together with
        the Privacy Policy, and any other documents or polices referenced herein constitute the entire agreement between
        you and Glic regarding the use of the Services and the content and services provided through the
        Services.
      </p>
      <p>IF YOU ARE A CONSUMER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER PROTECTION
        LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”
      </p>
    </div>
    <div class="repeat">
      <h3>Arbitration Agreement for Dispute Resolution; Information Resolution and Formal Resolution by Arbitration /
        Class Action Waiver</h3>
      <p>In order to expedite and control the cost of disputes, you and Glic agree that any legal or equitable
        claim
        relating to the use of the Services or any services associated with or marketed as part of the Services
        (referred to as a “Claim”) will be resolved as follows:
      </p>
      <br>
      <ul type="A">
        <li>A. Informal Resolution:
          <p>You and Glic will first attempt to resolve any Claim informally. In the event that any dispute
            between
            Glic and
            you arises out of or relates to: (i) these Terms and Conditions; (ii) the Services; or (iii) the ValpakRx
            Program,
            you and we agree to try to promptly resolve any such dispute informally. Please send a written notice
            describing the dispute to <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a>.
          </p>
        </li>
        <li>B. Formal Resolution by Arbitration / Class Action Waiver.
          <p>
            READ THE FOLLOWING ARBITRATION PROVISION CAREFULLY, IT LIMITS YOUR RIGHTS, INCLUDING THE RIGHT TO
              MAINTAIN A
              COURT ACTION. You agree that any dispute, controversy or Claim arising out of or relating to
            these
            Terms and Conditions, your use of the Services, or the ValpakRx™ Program, or the determination of the
            scope or
            applicability of arbitration shall be governed as set forth below.
          </p>
          <p>If you and Glic cannot resolve a Claim informally, any Claim asserted by either party will be
            resolved
            only
            by
            binding arbitration (“<b>Arbitration</b>”). By agreeing to Arbitration, both you and Glic understand
            and
            agree
            that
            all
            processes, such as a court action or administrative proceeding, to settle disputes shall be decided by a
            single
            arbitrator and that you are waiving your rights to maintain other available resolution processes, such as a
            court action or administrative proceeding, to settle disputes. Instead of suing in court, both you and
            Glic
            each
            agree to settle disputes (except certain small claims as set forth below) only by Arbitration.
          </p>
          <p>ARBITRATION MEANS THAT YOU WAVE YOUR RIGHT TO A JURY TRIAL.</p>
          <p>The rules in Arbitration are different. There is no judge or jury, and review is limited, but an arbitrator
            can
            award the same damages and relief, and must honor the same limitations stated in these Terms and Conditions
            as a
            court would. The Arbitration will be conducted under the JAMS Streamlined Arbitration Rules Procedures
            (referred
            to as the “JAMS Rules”) and under the rules set forth in these Terms and Conditions. If there is a
            conflict
            between JAMS Rules and the rules set forth in these Terms and Conditions, the rules set forth in these Terms
            and
            Conditions will govern. In Arbitration you may seek any and all remedies otherwise available to you pursuant
            to
            the law of the governing state. If you decide to initiate Arbitration, Glic agrees to pay the
            Arbitration
            initiation fee and any additional deposit required by JAMS to initiate your Arbitration. You and Glic
            agree to
            pay
            the costs of the Arbitration proceedings, provided however that if you are a consumer you shall not be
            required
            to pay more than $250.00 of the fees or such amount as the JAMS Rules may later prescribe. All other fees,
            such
            as attorneys’ fees and expenses of travel to the Arbitration, will be paid in accordance with JAMS Rules.
            The
            arbitration will be held at a location in your home town area if possible, unless you and Glic both
            agree
            to
            another location or telephonic Arbitration. To initiate Arbitration, you or Glic must do the
            following
            things:
          </p>

          <p>(1) Write a demand for Arbitration. The demand must include a description of the Claim and the amount of
            damages sought to be recovered. You can find a copy of a Demand for Arbitration at <a class="u"
              href="https://www.jamsadr.com" target="_blank">www.jamsadr.com</a>.
          </p>
          <p> (2) Send three copies of the Demand for Arbitration, plus the appropriate filing fee to: JAMS [to your
            local
            JAMS office] or to JAMS, 620 Eighth Ave., 34th Floor, New York, NY 10018.
          </p>
          <p> (3) Send one copy of the Demand for Arbitration to the other party.</p>
          <p> Special rules in the Arbitration Proceeding. (i) Except for errors of law, the arbitrator’s decision is
            final
            and binding on all parties and may be enforced in any Federal or State court that has jurisdiction. (ii)
            Neither
            you nor Glic shall be entitled to join or consolidate claims in Arbitration by or against other
            individuals or
            entities, or arbitrate any claim as a representative member of a class or in a private attorney general
            capacity.
          </p>
          <p>
            THIS MEANS THAT YOU WAIVE YOUR RIGHT TO INITIATE OR PARTICIPATE IN ANY CLASS OR CONSOLIDATED ACTION
              WHATSOEVER.
          </p>
          <p>Accordingly, you and Glic agree that the JAMS Class Action Procedures do not apply to our
            Arbitration. A
            court
            may sever any portion of this dispute resolution provision if it finds such unenforceable, except for the
            prohibition on class, representative and private attorney general arbitrations. Notwithstanding the
            obligation
            to arbitrate all Claims under these Terms and Conditions, you may assert an individual Claim in small claims
            court in lieu of Arbitration.
          </p>
          <p>IF YOU ARE A CONSUMER, HOWEVER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER
            PROTECTION LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”
          </p>
        </li>
      </ul>
    </div>
    <div class="repeat">
      <h3>Severability and Enforceability </h3>
      <p>If any provision or portion of these Terms and Conditions is held illegal, invalid, or unenforceable (in whole
        or in part) it will not affect the legality, validity or enforceability of any other provisions or portions of
        these Terms and Conditions and will be modified to the minimum extent necessary to correct any deficiencies or
        replaced with a provision which is as close as is legally permissible to the provision found invalid or
        unenforceable.

      </p>
    </div>
    <div class="repeat">
      <h3>Termination/Exclusion/Waiver </h3>
      <p>We reserve the right, in our sole discretion, to revoke any and all privileges associated with accessing and/or
        using the Services, and to take any other action we deem appropriate. We may terminate or suspend your use of
        the Services, for no reason or any reason whatsoever, including improper use of the Services or failure to
        comply with these Terms and Conditions. Our failure to enforce any term or condition of these Terms and
        Conditions shall not constitute a waiver ‎of any such term or condition, unless we provide such waiver expressly
        and in writing to ‎you.‎
      </p>
      <p>IF YOU ARE A CONSUMER, HOWEVER, THIS PROVISION SHALL NOT BE ‎CONSTRUED TO LIMIT YOUR RIGHTS UNDER CONSUMER
        PROTECTION LAWS OF ‎YOUR STATE OF RESIDENCE AS SET FORTH IN THE SECTION TITLED “CONSUMER PROTECTION NOTICE.”
      </p>
    </div>
    <div class="repeat">
      <h3>General </h3>
      <p>We may assign, novate or subcontract any or all of our rights and obligations under these Terms and Conditions
        at any time.</p>
      <p>You acknowledge and agree that any questions, testimonials, comments, suggestions, ideas or other information provided by you to Glic Health shall become the sole property of us, with no obligation of confidentiality. Glic Health shall be entitled to the unrestricted use and dissemination of these submissions for any purpose, commercial or otherwise without any acknowledgement of or compensation to you.</p>
      <p>The Administrator of the ValpakRx™ Program is Glic Health LLC. IIf you have any questions regarding these Terms and Conditions, please contact Glic Health at: One Marina Park Drive, Suite 1410, Boston, MA 02210‎, by phone at (844) 944-4542, or by e-mail at <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a></p>
    </div>
  </div>
</section>