<div class="search-fields">
  <div class="form-section">
    <input class="btn" type="submit" value="" name="submit" />
    <input class="form-control" type="text" placeholder="What medication are you looking for?"
      (click)="ShowHistory(); $event.stopPropagation()"
      (keyup)="TypeDrug($event); ShowHistory(); $event.stopPropagation()" (focus)="setHeight('focusin')"
      (blur)="clearHeight(); getCardShow()" value="{{ SelectedDrug }}" [(ngModel)]="SelectedDrug" id="SearchDrugText"
      autocomplete="off" (focus)="getCardHide()" />
    <!-- <div *ngIf="errorShowDisplay">
      <span class="errorSearchMsg"
        ><span>{{ searchtextbox_error }}</span></span
      >
    </div> -->
    <!-- <span  [className]="errorShowDisplay ? 'errorSearchMsg' : ''"><span>{{ searchtextbox_error }}</span></span> -->
    <div class="serachlist" *ngIf="ShowDisplay">
      <div class="recent_sesrch" *ngIf="ShowRecentSearch">
        <div class="title nodrugfound" style="color:red" *ngIf="NoDrugsFound">
          <span>Oops! Something is wrong</span>
          <p>There are no items found with this search. Double check your prescription.</p>
        </div>
        <div class="title heading" id="RS">Recent Searches</div>
        <ul>
          <li *ngFor="let item of SearchItemArray" (focusout)="setHeight('focusout')" (click)="DrugSetting(item.text)">
            {{ item.text }}
          </li>
          <!-- <li>Atorvastatin ( <span>Generic Lipitor</span> )</li>
            <li>Atenolol ( Generic Tenormin )</li> -->
        </ul>
      </div>
    </div>

    <!---List based on user typed drug-->
    <div class="serachlist" *ngIf="DisplayBindList">
      <ul>
        <li *ngFor="let item of SearchList" (click)="DrugSetting(item.DrugName)">
          {{ item.DrugName }}
        </li>
      </ul>
    </div>
    <div class="search_btn btn lowest btnshover" (click)="ClickDrug()">
      Find Lowest Price
    </div>
  </div>
</div>
<!-- Popular List -->
<div class="popular_sesrch">
  <div class="title heading" id="PS" style="display: block;">
    Popular Searches
  </div>
  <ul>
    <li *ngFor="let item of PopularDrugs" (click)="DrugSetting(item.DisplayName)">
      {{ item.DrugName }}
    </li>
  </ul>
</div>
<!--Search pharmacy popup-->
<!-- <div class="search_pharmacy_popup" *ngIf="ShowDrugdetails"> -->
<div class="search_pharmacy_popup" *ngIf="ShowDrugdetails">
  <div class="search_pharmacy_select">
    <!-- <h2>Atenolol ( Generic Tenormin )</h2> -->
    <!-- <h2>{{ SelectedDrug }}</h2> -->
    <h2>Search Details</h2>
    <div class="filter_box">
      <div class="close_search_pharmacy_popup" (click)="CloseDrugDetails()"></div>
      <div class="lists_box" [ngClass]="{ singleDoseForm: DrugNameTypeSingle }">
        <div (click)="hideme[0] = !hideme[0]; $event.stopPropagation()">
          <label>Type</label>
          <div class="filtertext">
            {{ SelectedDrug }}
          </div>
          <i></i>
        </div>
        <div class="lists" [hidden]="!hideme[0]">
          <label>Type</label>
          <div class="filtertext">
            {{ SelectedDrug }}
          </div>
          <ul>
            <li *ngFor="let item of DrugTypeList" (click)="addActive_select($event, 0, item, '')"
              [ngClass]="{ active: item == Swap_Drug_With_MultiSource }">
              {{ item }}
            </li>
            <!-- <li>None</li>
                  <li>Atenolol</li>
                  <li>Atenolol</li>
                  <li>Atenolol</li> -->

          </ul>
          <div class="closepop" (click)="hideme[0] = !hideme[0]; $event.stopPropagation()"></div>
        </div>
      </div>
      <div class="lists_box" [ngClass]="{ singleDoseForm: DrugFormSingle }">
        <div (click)="hideme[1] = !hideme[1]; $event.stopPropagation()">
          <label>Form</label>
          <div class="filtertext">
            {{ DrugType }}
          </div>
          <i></i>
        </div>
        <div class="lists formm" [hidden]="!hideme[1]">
          <label>Form</label>
          <div class="filtertext">
            {{ DrugType }}
          </div>
          <ul>
            <li *ngFor="let item of optionlist" (click)="addActive_select($event, 1, item.text, '')"
              [ngClass]="{ active: item.text == DrugType }">
              {{ item.text }}
            </li>
          </ul>
          <div class="closepop" (click)="hideme[1] = !hideme[1]; $event.stopPropagation()"></div>
        </div>
      </div>
      <div class="lists_box" [ngClass]="{ singleDoseForm: DrugDoseSingle }">
        <div (click)="hideme[2] = !hideme[2]; $event.stopPropagation()">
          <label>Dosage</label>
          <div class="filtertext">
            {{ DrugDose }}
          </div>
          <i></i>
        </div>
        <div class="lists" [hidden]="!hideme[2]">
          <label>Dosage</label>
          <div class="filtertext">
            {{ DrugDose }}
          </div>
          <ul>
            <li *ngFor="let item of DrugDoseList" (click)="addActive_select($event, 2, item, '')"
              [ngClass]="{ active: item == DrugDose }">
              {{ item }}
            </li>

          </ul>
          <div class="closepop" (click)="hideme[2] = !hideme[2]; $event.stopPropagation()"></div>
        </div>
      </div>
      <div class="lists_box">
        <div (click)="hideme[3] = !hideme[3]; $event.stopPropagation()">
          <label>Quantity</label>
          <div class="filtertext">
            {{ DrugQuant }}
          </div>
          <i></i>
        </div>
        <div class="lists" [hidden]="!hideme[3]">
          <label>Quantity</label>
          <div class="filtertext">
            {{ DrugQuant }}
          </div>
          <ul>
            <li *ngFor="let item of DrugQuantityList" (click)="addActive_select($event, 3, item, item.NDCCode)"
              [ngClass]="{ active: item == DrugQuant }">
              {{ item.DisplayQuantity }}
            </li>
            <!-- <li (click)="addActive_select($event, -3, QuantityManual)">
              Enter a Quantity
            </li> -->

          </ul>
          <div class="closepop" (click)="hideme[3] = !hideme[3]; $event.stopPropagation()"></div>
        </div>
      </div>
      <div class="lists_box PharmacyNear">
        <div>
          <label>Pharmacies Near</label>

          <!-- <div class="filtertext">Oakland, CA 94618, USA</div> -->
          <div class="filtertext clearfix">
            <input id="getzipcode" type="tel" placeholder="Type zipcode" value="{{ _CurrLocation }}"
              [(ngModel)]="_UserZip" (click)="ClearErrorMessage()" (keypress)="ZipCodeValidate($event)"
              autocomplete="off" (keyup)="zipCodeLenthInit()" maxlength="5" />
          </div>
          <!-- <i></i> -->
          <span>{{ ZipCodeMessage }}</span>
        </div>
      </div>
      <div class="autopic_location">
        <input type="checkbox" id="pick_location" (change)="GetAutoLocation($event)" />
        <!-- <label for="pick_location"><span></span>Auto pick my location</label> -->
        <label for="pick_location"><span></span></label>
      </div>
      <div class="find_button btnshover" (click)="Redirect_LoadPharmacy()">
        Find Lowest Price
      </div>
    </div>
  </div>
</div>

<!-- Enter Quantity Manual Box -->
<div class="change_password quantity_box_manual" *ngIf="ManualQuantity_box">
  <div class="changepassword_main forgot_pass custom_quantity">
    <div class="close_search_pharmacy_popup" (click)="removeforgot($event)"></div>
    <h2>Enter a quantity</h2>
    <div class="input_box">
      <label for="Quantity_Manual">Quantity</label>
      <div class="clearfix">
        <input type="number" id="Quantity_Manual" name="Quantity_Manual" placeholder="0" [(ngModel)]="Quantity_Manual"
          (keypress)="DrugQuantityValidate($event)" />
      </div>
      <span id="Quant_Error">{{ Quantity_error }}</span>
      <div class="clearfix"></div>
      <div class="reset_button" (click)="SubmitQuantity()">Submit</div>
    </div>
  </div>
</div>
<!-- Loader Screen -->
<div class="loaderscreen showloader" *ngIf="showloader">
  <div class="loaderpill">
  </div>
  <div>
    <div class="first"></div>
    <div class="second"></div>
    <div class="third"></div>
    <div class="fourth"></div>
    <div class="fifth"></div>
    <div class="sixth"></div>
    <div class="seventh"></div>
  </div>
</div>

<!--Search pharmacy popup-->
<div class="search_pharmacy_popup no_details_available" *ngIf="ShowNoDetails">
  <div class="search_pharmacy_select">
    <img src="../../assets/images/no-result@1x.svg" alt="" />
    <h2>No details available for this drug.</h2>
    <div class="filter_box">
      <div class="close_search_pharmacy_popup" (click)="CloseNoDetails()"></div>

    </div>
  </div>
</div>