import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService } from "../services/api.service";
import * as $ from "jquery";
@Component({
  selector: "app-getcard",
  templateUrl: "./getcard.component.html",
  styleUrls: ["./getcard.component.scss"],
  providers: [ApiService],
})
export class GetcardComponent implements OnInit {
  email: any = "";
  first_name: any = "";
  last_name: any = "";
  tags: any = "";
  errormessageFName: any = "";
  errormessageLName: any = "";
  errormessageEmail: any = "";
  cardsuccess: any = false;
  constructor(private savecard: ApiService) { }

  ngOnInit() {
    $("#myModal").on("click", function () {
      $(".getCardSectionOne")
        .find(".form-group input")
        .removeAttr("style")
        .next()
        .next()
        .hide();
      $(".getCard").show();
    });
    $(".getCard").on("click", function () {
      $(".getCardSectionOne")
        .find(".form-group input")
        .removeAttr("style")
        .next()
        .next()
        .hide();
      $(".getCard").hide();
    });
    // $(".close").click(function() {
    //   $(this)
    //     .parent()
    //     .find(".form-group input")
    //     .removeAttr("style")
    //     .next()
    //     .next()
    //     .hide();
    // });
    $("#GetSubmit").click(function () {
      alert();
    });
  }
  clearField(event) {
    this.removeerror(event);
  }
  closecard(event) {
    this.cardsuccess = false;
    this.email = "";
    this.first_name = "";
    this.last_name = "";
    $(event.target).parent().find(".form-group").removeClass("error_msg");
    $(".getCard").show();
  }
  // submitCard(event) {
  //   $(event.target).addClass("loader");
  //   event.stopImmediatePropagation();
  //   var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (
  //     this.first_name != "" &&
  //     this.last_name != "" &&
  //     this.email != "" &&
  //     this.email.match(mailformat)
  //   ) {
  //     this.savecard
  //       .checkemailexist(this.email)
  //       // .pipe(map((res) => res.json()))
  //       .subscribe((data) => {
  //         ;
  //         if (data.result_message == "Success: Something is returned") {
  //           this.savecard
  //             .removetag(this.email)
  //             // .pipe(map((res) => res.json()))
  //             .subscribe((data) => {
  //               this.savecard
  //                 .addtag(this.email)
  //                 // .pipe(map((res) => res.json()))
  //                 .subscribe((data) => {
  //                   this.email = "";
  //                   this.first_name = "";
  //                   this.last_name = "";
  //                   this.cardsuccess = true;
  //                   $(event.target).removeClass("loader");
  //                 });
  //             });
  //         } else {
  //           this.savecard
  //             .addActiveContact(
  //               this.email,
  //               this.first_name,
  //               this.last_name,
  //               "HDWebsite"
  //             )
  //             .pipe(map((res) => res.json()))

  //             .subscribe((data) => {
  //               ;
  //               this.email = "";
  //               this.first_name = "";
  //               this.last_name = "";
  //               // $("#getsucess").show();
  //               //   setTimeout(() => {
  //               //     $("#getsucess").fadeOut("slow");
  //               //   }, 1000);
  //               this.cardsuccess = true;
  //               $(event.target).removeClass("loader");
  //             });
  //         }
  //       });
  //   }
  //   if (this.first_name == "") {
  //     $(event.target).removeClass("loader");
  //     $("#fnameerror").parent().addClass("error_msg");
  //     this.errormessageFName = "Please enter your first name";
  //     this.cardsuccess = false;
  //   }
  //   if (this.last_name == "") {
  //     $(event.target).removeClass("loader");
  //     $("#lnameerror").parent().addClass("error_msg");
  //     this.errormessageLName = "Please enter your last name";
  //     this.cardsuccess = false;
  //   }
  //   if (this.email != "" && !this.email.match(mailformat)) {
  //     $(event.target).removeClass("loader");
  //     $("#emailerror").parent().addClass("error_msg");
  //     this.errormessageEmail = "Please enter a valid email address";
  //     this.cardsuccess = false;
  //   }
  //   if (this.email == "") {
  //     $(event.target).removeClass("loader");
  //     $("#emailerror").parent().addClass("error_msg");
  //     this.errormessageEmail = "Please enter a valid email address";
  //     this.cardsuccess = false;
  //   }
  // }
  removeerror(event) {
    $(event.target).removeClass("loader");
    $(event.target).parent().removeClass("error_msg");
  }
  removeErrorOut() {
    $("#fnameerror,#lnameerror,#emailerror").css({ display: "none" });
    $("#fnameerror,#lnameerror,#emailerror").prev().prev().removeAttr("style");
  }
}
