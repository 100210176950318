import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
// import { HttpModule } from "@angular/http";
import { PharmacyComponent } from "./pharmacy/pharmacy.component";
import { MycardsComponent } from "./mycards/mycards.component";
import { AgmCoreModule } from "@hudsontavares/agm-core";
import { GetcardComponent } from './getcard/getcard.component';
import { SearchComponent } from './search/search.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AboutUsComponent } from './about-us/about-us.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AccordianComponent } from './accordian/accordian.component';
import { DownloadComponent } from './download/download.component';
import { HowWorksComponent } from './how-works/how-works.component';
import { HelpComponent } from './help/help.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { DomainAffiliateComponent } from './domain-affiliate/domain-affiliate.component';
import { ComplianceComponent } from './compliance/compliance.component';
import {PrintmycardComponent } from './printmycard/printmycard.component';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaLoaderService,
  RecaptchaModule,
} from 'ng-recaptcha';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProgramDescriptionComponent } from './program-description/program-description.component';
import { RedirectTocComponent } from './redirect-toc/redirect-toc.component';
import { DrugsCoveredByTheProgramComponent } from './drugs-covered-by-the-program/drugs-covered-by-the-program.component';
import { LoginComponent } from './login/login.component';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { MarketingMaterialsComponent } from './marketing-materials/marketing-materials.component';
import { RecoverAccessCodeComponent } from './recover-access-code/recover-access-code.component';
import { AgentsupportComponent } from './agentsupport/agentsupport.component';
import { CategoryComponent } from './category/category.component';
import { PdflinkComponent } from './pdflink/pdflink.component';

const globalSettings: RecaptchaSettings = { siteKey: '6Lc_seIUAAAAALojSGOFtCDtWLGAspEYrc7sgu1n' };
const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "Home", component: HomeComponent },
  { path: "Pharmacy", component: PharmacyComponent },
  { path: "Pharmacy/:name", component: PharmacyComponent },
  { path: "MyCards", component: MycardsComponent },
  { path: 'aboutUs', component: AboutUsComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'download-mobile-app', component: DownloadComponent },
  { path: 'how-it-works', component: HowWorksComponent },
  { path: 'help', component: HelpComponent },
  { path: 'termsofuse', component: RedirectTocComponent },
  { path: 'terms-and-conditions', component: TermsofuseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'program-description', component: ProgramDescriptionComponent },
  { path: 'domain-affiliate', component: DomainAffiliateComponent },
  { path: 'compliance', component: ComplianceComponent },
  { path: 'drugs-covered', component: DrugsCoveredByTheProgramComponent },
  { path: 'guidebook-and-resources', component: MarketingMaterialsComponent },
  // { path: 'enrollmentcenter', component: LoginComponent },
  { path: 'enrollmentcenter/:name', component: LoginComponent },
  { path: 'enrollmentcenter', component: LoggedInComponent },
  { path: 'printmycard', component: PrintmycardComponent },
  { path: 'recoverAccessCode', component: RecoverAccessCodeComponent },
  // { path: 'cards/TracFone-GlicRx-Card.pdf', component: PdflinkComponent },
  { path: 'agentsupport', component: AgentsupportComponent },
  { path: "**", component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    PricingComponent,
    ContactUsComponent,
    HeaderComponent,
    FooterComponent,
    PharmacyComponent,
    MycardsComponent,
    GetcardComponent,
    SearchComponent,
    AccordianComponent,
    DownloadComponent,
    HowWorksComponent,
    HelpComponent,
    TermsofuseComponent,
    RedirectTocComponent,
    DomainAffiliateComponent,
    PrivacyPolicyComponent,
    ProgramDescriptionComponent,
    ComplianceComponent,
    DrugsCoveredByTheProgramComponent,
    LoginComponent,
    LoggedInComponent,
    MarketingMaterialsComponent,
    PrintmycardComponent,
    RecoverAccessCodeComponent,
    AgentsupportComponent,
    CategoryComponent,
    PdflinkComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes

      // { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    HttpClientModule,
    // HttpModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC46tn0xWqdGs1bD60z4cFakEh510tR3dw",
      libraries: ['places']
    }),
    RecaptchaModule,
    SlickCarouselModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
