<header _ngcontent-woc-c0="" class="header-section">
  <div _ngcontent-woc-c0="" class="comman-width menuFJC">
    <a class="logo" routerlink="/Home" routerlinkactive="" href="/Home"><img _ngcontent-xlb-c0="" alt="hdrx-logo"
        src="../assets/images/HonestDiscounts.svg" /></a>
  </div>
</header>

<section class="domain-affiliate">
  <div class="comman-width">
    <form class="formData">
      <div class="top">
        <div>
          <label>Vendor</label>
          <p>
            <input type="text" id="vender" autocomplete="off" (focus)="clearMsg('vender')" />
            <span>Vendor name is required</span>
          </p>
        </div>
        <div>
          <label>Group</label>
          <p>
            <input type="text" id="group" autocomplete="off" (focus)="clearMsg('group')" />
            <span>Group Number is required</span>
          </p>
        </div>

        <div>
          <label>PCN</label>
          <p>
            <input type="text" id="pcn" autocomplete="off" (focus)="clearMsg('pcn')" />
            <span>PCN is required</span>
          </p>
        </div>

        <div>
          <label>https://valpakrx.com/</label>
          <p>
            <input type="text" id="subText" minlength="3" required autocomplete="off" (focus)="clearMsg('subText')" />
            <span></span>
          </p>
        </div>
      </div>
      <div class="bottom">
        <div>
          <label>Description</label>
          <p>
            <input type="text" id="descText" minlength="10" required autocomplete="off" (focus)="clearMsg('subText')" />
            <span>Description is required</span>
          </p>
        </div>
        <div class="upload-image">
          <label>Image</label>

          <div class="avatar-upload">
            <div class="avatar-edit">
              <p>
                <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                  (change)="uploadFile($event)" />
                <span class="image-required">Image is required</span>
              </p>
            </div>
            <div class="avatar-preview" style="height: 50px; width: 50px;">
              <div id="imagePreview" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
            </div>
          </div>
          <button type="submit" class="btn btn-danger btn-lg btn-block" (click)="fileInput.click()">
            Upload Image
          </button>
        </div>

        <div>
          <p>
            <button class="submit-btn" type="submit" (click)="onSubmit()">
              Submit
            </button>
          </p>
        </div>
      </div>
    </form>
    <div class="sucess">{{ sucess_message }}</div>
  </div>
  <div class="comman-width">
    <form class="searchData">
      <input type="text" #searchInput minlength="3" autocomplete="off" placeholder="Please Enter Search Text"
        (focus)="clearMsg('searchID')" id="searchID" />
      <span>Please Enter Search Text</span>
      <button type="submit" (click)="onSearch(searchInput.value)">
        Search
      </button>
    </form>
  </div>
  <div class="comman-width">
    <ul class="header">
      <li>Sr. No.</li>
      <li>Vendor Name</li>
      <li>Group Number</li>
      <li>PCN</li>
      <li>Affiliate Text</li>
      <li>Desc</li>
      <li>Image</li>
      <li>pdf file</li>
      <li>Upload pdf</li>
    </ul>
    <div class="getData">
      <div *ngFor="let data of domainAffilateData">
        <ul>
          <li>{{ data.Sr }}</li>
          <li>{{ data.vname }}</li>
          <li>{{ data.group }}</li>
          <li>{{ data.pcn }}</li>
          <li>{{ data.ftext }}</li>
          <li>{{ data.desc }}</li>
          <li>
            <img *ngIf="data.image64" [src]="transform(data.image64)" alt="" style="height: 50px;" />
          </li>
          <li>
            <!-- <iframe
              *ngIf="data.pdf64"
              [src]="transformpdf(data.pdf64)"
              style="height: 50px;"
            ></iframe> -->
            <div style="cursor:pointer;" (click)="openpdf(data.ftext)" *ngIf="data.ispdvavail">{{data.pdfname}}</div>
            <div *ngIf="!data.ispdvavail">No pdf</div>
          </li>
          <li>
            <input type="file" id="{{ data.ftext }}" name="photo" (change)="uploadPdf($event)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>