import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-materials',
  templateUrl: './marketing-materials.component.html',
  styleUrls: ['./marketing-materials.component.scss']
})
export class MarketingMaterialsComponent implements OnInit {
  categoryPage: boolean = false;
  currentCategory: string;
  currentTitle: string;

  constructor() { 
    
  }
  ngOnInit() {
    let scope = this;
    (function (){
      let categoryObj = JSON.parse(localStorage.getItem('category'));
      let stringLength = categoryObj.name.length;
      if(stringLength > 0){
        scope.categoryPage = true;
        scope.currentCategory = categoryObj.name;
        scope.currentTitle = categoryObj.title;    
        $('.category').addClass('detail');
      } 
    })();
  }
  ngOnDestroy() {
    $('body').removeClass('broker-pages');
    localStorage.setItem('category', '');
    $('.category').removeClass('detail');
  }
  categoryData(e){
    this.categoryPage = true;
    this.currentCategory = e;
    this.currentTitle = $('#'+e+' span').text();
    const categoryObj = {
      name: this.currentCategory,
      title: this.currentTitle
    }
    localStorage.setItem('category', JSON.stringify(categoryObj));
    $('.category').addClass('detail');
  }
  resourcePage(){
    this.categoryPage = false;
    localStorage.setItem('category', '');
    $('.category').removeClass('detail');
  }
}
