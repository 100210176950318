import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-redirect-toc',
  templateUrl: './redirect-toc.component.html',
  styleUrls: ['./redirect-toc.component.scss']
})
export class RedirectTocComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    ; //1231232
    this.router.navigate(["/terms-and-conditions"]);
  }

}
