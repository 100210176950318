import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SaveService {
  /* logo */
  logo = "../assets/images/header_logo.svg";

  /* brand name2 */
  brandName2 = "GlicRx";

  /* brand name in footer */
  brandNameFooter = "GlicRx";

  /* brand name */
  brandName = "GlicRx";

  /* acceptSection logos */
  acceptSectionIcons = [
    { img: "../assets/images/pharmacist_logo1.webp", alt: "pharmacist_logo1" },
    { img: "../assets/images/pharmacist_logo2.webp", alt: "pharmacist_logo2" },
    { img: "../assets/images/pharmacist_logo3.webp", alt: "pharmacist_logo3" },
    // { img: "../assets/images/pharmacist_logo4.jpg", alt:'pharmacist_logo4'},
    { img: "../assets/images/pharmacist_logo5.webp", alt: "pharmacist_logo5" },
    // { img: "../assets/images/pharmacist_logo6.jpg", alt:'pharmacist_logo6'},
    // { img: "../assets/images/pharmacist_logo7.jpg", alt:'pharmacist_logo7'},
    { img: "../assets/images/pharmacist_logo8.webp", alt: "pharmacist_logo8" },
    { img: "../assets/images/pharmacist_logo9.webp", alt: "pharmacist_logo9" }
  ];

  /* accept section method */
  getAcceptSectionLogos() {
    return this.acceptSectionIcons;
  }

  /* brand name method */
  getBrandName() {
    return this.brandName;
  }

  /* brand name second method */
  getSecondBrandName() {
    return this.brandName2;
  }

  /* brand name in footer */
  getFooterBrandName() {
    return this.brandNameFooter;
  }

  /* logo method */
  getLogo() {
    return this.logo;
  }

  constructor() { }
}
