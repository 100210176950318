<section class="marketing-section">
  <div class="breadcrum" *ngIf="categoryPage">
    <ul>
      <li><a href="javascript:void('')" (click)="resourcePage()">Guidebook and Resources</a></li>
      <li>{{currentTitle}}</li>
    </ul>
  </div>
  <h2 *ngIf="!categoryPage">Guidebook and Resources</h2>    
  <h2 *ngIf="categoryPage">{{currentTitle}}</h2>    
  <!-- <input autocomplete="off" class="form-control" placeholder="Search" type="text" /> -->
  
  <div class="category">
    <ul *ngIf="!categoryPage" class="category-list">
      <li id="category1" (click)="categoryData('category1')"><div>A.  <span>Agent FAQ and Helpful Information</span></div></li>
      <li id="category2" (click)="categoryData('category2')"><div>B.  <span>Marketing Tools and Pre-Approved Materials</span></div></li>
      <li id="category3" (click)="categoryData('category3')"><div>C.  <span>Editing & Printing your Digital and Physical Discount Card</span></div></li>
      <li id="category4" (click)="categoryData('category4')"><div>D.  <span>Client/Customer Questions & Answers</span></div></li>
      <li id="category5" (click)="categoryData('category5')"><div>E.  <span>Prescription Pricing Resources</span></div></li>
      <li id="category6" (click)="categoryData('category6')"><div>F.  <span>How to use the Enrollment Center</span></div></li>
    </ul>
    <app-category *ngIf="categoryPage" [title]="currentTitle" [id]="currentCategory"></app-category>
  </div>

</section>