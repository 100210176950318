import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { MedicinService } from '../services/index';
import { map } from "rxjs/operators";
@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.css']
})
export class AccordianComponent implements OnInit {

  constructor(public ms: MedicinService) { }
  public faqData: any = [];
  public collapse: any;
  ngOnInit() {
    this.GetFaq();
  }

  GetFaq() {
    this.ms.GetFaq()
    // .pipe(map(res => res.json()))
      .subscribe(data => {
        if (data) { 
          // console.log(data)
          this.faqData = JSON.parse(data);
        }
        else {
          //error
        }
      });
  }

  // toggleCollapse() {
  //   $(".removeAttr").removeAttr('aria-expanded');
  //   $('.panel-collapse').removeClass('show');
  // }
  toggleCollapse(val) {
    $('.panel-collapse').removeClass('show');
    let idSet = '#' + val;
    let collapseSet = '#collapse' + val;
    let expandedValue = $(idSet).attr('aria-expanded');
    if (expandedValue == 'true') {
      $(idSet).attr('aria-expanded', 'false');
      $(collapseSet).removeClass('show');
      $(collapseSet).parent().removeClass('active');
    } else {
      $(".removeAttr").attr('aria-expanded', 'false');
      $(idSet).attr('aria-expanded', 'true');
      $(collapseSet).addClass('show');
      $('.panel-default').removeClass('active');
      $(collapseSet).parent().addClass('active');
    }
  }

}
