<div class="firstloadpricing">
  <p class="title">
    Pricing Tool
  </p>
  <div class="price_searcharea">
    <div class="zipbox">
      <!-- <input
        id="getzipcode"
        type="tel"
        placeholder="Type zipcode"
        value="{{ _CurrLocation }}"
        [(ngModel)]="_ZipCode"
        (click)="ClearErrorMessage()"
        (keypress)="ZipCodeValidate($event)"
        autocomplete="off"
        (keyup)="zipCodeLenthInit()"
        maxlength="5"
      /> -->
      <input id="getzipcode" type="tel" placeholder="Type zipcode" value="{{ _CurrLocation }}" [(ngModel)]="_ZipCode"
        (click)="ClearErrorMessage()" (keypress)="ZipCodeValidate($event)" autocomplete="off" maxlength="5" />
      <div>
        <input type="checkbox" id="pick_location" (change)="GetAutoLocation($event)" />
        <!-- <label for="pick_location"><span></span>Auto pick my location</label> -->
        <label for="pick_location"><span></span>Use my location</label>
      </div>
    </div>
    <!-- <div class="medicinenamesearch" id="CheckLoginState">
      <app-search></app-search>
    </div> -->
    <div class="medicinenamesearch">
      <app-search></app-search>
    </div>
  </div>
</div>
<div>
  <div class="pharmacyPage">
    <div class="container" id="pharmacy_container">
      <div class="pharmacy_box">
        <!-- <div class="select_filterList_parent ie_fixed_add_select_filterList_parent" id="CheckLoginState"></div> -->
        <div class="select_filterList_parent ie_fixed_add_select_filterList_parent">
          <h2>Search Details</h2>
          <i (click)="hide_filter_mobile_on()"></i>
          <div class="filter_box" [ngClass]="{ singleDoseForm: DrugNameTypeSingle }">
            <!-- <div class="filter_box"> -->
            <label>Type</label>
            <div class="type_box" (click)="hideothermenu()">
              {{ _DrugName }}
            </div>
            <div class="type_list">
              <ul>
                <li *ngFor="let item of DrugNameTypeList" [ngClass]="{ active: item == Swap_Drug_With_MultiSource_New }"
                  (click)="SwapDrugname(item)">
                  {{ item }}
                </li>
                <!-- <li class="active">Tablet</li> -->
              </ul>
              <div class="collapse_box">
                Collapse
              </div>
            </div>
          </div>
          <div class="filter_box" [ngClass]="{ singleDoseForm: DrugFormSingle }">
            <label>Form</label>
            <div class="type_box" (click)="hideothermenu()">
              {{ _Type }}
            </div>
            <div class="type_list">
              <ul>
                <li *ngFor="let item of DrugFormTypeList" [ngClass]="{ active: item.text == _Type }"
                  (click)="SwapDrugForm(item.text)">
                  {{ item.text }}
                </li>
                <!-- <li class="active">Tablet</li> -->
              </ul>
              <div class="collapse_box">
                Collapse
              </div>
            </div>
          </div>
          <div class="filter_box" [ngClass]="{ singleDoseForm: DrugDoseSingle }">
            <label>Dosage</label>
            <div class="type_box" (click)="hideothermenu()">
              {{ _Dose }}
            </div>
            <div class="type_list">
              <ul>
                <li *ngFor="let item of DrugDoseTypeList; let i = index" [ngClass]="{ active: item == _Dose }"
                  (click)="SwapDrugDose(item, i)">
                  {{ item }}
                </li>
                <!-- <li class="active">Tablet</li> -->
              </ul>
              <div class="collapse_box">
                Collapse
              </div>
            </div>
          </div>
          <div class="filter_box">
            <label>quantity</label>
            <div class="type_box" (click)="hideothermenu()">{{ _Quant }}</div>
            <div class="type_list">
              <ul>
                <li *ngFor="let item of DrugQuantityTypeList" [ngClass]="{ active: item == _Quant }"
                  (click)="SwapDrugQuantity(item.Quantity, item.NDCCode, item.DisplayQuantity)">
                  {{ item.DisplayQuantity }}
                </li>
                <!-- <li (click)="OpenQuantity_Box()">Enter a Quantity</li> -->
                <!-- <li>Powder</li>
              <li class="active">Tablet</li>
              <li>Suspension</li> -->
              </ul>
              <div class="collapse_box">
                Collapse
              </div>
            </div>
          </div>
          <div class="filter_box PharmacyNear">
            <label>Pharmacies Near</label>
            <div class="type_box Noddl" (click)="hideothermenu()">
              <input type="tel" placeholder="Type zipcode" [(ngModel)]="_ZipCode" value="{{ _CurrLocation }}"
                (click)="ClearErrorMessage()" (keypress)="ZipCodeValidate($event)" maxlength="5" />
              <div class="location_icon">
                <input type="checkbox" (change)="GetAutoLocation($event)" />
              </div>
            </div>
          </div>
          <span style="color: red;">{{ ZipCodeMessage }}</span>
          <!-- <div class="update_btn" [class.loader]="pharmacy_list.length == 0" (click)="UpdatePharmacyList()">Update Search</div> -->
          <div class="update_btn btnshover" (click)="UpdatePharmacyList(); CloseDrugDetails()">
            Update Search
          </div>
        </div>

        <div class="pharmacylist_parent">
          <div class="right_btn_sec">
            <div class="map_btn">
              <input type="checkbox" [disabled]="firstloadpricing" id="locate" (click)="Showmapview()" />
              <label for="locate">
                <i></i>
                Locate on Map
              </label>
            </div>
            <div class="filterbtn_mobile" (click)="filteron_mobile()">
              <i></i>
              Filters
            </div>
            <div class="sortby">
              Sort By
              <div class="sortby_ddl" [ngClass]="!List_Pharmacy_Available ? 'inactive' : ''">
                <ul *ngIf="IsDistancesort">
                  <li (click)="SortPharmacyList('Price')">Price</li>
                  <li (click)="SortPharmacyList('Distance')" class="active">
                    Distance 
                  </li>
                </ul>
                <ul *ngIf="IsPricesort">
                  <li (click)="SortPharmacyList('Price')" class="active">
                    Price
                  </li>
                  <li (click)="SortPharmacyList('Distance')">Distance</li>
                </ul>
              </div>
              <!-- Sort by list -->
            </div>
          </div>
          <div class="map_list_box">
            <div *ngIf="firstloadpricing" class="firstloadimg">
              <img src="../../assets/images/firstload_pharm.png" alt="" />
            </div>
            <div class="pharmacy_list ie_fixed_add_pharmacy_list" *ngIf="List_Pharmacy_Available && !firstloadpricing"
              [ngClass]="mapview ? 'smallcard' : ''">
              <h2>Pharmacies Available</h2>
              <!-- Discount Card -->
              <div class="showdiscountcard_view">
                <div class="discount_card" [ngClass]="mapview ? 'mapopened' : ''" *ngIf="showcard" id="print-section">
                  <div class="discount_card_parent">
                    <!-- <div class="header">
                    <div class="button_sec">
                      <div
                        class="save"
                        (click)="SaveCard()"
                        [class.saved]="IsSave == 'Remove'"
                      >
                        {{ IsSave }}
                      </div>
                      <div class="print" (click)="PrintMe()">Print</div>
                    </div>
                  </div> -->
                    <div class="pharmacybox">
                      <div class="name_quantity">
                        <p>{{ SelectPharmacy }}</p>
                        <span>{{ Card_Pharmacy_Distance }}</span>
                      </div>
                      <!--<div class="discount_Sec">{{Savings_Percent}}<span>{{RetailPrice}}</span></div>-->
                      <div class="price">                        
                        {{ PharmacyPrice }}
                      </div>
                    </div>
                    <div class="address" (click)="RedirectGoogleDirection()">
                      {{ PAddress2 }}
                    </div>
                    <div class="address phonenumber">
                      <a href="tel:{{ HelpLine }}">{{ HelpLine }}</a>
                    </div>
                    <!-- <div class="logoarea" *ngIf="Card_Pharmacy_Logo"> -->
                    <!-- <div class="singlecare">
                        <img src="../../assets/images/singlecare.svg" alt="" />
                      </div> -->
                    <!-- <div class="walmart_img" *ngIf="RXS_Walmart">
                        <span>Available at</span>
                        <img
                          src="http://rxhacker.23byt.es/MailSnap/wallmart.svg"
                          alt=""
                        />
                      </div>
                      <div class="cvs_img" *ngIf="RXS_CVS">
                        <span>For exclusive use at</span>
                        <img
                          src="http://rxhacker.23byt.es/MailSnap/cvs.svg"
                          alt=""
                        />
                      </div> -->
                    <!-- </div> -->

                    <div class="pharmacyDetail">
                      <!-- <div class="name">
                      {{ SelectPharmacy }}
                    </div>
                    <div class="address">{{ PAddress2 }}</div>
                    <div class="number">{{ PharmacyPhone }}</div> -->
                      <!-- <div class="method_salt">
                      <div class="method">
                        <div>bin</div>
                        <div>{{ RXBin }}</div>
                      </div>
                      <div class="method">
                        <div>PCN</div>
                        <div>{{ RXCPN }}</div>
                      </div>
                      <div class="method">
                        <div>Member ID</div>
                        <div>{{ MemberId }}</div>
                      </div>
                      <div class="method">
                        <div>Group</div>
                        <div>{{ GroupNumber }}</div>
                      </div>
                    </div> -->
                      <p>Send discount card via email</p>
                      <div class="email_field" style="position: relative;">
                        <input type="email" id="email" name="ShareEmail" placeholder=" " [(ngModel)]="UserInputEmail"
                          (click)="ClearMessage($event)" (blur)="ClearMessage($event)" (keypress)="clearClassMsg()"
                          placeholder="Enter Your Email" autocomplete="off" />
                        <!-- <label for="email">Enter your e-mail</label> -->

                        <!-- <div class="loadProcess"></div> -->
                      </div>
                      <span style="color: red;">{{ SendDiscountMessage }}</span>
                      <!-- <div class="helpline_btn">
                      <div class="helpline" (click)="PharmacyHelpLine()">
                        {{ PharmacistHelpline }}
                      </div>
                      <div
                        class="direction"
                        (click)="RedirectGoogleDirection()"
                      >
                        Get Directions
                        <img src="../../assets/images/direction.svg" alt="" />
                      </div>
                    </div> -->

                      <table class="sharecardtable"
                        style="width:400px;background:url(../assets/images/bkgd.png) no-repeat;background-size:cover;">
                        <tr>
                          <td style="padding-left:30px;padding-right:30px;padding-top:30px;">
                            <table style="width:100%;">
                              <tr>
                                <td style="font-family: 'Roboto';font-weight:700;color:#141f2b;font: size 18px;">
                                  <div
                                    *ngIf="SelectPharmacy == 'Walgreens' || SelectPharmacy == 'Walmart Neighborhood Market' || SelectPharmacy == 'Walmart Pharamacy' || SelectPharmacy == 'Walmart Pharmacy' || SelectPharmacy == 'CVS Pharmacy' || SelectPharmacy == 'Osco Drug' || SelectPharmacy == 'Osco Pharmacy' || SelectPharmacy == 'Mariano’s'|| SelectPharmacy == 'Safeway Pharmacy' || SelectPharmacy == 'Rite Aid Pharmacy' || SelectPharmacy == 'Fred Meyer Pharmacy' || SelectPharmacy == 'Sav-On' || SelectPharmacy == 'Ralphs Pharmacy' || SelectPharmacy == 'Kroger Pharmacy' || SelectPharmacy == 'Vons Pharmacy' || SelectPharmacy == 'Marianos Pharmacy' || SelectPharmacy == 'Costco'">
                                    <img src="../assets/images/wallgreen.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Walgreens'">
                                    <img src="../assets/images/walmart.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Walmart Neighborhood Market'">
                                    <img src="../assets/images/walmart.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Walmart Pharamacy' || SelectPharmacy == 'Walmart Pharmacy'">
                                    <img src="../assets/images/cvsp.png" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'CVS Pharmacy'">
                                    <img src="../assets/images/osco.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Osco Drug' || SelectPharmacy == 'Osco Pharmacy'">
                                    <img src="../assets/images/marianos.png" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Mariano’s'">
                                    <img src="../assets/images/safeway.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Safeway Pharmacy'">
                                    <img src="../assets/images/riteaid.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Rite Aid Pharmacy'">
                                    <img src="../assets/images/fredmeyer.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Fred Meyer Pharmacy'">
                                    <img src="../assets/images/savon.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Sav-On'">
                                    <img src="../assets/images/ralphs-logo.webp"
                                      style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Ralphs Pharmacy'">
                                    <img src="../assets/images/kroger.png" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Kroger Pharmacy'">
                                    <img src="../assets/images/vons.webp" style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Vons Pharmacy'">
                                    <img src="../assets/images/logo-color-marianos.webp"
                                      style="min-width: 150px;max-width: 150px;"
                                      *ngIf="SelectPharmacy == 'Marianos Pharmacy'">
                                    <img src="../assets/images/logo-color-costco.webp"
                                      style="min-width: 150px;max-width: 150px;" *ngIf="SelectPharmacy == 'Costco'">
                                  </div>
                                  <div
                                    *ngIf="SelectPharmacy != 'Walgreens' && SelectPharmacy != 'Walmart Neighborhood Market' && SelectPharmacy != 'Walmart Pharamacy' && SelectPharmacy != 'Walmart Pharmacy' && SelectPharmacy != 'CVS Pharmacy' && SelectPharmacy != 'Osco Drug' && SelectPharmacy != 'Osco Pharmacy' && SelectPharmacy != 'Mariano’s' && SelectPharmacy != 'Safeway Pharmacy' && SelectPharmacy != 'Rite Aid Pharmacy' && SelectPharmacy != 'Fred Meyer Pharmacy' && SelectPharmacy != 'Sav-On' && SelectPharmacy != 'Ralphs Pharmacy' && SelectPharmacy != 'Kroger Pharmacy' && SelectPharmacy != 'Vons Pharmacy' && SelectPharmacy != 'Marianos Pharmacy' && SelectPharmacy != 'Costco'">
                                    <img src="../assets/images/defaultpharmacy.webp" style="max-width: 150px;">
                                  </div>
                                </td>
                                <td
                                  style="font-family: 'Roboto', sans-serif;font-weight:700;color:#00ad68;font-size:41px;text-align:right;">
                                  {{PharmacyPrice.split('.')[0]}}.<span
                                    style="font-size:25px;border-bottom:4px solid #00ad68;position:relative;bottom: 10px;color: #00ad68;">{{PharmacyPrice.slice(-2)}}</span>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-top:15px;padding-left:30px;padding-right:30px;">
                            <table style="width:100%">
                              <tr>
                                <td style="width: 150px;">
                                  <table style="width:100%;">
                                    <tr>
                                      <td style="vertical-align: top;"><img src="../assets/images/Mail_Location.svg" alt="">
                                      </td>
                                      <td
                                        style="font-family: 'Roboto', sans-serif;font-weight:400;color:#141f2b;font-size:15px;text-align:left;">
                                        {{ PAddress2 }}</td>
                                    </tr>
                                  </table>
                                </td>
                                <td style="width:40px;">&nbsp;</td>
                                <td style="width:125px;">
                                  <table style="width:100%;">
                                    <tr>
                                      <td style="vertical-align: top;"><img src="../assets/images/Mail_Mobile.svg" alt=""></td>
                                      <td
                                        style="font-family: 'Roboto', sans-serif;font-weight:400;color:#141f2b;font-size:15px;text-align:left;text-align: right;">
                                        {{ HelpLine }}</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-top: 30px;">
                            <table style="width: 360px;">
                              <tr>
                                <td
                                  style="padding-top:26px;padding-left:32px;background: url(../assets/images/card.png) no-repeat;background-size: cover;">
                                  <table>
                                    <tr>
                                      <td
                                        style="font-family:'Roboto', sans-serif;font-size:20px;color:#141f2b;font-weight:700; padding-left: 20px;padding-bottom: 10px;padding-right: 50px;word-wrap: break-word;font-size: 19px;">
                                        {{ Card_DrugName }}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="font-family:'Roboto', sans-serif;font-weight:400;font-size:16px;color:#141f2b;padding-left: 20px;">
                                        {{Card_Quantity}} {{Card_Type}}, {{Card_Dosage}}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="font-family:'Roboto', sans-serif;font-weight:400;font-size:17px;color:#B6ACA3;padding-left: 20px;padding-top: 20px;">
                                        Need Assistance? Call on the above number</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left:20px;padding-right:20px;padding-top: 20px;">
                                        <table>
                                          <tr>
                                            <td style="width: 150px;">
                                              <table>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                                    BIN</td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                                    {{RXBin}}</td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td style="width: 150px;">
                                              <table>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                                    PCN</td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                                    {{RXCPN}}</td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="width: 150px;padding-top: 30px;">
                                              <table>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                                     Group # (Access Code)</td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                                    {{GroupNumber}}</td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td style="width: 150px;padding-top: 30px;">
                                              <table style="width: 100%;">
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                                    Member ID</td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                                    {{ConditionalId}}</td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="font-family:'Roboto', sans-serif;font-weight:400;font-size:11px;color:#B6ACA3;padding-left: 20px;padding-top: 20px;padding-bottom:15px;">
                                        This card is free. <b style="font-weight: bold;">This is not insurance.</b></td>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <div class="sendbtn CheckLoginState btnshover btnsnd" (click)="ShareDiscountCard($event)">
                        Get Discount!
                        <div class="loadProcess"></div>
                      </div>
                      <div class="save CheckLoginState" (click)="SaveCard($event)" [class.saved]="IsSave == 'Remove'"
                        *ngIf="IsSave != 'Remove'">
                        Or Add to My Cards
                      </div>
                      <div class="save" (click)="SaveCard()" *ngIf="IsSave == 'Remove'" style="pointer-events: none;">
                        Added to My Cards
                      </div>
                      
                      <div class="sendbtn CheckLoginState btnshover printbtn" (click)="printcard($event)">
                        Print Card
                      </div>
                      <div class="close_search_pharmacy_popup" (click)="CloseDrugDetails()">
                        Collapse
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" *ngFor="let item of PharmacyList; let index = index"
                  (click)="showDiscountcard(item, index, item.Savings_Percent, item.RetailPrice)">
                  <div class="name_Sec">
                    <p>{{ item.PharmacyName }}</p>                    
                    <span><span *ngIf="item.Savings_Percent != '' || item.Savings_Percent != 0" class="discount_Sec"><!--<label>{{'Save ' + item.Savings_Percent + '%'}}</label><span>{{'$' + item.RetailPrice}}</span>--></span><span class="item-price">${{item.Price | number: "1.2-2" }}</span></span>
                  </div>
                  <div class="distance">
                    {{ item.Distance }} - Discount Card
                  </div>
                </div>
              </div>
              <div *ngIf="pharmacy_list.length > 5" class="loadmore btnshover" [ngClass]="!mapview ? 'fullscreen' : ''">
                Load More
              </div>
              <span class="saving-shown" [ngClass]="!mapview ? 'fullscreen' : ''">Savings shown above are off of pharmacy’s retail price.</span>
            </div>
            <!-- <----------------No Pharmacy List Start------------------>
            <div class="pharmacy_list no_pharmacy ie_fixed_add_no_pharmacy" *ngIf="!List_Pharmacy_Available">
              <img src="../../assets/images/no-result@1x.svg" alt="" />
              <span class="notxt">No Rx Found</span>
              <span>Sorry, No Results found for "{{ _DrugName }}"</span>
            </div>
            <!-- <----------------No Pharmacy List End------------------>

            <div class="maps_box ie_fixed_add_maps_box" *ngIf="mapview && getBrowser == null">
              <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
                <agm-marker [latitude]="lat" [longitude]="lng" iconUrl="../assets/images/User_Current.png"
                  [label]="Discount_Card" [title]="AppName"></agm-marker>
                <agm-marker *ngFor="let marker of MarkersList; let index = index" [latitude]="marker.lat"
                  [longitude]="marker.lng" (markerClick)="SelectMapMarker(marker.Id, index)" [iconUrl]="{
                    url:
                      showshare === index
                        ? '../assets/images/mapicon_active.svg'
                        : '../assets/images/mapicon.svg'
                  }" [label]="{
                    color: '#ffffff',
                    fontWeight: 'normal',
                    fontSize: '19px',
                    fontfamily: 'Apercu-Bold',
                    text: '$' + convertstringtoint(marker.Price)
                  }" [title]="marker.PAddress">
                </agm-marker>
              </agm-map>
            </div>

            <div *ngIf="mapview && getBrowser == ''">
              <div class="maps_box ie_fixed_add_maps_box">
                <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
                  <agm-marker [latitude]="lat" [longitude]="lng" iconUrl="../assets/images/User_Current.png"
                    [label]="Discount_Card" [title]="AppName"></agm-marker>
                  <agm-marker *ngFor="let marker of MarkersList; let index = index" [latitude]="marker.lat"
                    [longitude]="marker.lng" (markerClick)="SelectMapMarker(marker.Id, index)" [iconUrl]="{
                      url:
                        showshare === index
                          ? '../assets/images/mapicon_active.svg'
                          : '../assets/images/mapicon.svg'
                    }" [label]="{
                      color: '#ffffff',
                      fontWeight: 'normal',
                      fontSize: '19px',
                      fontfamily: 'Apercu-Bold',
                      text: '$' + convertstringtoint(marker.Price)
                    }" [title]="marker.PAddress">
                  </agm-marker>
                </agm-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- iconUrl="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"  -->
<!-- [label]="marker.Price" -->

<!-- Enter Quantity Manual Box -->
<div class="change_password quantity_box_manual" *ngIf="ManualQuantity_box">
  <div class="changepassword_main forgot_pass custom_quantity">
    <div class="close_search_pharmacy_popup" (click)="removeforgot($event)"></div>
    <h2>Enter a quantity</h2>
    <div class="input_box">
      <label for="Quantity_Manual">Quantity</label>
      <div>
        <input type="number" id="Quantity_Manual" name="Quantity_Manual" placeholder="0" [(ngModel)]="Quantity_Manual"
          (keypress)="DrugQuantityValidate($event)" />
      </div>
      <span style="color: red;" id="Quant_Error">{{ Quantity_error }}</span>
      <div class="reset_button btnshover" (click)="SubmitQuantity()">
        Submit
      </div>
    </div>
  </div>
</div>
<!-- Loader Screen -->
<div class="loaderscreen showloader" *ngIf="showloader">
  <div class="loaderpill">
    <p>Searching for the best price, one moment please.</p>
    <!-- <div>
      <img src="../assets/images/loaderpill.png" alt="" />
    </div> -->
  </div>
  <div>
    <div class="first"></div>
    <div class="second"></div>
    <div class="third"></div>
    <div class="fourth"></div>
    <div class="fifth"></div>
    <div class="sixth"></div>
    <div class="seventh"></div>
    <!-- <div class="eirth"></div> -->
    <!-- <div class="ninth"></div> -->
  </div>
</div>