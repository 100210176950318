import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @Input() id: any;
  @Input() title: any;

  constructor() {
  }

  ngOnInit() {
  }
  toggleCollapse(val) {
    let idSet = '#' + val;
    let subAccordian = $(idSet).attr('data-sub');
    if(subAccordian == 'true'){
      $('.sub').removeClass('show');
      $('.sub-accordian').removeClass('active');
    } else {
      $('.panel-collapse').removeClass('show');
    }
    let collapseSet = '#collapse' + val;
    let expandedValue = $(idSet).attr('aria-expanded');
    if(subAccordian == 'true'){
      if (expandedValue == 'true') {
        $(idSet).attr('aria-expanded', 'false');
        $(collapseSet).removeClass('show');
        $(collapseSet).parent().removeClass('active');
      } else {
        $(".sub-accordian .removeAttr").attr('aria-expanded', 'false');
        $(idSet).attr('aria-expanded', 'true');
        $(collapseSet).addClass('show');
        $(collapseSet).parent().addClass('active');
      }
    } else {
      if (expandedValue == 'true') {
        $(idSet).attr('aria-expanded', 'false');
        $(collapseSet).removeClass('show');
        $(collapseSet).parent().removeClass('active');
      } else {
        $(".removeAttr").attr('aria-expanded', 'false');
        $(idSet).attr('aria-expanded', 'true');
        $(collapseSet).addClass('show');
        $('.panel-default').removeClass('active');
        $(collapseSet).parent().addClass('active');
      }
    }
  }

}

