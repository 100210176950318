import { Component, OnInit } from '@angular/core';
import { MedicinService } from "../services/medicin.service";
import { map } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MedicinService]
})
export class LoginComponent implements OnInit {
    emptyEmail: boolean = false;
    incorrect: boolean = false;
    loader: boolean = false;
    emptyPassword: boolean = false;
    emailmodel: any = "";

    constructor(private ms: MedicinService, public router: Router,) { }
    ngOnInit() { }

    validate() {

        this.loader = true;
        var email = $("#email_").val();
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var password: any = $("#password_").val();
        this.emailmodel = this.emailmodel.replace(/\s/g, '')

        /* email validation */
        if (!this.emailmodel.match(emailReg) && email != "") {
            this.emptyEmail = false;
            this.incorrect = true;
            this.loader = false;
            $("#email_").addClass('errorIcon');
        }
        if (email == null || email == undefined || email == "") {
            this.emptyEmail = true;
            this.loader = false;
            $("#email_").addClass('errorIcon');
        }
        /* password validation */
        if (password == null || password == undefined || password == "") {
            this.emptyPassword = true;
            this.loader = false;
            $("#password_").addClass('errorIcon');
        } else if (password.length < 4) {
            this.incorrect = true;
            this.loader = false;
            $("#password_").addClass('errorIcon');
        }
        if (
            !(email == null || email == undefined || email == "") &&
            !(emailReg.test(this.emailmodel) == false) &&
            !(password == null || password == undefined || password == "") &&
            !(password.length < 4)
        ) {
            this.ms.brokerLogin(this.emailmodel, password)
                // .pipe(map((res) => res.json()))
                .subscribe(data => {
                    try {
                        if (data[0].success == true) {
                            var a = new Date();
                            localStorage.a = a;
                            window.localStorage.setItem('brokergroup', password);
                            window.localStorage.setItem('brokerdetail', JSON.stringify(data[0]));
                            window.localStorage.setItem('portallink', data[0].brokerDetails[0].portalLink);
                            window.localStorage.setItem('liid', data[0].brokerDetails[0].sandGridListId);
                            window.localStorage.setItem('signstatus', data[0].brokerDetails[0].signstatus);
                            window.localStorage.setItem('brokeremail', this.emailmodel);
                            if (data[0].brokerDetails[0].klaviyo_ListId != '') {
                                window.localStorage.setItem('klaviyoid', data[0].brokerDetails[0].klaviyo_ListId)
                            }
                            else {
                                window.localStorage.setItem('klaviyoid', '')
                            }
                            this.loader = false;
                            this.router.navigate(['/enrollmentcenter'])
                        } else {
                            this.incorrect = true;
                            this.loader = false;
                        }
                    } catch (error) {
                        this.incorrect = true;
                        this.loader = false;
                    }
                })
        }
    }
    removeerror(event) {
        let currentId = $(event.target).attr('id');
        if (currentId == 'email_') {
            this.emptyEmail = false;
            this.incorrect = false;
        } else {
            this.emptyPassword = false;
            this.incorrect = false;
        }
    }


}
