import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
  providers: []
})
export class PricingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
