import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentversion = window.localStorage.getItem('version');
    constructor(private http: HttpClient) { }
    /**
    * Checks in every set frequency the version of frontend application
    * @param url
    * @param {number} frequency - in milliseconds, defaults to 30 minutes
    */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }
    /**
    * Will do the call and check if the hash has changed or not
    * @param url
    */
    private checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    const version = response.version;
                    window.localStorage.setItem('version', version)
                    if (this.currentversion) {
                        if (this.currentversion == response.version) {
                            return false;
                            // window.location.reload();
                            // window.location.href = window.location.href;
                        }
                        else {
                            window.location.reload();
                            window.location.href = window.location.href;
                        }
                    }
                    else {
                        window.location.reload();
                        window.location.href = window.location.href;
                    }
                    //alert('First alert')
                    // const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    // If new version, do something
                    // if (hashChanged) {
                    //   //alert('second alert')
                    // console.log('changed')
                    // window.location.reload();
                    // window.location.href = window.location.href;

                    // // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                    // // for an example: location.reload();
                    // }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    // this.currentHash = hash;
                },
                (err) => {
                    console.log(err, 'Could not get version');
                }
            );
    }
    /**
    * Checks if hash has changed.
    * This file has the JS hash, if it is a different one than in the version.json
    * we are dealing with version change
    * @param currentHash
    * @param newHash
    * @returns {boolean}
    */
    // private hasHashChanged(currentHash, newHash) {
    //     debugger;
    // if (!currentHash || currentHash === '4d5ca0ad30d825cd31af') {
    //   debugger;
    // return false;
    // }
    // return currentHash !== newHash;
    // }
}
