<div class="pharmacyPage cardPage">
  <!-- <div class="pharmacy_header">
    <div class="container header_container">
      <h2>My Cards</h2>
      <div class="right_btn_sec">       
        <div class="sortby_ddl" [hidden]="!sortbyddl">
          <ul *ngIf="IsDistancesort">
            <li (click)="SortcardsList('Distance')" class="active">Distance</li>
            <li (click)="SortcardsList('Price')">Price</li>
          </ul>
          <ul *ngIf="IsPricesort">
              <li (click)="SortcardsList('Distance')">Distance</li>
              <li (click)="SortcardsList('Price')" class="active">Price</li>
            </ul>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container" id="pharmacy_container">
    <h2>My Cards</h2>
    <div class="sorting_box">
      <p>Sort By</p>
      <div class="sort_link">
        <div [ngClass]="{ active: selectedItem == 'by_recent' }" class="active"
          (click)="SortingPharmacyList('by_recent')">
          Recent
        </div>
        <div [ngClass]="{ active: selectedItem == 'by_a_z' }" (click)="SortingPharmacyList('by_a_z')">
          A—Z
        </div>
      </div>
    </div>
    <div class="pharmacy_box">
      <div class="pharmacy_list">
        <div class="card" *ngFor="let item of CardsList; let index = index">
          <div class="card_name_price">
            <div class="drugname">{{ item.DrugName }}</div>
            <div class="drugprice">{{ item.Price }}</div>
          </div>
          <div class="cardbox_p">
            <div class="card_box">
              <div class="card_box_header">
                Pharmacy Discount Card
                <!-- <img src="../assets/images/card_logo.svg" alt="" /> -->
              </div>
              <div class="card_box_body">
                <div class="logo_phone">
                  <div class="img_logo">
                    <img loading="lazy" src="../assets/images/header_logo.png" alt="" />
                  </div>
                  <div class="member_support">
                    <span>Member Support</span>
                    <span>{{ item.PharmacyPhone }}</span>
                  </div>
                </div>
                <div class="bin_pcn_member_id">
                  <div>
                    <span>BIN</span>
                    <div>{{ item.BIN || item.Bin }}</div>
                  </div>
                  <div>
                    <span>PCN</span>
                    <div>{{ item.PCN || item.Pcn }}</div>
                  </div>
                  <div>
                    <span>Group # (Access Code)</span>
                    <div>{{ item.UserGroup }}</div>
                  </div>

                  <div>
                    <span>MEMBER ID</span>
                    <div *ngIf="item.UserCharId">{{ item.UserCharId }}</div>
                    <div *ngIf="!item.UserCharId">N/A</div>
                  </div>
                </div>
                <p><b>This is not insurance.</b> This card is free.</p>
              </div>
            </div>
            <div class="card_box">

              <div class="card_box_body">
                <table>

                  <tr>
                    <td>
                      <p style="padding:0;margin:0;border-right:1px solid #eee;padding-right: 8px">
                        <strong>Member Instructions</strong><br>
                        1. Present card to your pharmacist<br>
                        2. Ask for discount on each prescription
                        and save!<br>
                        3. Use over and over! It does not expire
                      </p>
                      <p style="padding:0;margin:0;border-right:1px solid #eee;padding-right: 8px">
                        Visit <a href="https://valpakrx.com/" style="color:#27524B;text-decoration:none;">valpakrx.com</a>,
                        download the ValpakRx™ app,
                        or call <a href="tel:800-984-4031"
                          style="color:#27524B;text-decoration:none;font-weight:bold">800-984-4031</a> for more
                        information, to report any concerns and to
                        find participating pharmacies near you! The
                        ValpakRx™ Program, prices, list of covered
                        drugs, and participating pharmacies are
                        subject to change.
                      </p>


                    </td>
                    <td style="padding-left: 8px;">
                      <strong>Pharmacist Instructions</strong><br>
                      This card is pre-activated and
                      gives member discounts
                      through the BIN, PCN, Group on
                      the front. If you need assistance
                      call <a href="tel:8778007820" style="color:#27524B;text-decoration:none;">(877)
                        800-7820</a>.<br><br>
                      <strong>Accepted at approximately 35,000
                        nationwide</strong>
                      <p style="padding:0;margin:0;margin-top:5px">
                        <img src="../../assets/images/pharmacylogos.webp" alt="" />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      By using this card, you have agreed to the terms and conditions found at
                      <a href="https://valpakrx.com/terms-and-conditions"
                        style="color:#27524B;text-decoration:none;">valpakrx.com/terms</a>. The ValpakRx™ Program is
                      administered by Glic Health LLC, One
                      Marina Park Drive, Suite 1410, Boston MA
                      <br /><br />
                      The CVS, KROGER, WALMART and WALGREENS logos are the trademarks and/
                      or registered trademarks of CVS, KROGER, WALMART and WALGREENS (or one
                      of their respective affiliates), respectively.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card_detail">
            <div class="type_form">
              <div>
                <span>Type:</span>
                <span>{{ item.DrugName }}</span>
              </div>
              <div>
                <span>Form:</span>
                <span>{{ item.Form }}</span>
              </div>
              <div>
                <span>Dosage:</span>
                <span>{{ item.Dose }}</span>
              </div>
              <div>
                <span>Quantity:</span>
                <span>{{ item.Quantity }}</span>
              </div>
            </div>
            <div class="address_number_box">
              <p class="pharmacy_name">{{ item.PharmacyName }}</p>
              <span class="distance" *ngIf="item.Distance">{{
                item.Distance
                }}</span>
              <p class="address" (click)="RedirectGoogleDirection(item.Address1)">
                <span>{{ item.Address1 }} {{ item.Address2 }}</span>
              </p>
              <p class="phonenumber" *ngIf="item.PharmacyType == 'CRX'">
                <a href="tel:8886800827">888 680 0827 </a>
              </p>
              <p class="phonenumber" *ngIf="item.PharmacyType == 'NVT'">
                <a href="tel:8668099382"> 866 809 9382</a>
              </p>
              <p class="phonenumber" *ngIf="item.PharmacyType == 'RXS'">
                <a href="tel:8778007820"> 877 800 7820</a>
              </p>
              <p class="phonenumber" *ngIf="
                  !item.PharmacyType == 'CRX' ||
                  !item.PharmacyType == 'NVT' ||
                  !item.PharmacyType == 'RXS'
                ">
                <a href="tel:8446169490"> 844 616 9490</a>
              </p>
            </div>
            <div class="share_card">
              <div *ngIf="showshare === index">
                <p>Send discount card via email</p>
                <div class="email_field" style="position: relative;">
                  <input type="email" id="email" name="ShareEmail" placeholder=" " [(ngModel)]="UserInputEmail"
                    (click)="ClearMessage($event)" (blur)="ClearMessage($event)" (keypress)="clearClassMsg()"
                    placeholder="Enter Your Email" autocomplete="off" />
                  <!-- <label for="email">Enter your e-mail</label> -->

                  <!-- <div class="loadProcess"></div> -->
                </div>
                <table class="sharecardtable"
                  style="width:400px;background:url(../assets/images/bkgd.png) no-repeat;background-size:cover;">
                  <tr>
                    <td style="padding-left:30px;padding-right:30px;padding-top:30px;">
                      <table style="width:100%;">
                        <tr>
                          <td style="font-family: 'Roboto';font-weight:700;color:#141f2b;font: size 18px;">
                            <div
                              *ngIf="item.PharmacyName == 'Walgreens' || item.PharmacyName == 'Walmart Neighborhood Market' || item.PharmacyName == 'Walmart Pharamacy' || item.PharmacyName == 'Walmart Pharmacy' || item.PharmacyName == 'CVS Pharmacy' || item.PharmacyName == 'Osco Drug' || item.PharmacyName == 'Osco Pharmacy' || item.PharmacyName == 'Mariano’s'|| item.PharmacyName == 'Safeway Pharmacy' || item.PharmacyName == 'Rite Aid Pharmacy' || item.PharmacyName == 'Fred Meyer Pharmacy' || item.PharmacyName == 'Sav-On' || item.PharmacyName == 'Ralphs Pharmacy' || item.PharmacyName == 'Kroger Pharmacy' || item.PharmacyName == 'Vons Pharmacy' || item.PharmacyName == 'Marianos Pharmacy' || item.PharmacyName == 'Costco'">
                              <img src="../assets/images/wallgreen.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Walgreens'">
                              <img src="../assets/images/walmart.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Walmart Neighborhood Market'">
                              <img src="../assets/images/walmart.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Walmart Pharamacy' || item.PharmacyName == 'Walmart Pharmacy'">
                              <img src="../assets/images/cvsp.png" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'CVS Pharmacy'">
                              <img src="../assets/images/osco.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Osco Drug' || item.PharmacyName == 'Osco Pharmacy'">
                              <img src="../assets/images/marianos.png" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Mariano’s'">
                              <img src="../assets/images/safeway.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Safeway Pharmacy'">
                              <img src="../assets/images/riteaid.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Rite Aid Pharmacy'">
                              <img src="../assets/images/fredmeyer.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Fred Meyer Pharmacy'">
                              <img src="../assets/images/savon.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Sav-On'">
                              <img src="../assets/images/ralphs-logo.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Ralphs Pharmacy'">
                              <img src="../assets/images/kroger.png" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Kroger Pharmacy'">
                              <img src="../assets/images/vons.webp" style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Vons Pharmacy'">
                              <img src="../assets/images/logo-color-marianos.webp"
                                style="min-width: 150px;max-width: 150px;"
                                *ngIf="item.PharmacyName == 'Marianos Pharmacy'">
                              <img src="../assets/images/logo-color-costco.webp"
                                style="min-width: 150px;max-width: 150px;" *ngIf="item.PharmacyName == 'Costco'">
                            </div>
                            <div
                              *ngIf="item.PharmacyName != 'Walgreens' && item.PharmacyName != 'Walmart Neighborhood Market' && item.PharmacyName != 'Walmart Pharamacy' && item.PharmacyName != 'Walmart Pharmacy' && item.PharmacyName != 'CVS Pharmacy' && item.PharmacyName != 'Osco Drug' && item.PharmacyName != 'Osco Pharmacy' && item.PharmacyName != 'Mariano’s' && item.PharmacyName != 'Safeway Pharmacy' && item.PharmacyName != 'Rite Aid Pharmacy' && item.PharmacyName != 'Fred Meyer Pharmacy' && item.PharmacyName != 'Sav-On' && item.PharmacyName != 'Ralphs Pharmacy' && item.PharmacyName != 'Kroger Pharmacy' && item.PharmacyName != 'Vons Pharmacy' && item.PharmacyName != 'Marianos Pharmacy' && item.PharmacyName != 'Costco'">
                              <img src="../assets/images/defaultpharmacy.webp" style="max-width: 150px;">
                            </div>
                          </td>
                          <td
                            style="font-family: 'Roboto', sans-serif;font-weight:700;color:#00ad68;font-size:41px;text-align:right;">
                            {{item.Price.split('.')[0]}}.<span
                              style="font-size:25px;border-bottom:4px solid #00ad68;position:relative;bottom: 10px;color: #00ad68;">{{item.Price.slice(-2)}}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-top:15px;padding-left:30px;padding-right:30px;">
                      <table style="width:100%">
                        <tr>
                          <td style="width: 150px;">
                            <table style="width:100%;">
                              <tr>
                                <td style="vertical-align: top;"><img src="../assets/images/Mail_Location.svg" alt=""></td>
                                <td
                                  style="font-family: 'Roboto', sans-serif;font-weight:400;color:#141f2b;font-size:15px;text-align:left;">
                                  {{ item.Address1 }} {{ item.Address2 }}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="width:40px;">&nbsp;</td>
                          <td style="width:125px;">
                            <table style="width:100%;">
                              <tr>
                                <td style="vertical-align: top;"><img src="../assets/images/Mail_Mobile.svg" alt=""></td>
                                <td
                                  style="font-family: 'Roboto', sans-serif;font-weight:400;color:#141f2b;font-size:15px;text-align:left;text-align: right;">
                                  877-800-7820</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-top: 30px;">
                      <table style="width: 360px;">
                        <tr>
                          <td
                            style="padding-top:26px;padding-left:32px;background: url(../assets/images/card.png) no-repeat;background-size: cover;">
                            <table>
                              <tr>
                                <td
                                  style="font-family:'Roboto', sans-serif;font-size:20px;color:#141f2b;font-weight:700; padding-left: 20px;padding-bottom: 10px;padding-right: 50px;word-wrap: break-word;font-size: 19px;">
                                  {{ item.DrugName }}</td>
                              </tr>
                              <tr>
                                <td
                                  style="font-family:'Roboto', sans-serif;font-weight:400;font-size:16px;color:#141f2b;padding-left: 20px;">
                                  {{ item.Quantity }} {{ item.Form }}, {{ item.Dose }}</td>
                              </tr>
                              <tr>
                                <td
                                  style="font-family:'Roboto', sans-serif;font-weight:400;font-size:17px;color:#B6ACA3;padding-left: 20px;padding-top: 20px;">
                                  Need Assistance? Call on the above number</td>
                              </tr>
                              <tr>
                                <td style="padding-left:20px;padding-right:20px;padding-top: 20px;">
                                  <table>
                                    <tr>
                                      <td style="width: 150px;">
                                        <table>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                              BIN</td>
                                          </tr>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                              {{ item.BIN || item.Bin }}</td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td style="width: 150px;">
                                        <table>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                              PCN</td>
                                          </tr>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                              {{item.PCN || item.Pcn }}</td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="width: 150px;padding-top: 30px;">
                                        <table>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                              Group # (Access Code)</td>
                                          </tr>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                              {{ item.UserGroup }}</td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td style="width: 150px;padding-top: 30px;">
                                        <table style="width: 100%;">
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:10px;color:#537770">
                                              Member ID</td>
                                          </tr>
                                          <tr>
                                            <td
                                              style="font-family:'Roboto', sans-serif;font-weight:400;font-size:20px;color:#141f2b;border-left: 1px solid #A7C7B4;padding-left: 10px;">
                                              {{ item.UserCharId }}</td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="font-family:'Roboto', sans-serif;font-weight:400;font-size:11px;color:#B6ACA3;padding-left: 20px;padding-top: 20px;padding-bottom:15px;">
                                  This card is free. <b style="font-weight: bold;">This is not insurance.</b></td>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <span style="color: red;">{{ SendDiscountMessage }}</span>
                <div class="sendbtn btnshover btnsnd" (click)="
                    ShareDiscountCard(
                      item.DrugName,
                      item.Quantity,
                      item.Form,
                      item.Dose,
                      item.Price,
                      item.PharmacyName,
                      item.Address1,
                      item.Address2,
                      item.PharmacyPhone,
                      $event
                    )
                  ">
                  Get Discount!
                  <div class="loadProcess"></div>
                </div>
              </div>
              <div class="sharecard btnshover" (click)="showsharebox(index)">
                Share Discount Card
              </div>
            </div>
            <div class="share_card">
              <div class="sharecard btnshover" (click)="printcard($event)">
                Print Card
              </div>
            </div>
          </div>
          <div class="remove_card">
            <span (click)="
                RemoveCard(
                  item.CardId,
                  item.Price,
                  item.DrugName,
                  item.Dose,
                  item.Form,
                  item.PharmacyId
                )
              ">Remove Card</span>
          </div>
          <!-- <div class="name_Sec">
            <p>{{ item.DrugName }}</p>
            <span>{{ item.Price }}</span>
          </div>
          <div class="distance">
            {{ item.Quantity }} {{ item.Form }} {{ item.Dose }}
          </div> -->
          <!-- <div class="distance">{{ item.PharmacyName }}</div> -->
        </div>

        <!-- ---------------------Sample Card-------------------------- -->
        <!-- <div class="card" (click)="SampleDiscountcard()"> -->
        <!-- <div class="card">
          <div class="card_name_price">
            <div class="drugname">This is your generic card</div>
            <div class="drugprice">--</div>
          </div>
          <div class="card_box">
            <div class="card_box_header">
              Pharmacy Discount Card
            </div>
            <div class="card_box_body">
              <div class="logo_phone">
                <div class="img_logo">
                  <img src="../assets/images/header_logo.svg" alt="" />
                </div>
                <div class="member_support">
                  <span>Member Support</span>
                  <span>--</span>
                </div>
              </div>
              <div class="bin_pcn_member_id">
                <div>
                  <span>BIN</span>
                  <div>023518</div>
                </div>
                <div>
                  <span>Group</span>
                  <div>HDRX9543</div>
                </div>
                <div>
                  <span>PCN</span>
                  <div>HD</div>
                </div>
                <div>
                  <span>MEMBER ID</span>
                  <div>N/A</div>
                </div>
              </div>
              <p>This is not insurance. This card is free.</p>
            </div>
          </div>
          <div class="card_detail">
            <div class="type_form">
              <div>
                <span>Type:</span>
                <span>--</span>
              </div>
              <div>
                <span>Form:</span>
                <span>--</span>
              </div>
              <div>
                <span>Dosage:</span>
                <span>--</span>
              </div>
              <div>
                <span>Quantity:</span>
                <span>--</span>
              </div>
            </div>
            <div class="address_number_box">
              <p class="pharmacy_name">
                For use at any major retail pharmacy and save!
              </p>
              <span class="distance">--</span>
              <p class="address">
                <span>--</span>
              </p>
              <p class="phonenumber">
                --
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- Discount Card -->
  <div class="discount_card" *ngIf="showcard" id="print-section">
    <div class="close_search_pharmacy_popup" (click)="CloseDrugDetails()"></div>
    <div class="discount_card_parent">
      <div class="header">
        <img src="../../assets/images/logo.png" alt="" />
        <div class="button_sec">
          <!-- <div class="sendsms">Send SMS</div> -->
          <div class="saved" (click)="RemoveCard()">Remove</div>
          <div class="print" (click)="PrintMe()">Print</div>
        </div>
      </div>
      <!-- <div class="logoarea" *ngIf="Card_Pharmacy_Logo">
        <div class="singlecare">
          <img src="../../assets/images/singlecare.svg" alt="" />
        </div>
        <div class="walmart_img" *ngIf="RXS_Walmart">
          <span>Available at</span>
          <img src="http://rxhacker.23byt.es/MailSnap/wallmart.svg" alt="" />
        </div>
        <div class="cvs_img" *ngIf="RXS_CVS">
          <span>For exclusive use at</span>
          <img src="http://rxhacker.23byt.es/MailSnap/cvs.svg" alt="" />
        </div>
      </div> -->
      <div class="pharmacybox">
        <div class="name_quantity">
          <p>{{ Card_DrugName }}</p>
          <span>{{ Card_Quantity }} {{ Card_DrugForm }} | {{ Card_Dosage }}</span>
        </div>
        <div class="price">{{ PharmacyPrice }}</div>
      </div>
      <div class="pharmacyDetail">
        <div class="name">
          {{ SelectPharmacy }}
        </div>
        <div class="address">{{ PAddress2 }}</div>
        <div class="number">{{ PharmacyPhone }}</div>
        <div class="method_salt">
          <div class="method">
            <div>BIN</div>
            <div>{{ RXBin }}</div>
          </div>
          <div class="method">
            <div>PCN</div>
            <div>{{ RXCPN }}</div>
          </div>
          <div class="method">
            <div>Member ID</div>
            <div>{{ MemberId }}</div>
          </div>
          <div class="method">
            <div>Group # (Access Code)</div>
            <div>{{ GroupNumber | uppercase }}</div>
          </div>
        </div>
        <div class="email_field">
          <input type="email" id="email" name="ShareEmail" placeholder=" " autocomplete="off"
            [(ngModel)]="UserInputEmail" (click)="ClearMessage($event)" (keypress)="clearClassMsg()"
            (blur)="clearClassMsg()" />
          <label for="email">Enter your e-mail</label>
          <div class="sendbtn" (click)="ShareDiscountCard()">Send</div>
          <div class="loadProcess"></div>
        </div>
        <span>{{ SendDiscountMessage }}</span>
        <div class="helpline_btn">
          <div class="helpline" (click)="PharmacyHelpLine()">
            {{ PharmacistHelpline }}
          </div>
          <div class="direction" (click)="RedirectGoogleDirection()">
            Get Directions
            <img src="../../assets/images/direction.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="discount_card" *ngIf="SampleDiscountCard">
    <div class="close_search_pharmacy_popup" (click)="CloseDrugDetails()"></div>
    <div class="discount_card_parent">
      <div class="header">
        <img src="../../assets/images/logo.png" alt="" />
        <div class="button_sec">
          <!-- <div class="sendsms">Send SMS</div> -->
          <!-- <div class="saved" (click)="RemoveCard()">Remove</div>
            <div class="print" (click)="PrintMe()">Print</div> -->
        </div>
      </div>

      <div class="pharmacybox">
        <div class="name_quantity">
          <p>{{ TopText }}</p>
          <span>- - | -</span>
        </div>
        <div class="price">$</div>
      </div>
      <div class="pharmacyDetail">
        <div class="name">
          {{ HeadingText }}
        </div>
        <div class="address">{{ PAddress2 }}</div>
        <div class="number">{{ PharmacyPhone }}</div>
        <div class="method_salt">
          <div class="method">
            <div>BIN</div>
            <div>{{ RXBin }}</div>
          </div>
          <div class="method">
            <div>PCN</div>
            <div>{{ RXCPN }}</div>
          </div>
          <div class="method">
            <div>Member ID</div>
            <div>{{ MemberId }}</div>
          </div>
          <div class="method">
            <div>Group # (Access Code)</div>
            <div>{{ GroupNumber | uppercase }}</div>
          </div>
        </div>
        <!-- <div class="email_field">
            <input type="email" id="email" name="ShareEmail" placeholder=" " [(ngModel)]="UserInputEmail" (click)="ClearMessage($event)"/>
            <label for="email">Enter your e-mail</label>
            <div class="sendbtn"(click)="ShareDiscountCard()">Send</div>
          </div> -->
        <!-- <span>{{SendDiscountMessage}}</span> -->
        <div class="helpline_btn">
          <!-- <div class="helpline" (click)="PharmacyHelpLine()">{{PharmacistHelpline}}</div> -->
          <!-- <div class="direction" (click)=RedirectGoogleDirection()>
              Get Directions
              <img src="../../assets/images/direction.svg" alt="" />
            </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
