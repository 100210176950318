import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { map } from "rxjs/operators";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"],
  providers: [ApiService]
})
export class HelpComponent implements OnInit {
  emptyInput: boolean = false;
  emptyEmail: boolean = false;
  invalidEmail: boolean = false;
  emptyMessage: boolean = false;
  emptyCheckbox: boolean = false;
  emailmodel:any='';
  constructor(private apiService: ApiService) {}

  ngOnInit() {
  }
  focusValidation(inputField) {
    if (inputField == "name") {
      this.emptyInput = false;
      $("#name_").removeAttr("style");
    }
    if (inputField == "email") {
      this.emptyEmail = false;
      this.invalidEmail = false;
      $("#email_").removeAttr("style");
    }
    if (inputField == "message") {
      this.emptyMessage = false;
      $("#message").removeAttr("style")
    }
  }

  checkboxFocusValidation() {
    if ($('input[type="checkbox"]').prop("checked") == true) {
      this.emptyCheckbox = false;
    } else {
      this.emptyCheckbox = true;
    }
  }

  /* form validation */
  validate() {
    var name = $("#name_").val();
    var email = $("#email_").val();
    var message = $("#message").val();
    var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    /* name */
    if (name == null || name == undefined || name == '') {
      this.emptyInput = true;
      $("#name_").css("border-color", "red");
    }

    /* email */
    if (!this.emailmodel.match(emailReg) && email != "") {
      this.emptyEmail = false;
      this.invalidEmail = true;
      $("#email_").css("border-color", "red");
      $("#email").focus();
    }
    if (email == null || email == undefined || email == '') {
      this.emptyEmail = true;
      $("#email_").css("border-color", "red");
    }

    /* message */
    if (message == null || message == undefined || message == '') {
      this.emptyMessage = true;
      $("#message").css("border-color", "red");
    }

    if (!$('input[type="checkbox"]').prop("checked") == true) {
      this.emptyCheckbox = true;
    }

    if (
      !(name == null || name == undefined || name == '') &&
      !(email == null || email == undefined || email == '') &&
      !(emailReg.test(this.emailmodel) == false) &&
      !(message == null || message == undefined || message == '') &&
      $('input[type="checkbox"]').prop("checked") == true
    ) {
      this.apiService
        .sendHelp(email, name, message)
        // .pipe(map(res => res.json()))
        .subscribe(data => {
          if ((data.status = 202)) {
            $("#name_").val("");
            $("#email_").val("");
            $("#message").val("");
            $("#contactsucess").show();
            setTimeout(() => {
              $("#contactsucess").fadeOut("slow");
            }, 2000);
          }
        });
    } else {
    }
  }
}
