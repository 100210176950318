import { Component, OnInit } from '@angular/core';
import { SaveService } from "../services/save.service";
@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss'],
  providers:[SaveService]
})
export class TermsofuseComponent implements OnInit {
  public brandName:any;
  public brandName2:any;
  constructor(private SaveService: SaveService) { }

  ngOnInit() {
    this.brandName = this.SaveService.getBrandName();
    this.brandName2 = this.SaveService.getSecondBrandName();
  }

}
