<!-- // header \\ -->
<header class="header-section 1">
  <div class="comman-width">
    <button class="slideToggle" (click)="slideToggle()">
      <!-- <img id="slide-icon" src="assets/images/menu.png" /> -->
    </button>

    <a routerLink="/" routerLinkActive="" aria-label="hdrx logo" class="logo"><img src="{{ logo }}" alt="hdrx-logo" /></a>
    <nav class="navbar pull-right">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a routerLink="aboutUs" (click)="slideToggle()" routerLinkActive="active">About Us<span>&nbsp;</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="pricing" (click)="slideToggle()" routerLinkActive="active">Pricing<span>&nbsp;</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="contact" (click)="slideToggle()" routerLinkActive="active">Contact Us<span>&nbsp;</span></a>
        </li>
        <li class="nav-item download">
          <a routerLink="download-mobile-app" (click)="slideToggle()" routerLinkActive="active">Download Mobile
            App<span>&nbsp;</span></a>
        </li>
      </ul>
      <div class="login">
        <a href="#" class="signUp">Sign Up</a>
        <a href="#" class="last signIn">Sign In</a>
      </div>
    </nav>
    <div class="clearfix"></div>
  </div>
</header>
<!-- \\ header // -->
