import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MedicinService } from "../services/index";
import { PrerequisiteService } from "../services/prerequisite.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../services/google-analytics-service.service";
import { ApiService } from "../services/api.service";
import domtoimage from 'dom-to-image-more';
import jspdf from 'jspdf';

@Component({
  selector: "app-mycards",
  templateUrl: "./mycards.component.html",
  styleUrls: ["./mycards.component.scss"],
  providers: [MedicinService, PrerequisiteService, GoogleAnalyticsService, ApiService],
})
export class MycardsComponent implements OnInit {
  sortbyddl: any = false;
  type_list = [];
  mapview: any = false;
  public SampleDiscountCard: boolean = false;
  showcard: any = false;
  public CardsList: any;
  public show_apple_wallet: boolean = false;

  public IsPricesort = true;
  public IsDistancesort = false;
  public distancesort: any;
  //----Sample CardData variable
  public TopText: string = "";
  public HeadingText: string = "";
  //----Card variables
  public SelectedCardPrice: string = "";
  public SelectedCardDrug: string = "";
  public SelectedCardDose: string = "";
  public SelectedCardForm: string = "";
  public SelectPharmacy: string;
  public PharmacyPrice: string;
  public PharmacyContact: string;
  public MemberId: string;
  public RXBin: string;
  public RXCPN: string;
  public HelpLine: string;
  public DialNumber: string;
  public GroupNumber: string;
  public NoDefaultCard: Boolean = false;
  public PAddress1: string = "";
  public PAddress2: string = "";
  public gpid: any;
  public multisource: any;
  public SavedCardList: any = [];
  public unregisterBackButtonAction: any;
  public Quant_Type: string = "";
  public PharmacyPhone: string = "";
  public Card_Quantity: string = "";
  public Card_DrugName: string = "";
  public Card_Dosage: string = "";
  public Card_DrugForm: string = "";
  public PharmacyLat: string = "";
  public PharmacyLng: string = "";
  public SelectedCardId: string = "";
  public SelectedPharmacyId: string = "";
  public PharmacistHelpline: string = "Pharmacist Helpline";
  public PharmacyName: string = "";
  public PAddress3: string = "";
  public UserInputEmail: string = "";
  public PharmacistHL: string = "";
  public SendDiscountMessage = "";
  public DrugResponseType: string = "";
  public DrugMsType: string = "";
  public Card_Pharmacy_Logo: string = "";
  public RXS_Walmart: string = "";
  public RXS_CVS: string = "";
  public Bin: string = "";
  public Pcn: string = "";
  public Plogo: string = "";
  public Card_Type: string = "";
  public Card_Pharmacy_Distance: string = "";
  public Card_CardId: string = "";
  selectedItem: any = "by_recent";
  public showshare: any;
  constructor(
    public http: HttpClient,
    private ms: MedicinService,
    public rs: PrerequisiteService,
    public router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private ApiService: ApiService
  ) {
    if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
      this.router.navigate(["/"]);
    } else {
      this.GetUserCardList();
    }
  }
  showsharebox(index) {
    $(".email_field + span").hide();
    this.showshare = index;
  }
  ngOnInit() {
    // this.getOS();
    $(document).on("click", ".sortby_ddl ul li", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
    });
    try {
      $(".navbar ul").css({ "padding-top": "0px" });
    } catch { }
  }

  Showmapview() {
    this.mapview = !this.mapview;
    var header_container = $(".header_container").css("margin-left");
    var header_container_margin = parseInt(header_container);
    if (this.mapview) {
      $("#pharmacy_container").css({
        "max-width": "100%",
        "padding-left": header_container_margin + 15,
        "padding-right": "0",
      });
      $(".pharmacy_list").css("max-width", "424px");
    } else {
      $("#pharmacy_container").removeAttr("style");
      $(".pharmacy_list").removeAttr("style");
    }
  }
  showDiscountcard(item: any) {
    this.Card_Pharmacy_Logo = "";
    this.RXS_Walmart = "";
    this.Plogo = "";
    this.SelectedCardPrice = item.Price;
    this.SelectedCardDrug = item.DrugName;
    this.SelectedCardDose = item.Dose;
    this.SelectedCardForm = item.Form;
    this.UserInputEmail = null;
    window.localStorage.setItem("SelectedPhamacyId", item.PharmacyId);
    this.UserInputEmail = null;
    this.SendDiscountMessage = "";
    this.PharmacistHelpline = "Pharmacist Helpline";
    this.GroupNumber = window.localStorage.getItem("UserGroup").toUpperCase();
    this.PAddress2 = item.Address1 + ", " + item.Address2;
    this.PAddress1 = item.Address1;
    this.PAddress3 = item.Address2;
    this.DrugResponseType = item.PharmacyType;
    this.DrugMsType = item.MultiSouce;
    if (item.PharmacyType == "CRX") {
      this.RXBin = "015284";
      this.RXCPN = "CRX";
      this.HelpLine = "888-680-0827";
      this.DialNumber = "8886800827";
    } else if (item.PharmacyType == "NVT") {
      this.RXBin = "610602";
      this.RXCPN = "HDX";
      this.HelpLine = "866-809-9382";
      this.DialNumber = "8668099382";
    } else if (item.PharmacyType == "RXS") {
      ;
      this.RXBin = item.Bin;
      this.RXCPN = item.Pcn;
      this.HelpLine = "877-800-7820";
      this.DialNumber = "8778007820";
    } else {
      this.RXBin = "600428";
      this.RXCPN = "HRX";
      this.HelpLine = "844-616-9490";
      this.DialNumber = "8446169490";
    }
    this.PharmacyName = item.PharmacyName.replace("at ", "").replace("At ", "");
    if (item.PharmacyName == "use at any major retail pharmacy and save!") {
      this.SelectPharmacy = "For " + item.PharmacyName;
    } else {
      this.SelectPharmacy =
        "at " + item.PharmacyName.replace("at ", "").replace("At ", "");
    }
    if (
      item.PharmacyType != "RXS" &&
      item.PharmacyName.toLowerCase().indexOf("walmart pharmacy") > -1
    ) {
      this.RXBin = item.Bin;
      this.RXCPN = item.Pcn;
    }
    // if (item.PharmacyType == "RXS") {
    //   if (item.PharmacyName.toLowerCase().indexOf("walmart ") > -1) {
    //     this.RXBin = "610396";
    //     this.RXCPN = "WM1";
    //     this.Card_Pharmacy_Logo = item.Plogo;
    //     this.RXS_Walmart = "walmart";
    //     this.Plogo = item.Plogo;
    //   } else if (item.PharmacyName.toLowerCase().indexOf("cvs pharmacy") > -1) {
    //     this.RXBin = "610398";
    //     this.RXCPN = "CV1";
    //     this.Card_Pharmacy_Logo = item.Plogo;
    //     this.RXS_CVS = "cvs";
    //     this.Plogo = item.Plogo;
    //   }
    // }
    this.PharmacyPrice = item.Price;
    this.PharmacyPhone = item.PharmacyPhone;
    this.Card_Dosage = item.Dose;
    this.Card_DrugName = item.DrugName;
    this.Card_Quantity = item.Quantity;
    this.PharmacyLat = item.Lat;
    this.PharmacyLng = item.Long;
    this.Card_DrugForm = item.Form;
    this.SelectedCardId = item.CardId;
    this.PharmacistHL = this.HelpLine;
    this.Card_Pharmacy_Distance = item.Distance;
    this.Card_CardId = item.CardId;
    this.MemberId = window.localStorage.getItem("UserCharId");
    //  / this.PharmacistHelpline =this.HelpLine;
    if (
      this.MemberId == null ||
      this.MemberId == undefined ||
      this.MemberId == "" ||
      this.MemberId == "null"
    ) {
      this.MemberId = "N/A";
    }
    this.showcard = true;
  }
  SortingPharmacyList(val) {
    if (val == "by_recent") {
      this.selectedItem = "by_recent";
      // if(JSON.parse(window.localStorage.getItem('CardListLocally')) == null) {
      //    this.CardsList = JSON.parse(window.localStorage.getItem('initialcard'));
      // }
      this.GetUserCardList();
    } else {
      this.selectedItem = "by_a_z";
      let shortcardlist = [];
      shortcardlist = this.CardsList;
      shortcardlist = shortcardlist.sort((a, b) =>
        a.DrugName.localeCompare(b.DrugName)
      );
      this.CardsList = shortcardlist;
    }
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      this.show_apple_wallet = true;
      console.log("Mac OS: ", this.show_apple_wallet);
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
      this.show_apple_wallet = true;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  SampleDiscountcard() {
    this.PharmacistHelpline = "844-616-9490";
    this.GroupNumber = window.localStorage.getItem("UserGroup").toUpperCase();
    this.MemberId = window.localStorage.getItem("UserCharId");
    this.TopText = "This is your generic card";
    this.HeadingText = "For use at any major retail pharmacy and save!";
    this.RXBin = "";
    this.RXCPN = "";
    this.PAddress2 = "";
    this.PharmacyPhone = "";
    try {
      this.ms
        .GetDefaultCard(window.localStorage.getItem("WhiteName"))
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (data.length > 0) {
              if (
                this.MemberId == null ||
                this.MemberId == undefined ||
                this.MemberId == "" ||
                this.MemberId == "null"
              ) {
                this.MemberId = "N/A";
              }
              this.TopText = data[0].toptext;
              this.HeadingText = data[0].headingtext;
              this.RXBin = data[0].bin;
              this.RXCPN = data[0].pcn;
              this.SampleDiscountCard = true;
            } else {
              this.TopText = "This is your generic card";
              this.HeadingText =
                "For use at any major retail pharmacy and save!";
              this.RXBin = "";
              this.RXCPN = "";
              this.PAddress2 = "";
              this.PharmacyPhone = "";
              this.SampleDiscountCard = true;
            }
          },
          (error) => { }
        );
    } catch {
      this.SampleDiscountCard = true;
    }
  }
  CloseDrugDetails() {
    this.showcard = false;
    this.SampleDiscountCard = false;
  }
  GetUserCardList() {
    // ;
    if (
      // this.rs.IsLoginRequired() == "Yes" &&
      window.localStorage.getItem("UserId") != null &&
      window.localStorage.getItem("UserId") != "" &&
      window.localStorage.getItem("UserId") != undefined &&
      window.localStorage.getItem("UserId") != "null"
    ) {
      try {
        this.ms
          .GetUserCards(window.localStorage.getItem("UserId"))
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                this.CardsList = data;
              }
            },
            (error) => { }
          );
      } catch { }
    } else if (
      this.rs.IsLoginRequired() == "Yes" &&
      window.localStorage.getItem("SkipSignup") == "signupskipped"
    ) {
      try {
        this.CardsList = JSON.parse(
          window.localStorage.getItem("CardListLocally")
        );
        ;
        this.CardsList = this.CardsList.reverse();
        ;
      } catch { }
    } else if (
      this.rs.IsLoginRequired() == "No" &&
      window.localStorage.getItem("UserId") != null &&
      window.localStorage.getItem("UserId") != undefined
    ) {
      try {
        this.ms
          .GetUserCards(window.localStorage.getItem("UserId"))
          // .pipe(map((res) => res.json()))
          .subscribe((data) => {
            const RemoveDuplicateData = data.reduce((acc, current) => {
              const x = acc.find(
                (item) =>
                  item.DrugName === current.DrugName &&
                  item.Form === current.Form &&
                  item.Price === current.Price &&
                  item.Dose === current.Dose
              );
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            this.CardsList = RemoveDuplicateData;
          });
      } catch { }
    } else {
      try {
        this.CardsList = JSON.parse(
          window.localStorage.getItem("CardListLocally")
        );
        this.CardsList = this.CardsList.reverse();
      } catch { }
    }
  }
  SortcardsList(SortParam: string) {
    this.sortbyddl = false;
    if (SortParam == "Distance") {
      this.CardsList = this.CardsList.sort((a, b) =>
        a.Fdistance <= b.Fdistance ? -1 : 1
      );
      this.CardsList = this.CardsList.sort((a, b) =>
        a.Fdistance <= b.Fdistance ? -1 : 1
      );
    } else if (SortParam == "Price") {
      this.CardsList = this.CardsList.sort((a, b) =>
        a.Price <= b.Price ? -1 : 1
      );
      this.CardsList = this.CardsList.sort((a, b) =>
        a.Price <= b.Price ? -1 : 1
      );
    }
  }
  RedirectGoogleDirection(item) {
    try {
      let DirectionLink =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        window.localStorage.getItem("UserLat") +
        "," +
        window.localStorage.getItem("UserLong") +
        "&destination=" +
        item;
      window.open(DirectionLink, "_blank");
    } catch { }
  }
  RemoveCard(item, price, drug, dose, form, pid) {
    // ;
    this.SelectedCardId = item;
    this.SelectedCardPrice = price;
    this.SelectedCardDrug = drug;
    this.SelectedCardDose = dose;
    this.SelectedCardForm = form;
    this.SelectedPharmacyId = pid;

    if (this.rs.IsLoginRequired() == "Yes") {
      // ;
      this.ms
        .RemoveSavedCard(
          window.localStorage.getItem("UserId"),
          this.SelectedCardId
        )
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          try {
            this.ms
              .GetUserCards(window.localStorage.getItem("UserId"))
              // .pipe(map((res) => res.json()))
              .subscribe(
                (data) => {
                  if (data.length > 0) {
                    this.CardsList = data;
                    this.RemoveDiscountCard_login(this.SelectedCardId);
                  } else this.CardsList = [];
                },
                (error) => { }
              );
          } catch { }
        });
      this.CloseDrugDetails();
    } else if (
      this.rs.IsLoginRequired() == "No" &&
      window.localStorage.getItem("UserId") != null &&
      window.localStorage.getItem("UserId") != undefined
    ) {
      this.ms
        .RemoveSavedCard(
          window.localStorage.getItem("UserId"),
          this.SelectedCardId
        )
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          try {
            this.ms
              .GetUserCards(window.localStorage.getItem("UserId"))
              // .pipe(map((res) => res.json()))
              .subscribe(
                (data) => {
                  if (data.length > 0) {
                    this.CardsList = data;
                    this.RemoveDiscountCard_login(this.SelectedCardId);
                    this.showcard = false;
                  } else this.CardsList = [];
                },
                (error) => { }
              );
          } catch { }
        });
      this.CloseDrugDetails();
    } else {
      try {
        let pid = this.SelectedPharmacyId;
        let Price = this.SelectedCardPrice;
        let SelectedDrugName = this.SelectedCardDrug;
        let DrugDose = this.SelectedCardDose;
        let DrugType = this.SelectedCardForm;
        let Original_CardList = JSON.parse(
          window.localStorage.getItem("CardListLocally")
        );
        try {
          const index = Original_CardList
            .findIndex(user => { return user == Price });
          var result = Original_CardList.filter(function (v) {
            return !(
              v["PharmacyId"] == pid &&
              v["DrugName"] == SelectedDrugName &&
              v["Price"] == Price &&
              v["Dose"] == DrugDose &&
              v["Form"] == DrugType
            );
          });
          window.localStorage.setItem(
            "CardListLocally",
            JSON.stringify(result)
          );
          let ss = JSON.parse(window.localStorage.getItem("CardListLocally"));

          this.CardsList = JSON.parse(
            window.localStorage.getItem("CardListLocally")
          );
          this.showcard = false;
        } catch { }
      } catch { }
    }
  }
  PharmacyHelpLine() {
    this.PharmacistHelpline = this.HelpLine;
  }
  PrintMe() {
    this.PharmacistHelpline = this.HelpLine;
    setTimeout(() => {
      window.print();
    }, 600);
  }
  ClearMessage(event: any) {
    if (event.target.name == "ShareEmail") {
      this.SendDiscountMessage = "";
      $(".email_field").removeClass("error_msg");
    }
    if ($(".sendbtn").hasClass("success")) {
      $(".sendbtn").removeClass("success").text("Get Discount!");
    }
  }
  clearClassMsg() {
    $(".email_field").removeClass("error_msg");
    this.SendDiscountMessage = "";
  }
  ShareDiscountCard(
    Card_DrugName,
    Card_Quantity,
    Card_DrugForm,
    Card_Dosage,
    PharmacyPrice,
    PharmacyName,
    PAddress1,
    PAddress3,
    PharmacyPhone,
    event
  ) {
    // ;
    this.MemberId = window.localStorage.getItem("UserCharId");
    let RXBin = $(event.target)
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".card_box .card_box_body .bin_pcn_member_id >div")
      .eq(0)
      .find("div")
      .text();
    let RXCPN = $(event.target)
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".card_box .card_box_body .bin_pcn_member_id >div")
      .eq(1)
      .find("div")
      .text();
    let PharmacistHL = $(event.target)
      .parent()
      .parent()
      .parent()
      .find(".address_number_box .phonenumber a")
      .text();
    let memberid = $(event.target)
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".card_box .card_box_body .bin_pcn_member_id >div")
      .eq(3)
      .find("div")
      .text();
    let groupno = $(event.target)
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".card_box .card_box_body .bin_pcn_member_id >div")
      .eq(2)
      .find("div")
      .text();
    $(".email_field").removeClass("error_msg");
    let email_REGEX = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    if (this.ms.IsNullOrEmptyField(this.UserInputEmail) == true) {
      $(".email_field").addClass("error_msg");
      this.SendDiscountMessage = "Email is required";
      return false;
    } else if (email_REGEX.test(this.UserInputEmail) == false) {
      $(".email_field").addClass("error_msg");
      this.SendDiscountMessage = "Please enter correct email or valid email";
      return false;
    } else {
      $(".loadProcess").addClass("loader");
      let ShareCard_Params =
        Card_DrugName +
        "|" +
        Card_DrugForm +
        "|" +
        Card_Dosage +
        "|" +
        Card_Quantity +
        "|" +
        PharmacyPrice +
        "|" +
        PharmacyName +
        "|" +
        PAddress1 +
        "|" +
        PAddress3 +
        "|" +
        this.UserInputEmail;
      this.googleAnalyticsService.eventEmitter(
        "MyCards Page",
        "Share Discount Card",
        ShareCard_Params,
        0
      );

      this.ms
        .SaveUserShareCard(
          window.localStorage.getItem("UserId"),
          memberid,
          Card_DrugName,
          Card_Quantity,
          Card_DrugForm,
          Card_Dosage,
          PharmacyPrice,
          PharmacyName,
          PAddress1,
          PAddress3,
          PharmacyPhone,
          RXBin,
          RXCPN,
          groupno,
          this.UserInputEmail,
          PharmacistHL,
          window.localStorage.getItem("UserLat") +
          "," +
          window.localStorage.getItem("UserLong")
        )
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          if (data.length > 0) {
            // ;
            if (data[0].Status == "Success") {
              $(".loadProcess").css({ display: "none" });
              $(".loadProcess").removeClass("loader");
              this.SendDiscountMessage = "";
              this.UserInputEmail = null;
              this.showshare = false;
              $(".sendbtn").addClass("success").text("Check Your Email!");
            } else {
              this.SendDiscountMessage = "Please try after sometime.";
              $(".email_field").addClass("error_msg");
            }
          } else {
            this.SendDiscountMessage = "Please try after sometime.";
            $(".email_field").addClass("error_msg");
          }
        });

      // var node = event.target.previousElementSibling.previousElementSibling;
      // node.style.opacity = '1';
      // var img;
      // var filename;
      // var newImage;
      // var dataurl;


      // domtoimage.toPng(node, { bgcolor: '#fff' })

      //   .then(function (dataUrl) {
      //     console.log(dataUrl)
      //     node.style.opacity = '0';
      //     dataurl = dataUrl;
      //     img = new Image();
      //     img.src = dataUrl.split(',')[1];
      //     newImage = img.src;
      //     img.onload = function () {
      //       var doc;

      //       var pdf = new jspdf('l', 'pt', [event.target.previousElementSibling.previousElementSibling.offsetWidth, event.target.previousElementSibling.previousElementSibling.offsetHeight]);
      //       pdf.addImage(dataUrl, 'PNG', 0, 0, event.target.previousElementSibling.previousElementSibling.offsetWidth, event.target.previousElementSibling.previousElementSibling.offsetHeight);
      //       //pdf.save(name+'.pdf');


      //     };
      //   })
      //   .catch(function (error) {
      //     // Error Handling

      //   });
      // setTimeout(() => {
      //   this.sendattached(dataurl.replace('data:image/png;base64,', ''))
      // }, 1000);
    }
  }
  sendattached(dataUrl) {
    this.ApiService.addToMailingListt(
      this.UserInputEmail,
      window.localStorage.getItem('FirstName'),
      '',
      window.localStorage.getItem("UserGroup").toUpperCase(),
      '',
      dataUrl,
      this.MemberId
    )
      // .pipe(map((res) => res.json()))

      .subscribe((data) => {
        $(".loadProcess").css({ display: "none" });
        $(".loadProcess").removeClass("loader");
        this.UserInputEmail = null;
        this.SendDiscountMessage = "";
        this.UserInputEmail = "";
        $(".btnsnd").addClass("success").text("Check Your Email! ");

      });
  }
  RemoveDiscountCard_login(CardId: string) {
    try {
      let Original_CardList_Login = JSON.parse(
        window.localStorage.getItem("CardListLocally_Login")
      );
      let Filter_CardList_Login = Original_CardList_Login.filter((item) => {
        return item["CardId"] != CardId;
      });
      window.localStorage.setItem(
        "CardListLocally_Login",
        JSON.stringify(Filter_CardList_Login)
      );
      this.showcard = false;
    } catch { }
  }
  printcard(event) {
    let groupno = event.target.parentElement.parentElement.previousElementSibling.children[0].lastChild.children[1].children[2].children[1].innerHTML;
    let medicine = event.target.parentElement.parentElement.parentElement.children[0].children[0].innerHTML;
    window.open('/printmycard?source=web&groupno=' + groupno + '&med=' + medicine);
  }
}
