<!-- // banner section \\ -->
<section class="banner-section needHelpHeader">
  <img class="img-responsive" src="../../../assets/images/contact-us-bg.jpg" alt="about-us-banner" />
  <div class="comman-width">
    <div class="over">
      <h2>Customer Support</h2>
      <p>
        Need Assistance? We are here to help. Any time of day, all 7 days a
        week, our<br />
        support is here for you.
      </p>
    </div>
  </div>
</section>
<!-- \\ banner section // -->

<!-- // contact section \\ -->
<section class="contact-section support-new helpSecTwo">
  <div class="comman-width">
    <div class="content d-flex">
      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
        <img src="../../../assets/images/email-support.svg" alt="email-support" />
      </div>
      <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
        <h4>Email Support</h4>
        <p>Read through popular questions and answers about ValpakRx™.</p>
        <a class="u" href="mailto:support@valpakrx.com">support@valpakrx.com</a>
      </div>
    </div>
  </div>
</section>
<!-- \\ contact section // -->

<!-- // contact section \\ -->
<section class="contact-section support-new2">
  <div class="comman-width">
    <div class="content">
      <form>
        <div class="left">
          <h2>Write to us</h2>
          <div class="form-group">
            <input type="text" (focus)="focusValidation('name')" name="name" id="name_" class="form-control"
              placeholder=" " autocomplete="off" required />
            <label for="name_">Name</label>
            <div class="colorRed" *ngIf="emptyInput">Please enter name</div>
          </div>
          <div class="form-group">
            <input type="email" name="email" id="email_" (focus)="focusValidation('email')" class="form-control"
              placeholder=" " [(ngModel)]="emailmodel" autocomplete="off" required />
            <label for="email_">Email</label>
            <div class="colorRed" *ngIf="emptyEmail">Please enter email</div>
            <div class="colorRed" *ngIf="invalidEmail">Invalid email</div>
          </div>
          <div class="form-group destop">
            <label class="checkbox-container">I am not robot
              <input value="checked" name="captcha" type="checkbox" (change)="checkboxFocusValidation()" />
              <span class="checkmark"></span>
            </label>
            <div class="colorRed" *ngIf="emptyCheckbox" style="margin-top:30px;">
              Please checked it
            </div>
          </div>
        </div>
        <div class="right">
          <div class="form-group fourth">
            <textarea name="message" (focus)="focusValidation('message')" id="message" placeholder=" "
              class="form-control"></textarea>
            <label for="message">Message</label>
            <div class="colorRed" *ngIf="emptyMessage">
              Please enter message
            </div>
          </div>
          <div class="form-group mobile">
            <label class="checkbox-container">I am not a robot
              <input value="checked" name="captcha" type="checkbox" (change)="checkboxFocusValidation()" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="" id="contactsucess">
            Thank you for contacting us.
          </div>
          <button type="submit" class="btn button btn-hover-effect" (click)="validate()">
            <div class="insider"></div>
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- \\ contact section // -->

<!-- // accordian section \\ -->
<section class="accordian-section helpSecFour">
  <div class="comman-width">
    <app-accordian></app-accordian>
  </div>
</section>
<!-- \\ accordian section // -->
<app-getcard></app-getcard>