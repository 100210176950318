import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdflink',
  templateUrl: './pdflink.component.html',
  styleUrls: ['./pdflink.component.scss']
})
export class PdflinkComponent implements OnInit {
  private pdfFileName = 'TracFone-GlicRx-Card.pdf';
  constructor() { }

  ngOnInit() {
  }
  getPDFUrl(): string {
    // Use the relative path to the PDF file in the assets folder
    return 'assets/cards/' + this.pdfFileName;
  }
}
