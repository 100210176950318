import { Component, OnInit } from '@angular/core';
import { ApiService } from "../services/api.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-printmycard',
  templateUrl: './printmycard.component.html',
  styleUrls: ['./printmycard.component.scss'],
  providers: [ApiService],
})
export class PrintmycardComponent implements OnInit {
  public source: any = "";
  public groupno: any = "";
  public bin: any = "";
  public pcn: any = "";
  public memberid = "";
  public groupno_valid: any = "";
  public medicineName:any="";

  constructor(public ApiService: ApiService, private activeRoute: ActivatedRoute, public router: Router,) {
    this.activeRoute.queryParams.subscribe((qp) => {
      this.source = qp.source;
      this.groupno = qp.groupno;
      this.medicineName = qp.med;
    })
  }

  ngOnInit() {
    this.printcard(this.groupno)
  }
  printcard(grpno) {
    this.ApiService.printcard(grpno)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
          // group number condition
          let member_Id = '100-4542';
          let wholesalername = window.localStorage.getItem('WhName')
          if (wholesalername == "Avenel" && this.groupno != 'GST1016') {
            member_Id = "12345"
          }
          if(this.groupno === 'GST1016'){
            member_Id = '48786';
          }
        
        if (data[0].status == true) {
          if (this.source == "" || (this.source != "web" && this.source != "ecenter" && this.source != "mobile")) {
            this.bin = data[0].def[0].bin;
            this.pcn = data[0].def[0].pcn;
            this.groupno_valid = data[0].gn;
            // group number condition
            if(this.groupno === 'GST1016'){
              this.memberid = '48786';
            } else {
              this.memberid = data[0].def[0].member_Id;
            }
            setTimeout(() => {
              this.printPage();
            }, 300);
          }
          if (this.source == "mobile") {
            this.bin = data[0].mobile[0].bin;
            this.pcn = data[0].mobile[0].pcn;
            this.groupno_valid = data[0].gn;
            // group number condition
            if(this.groupno === 'GST1016'){
              this.memberid = '48786';
            } else {
              this.memberid = data[0].mobile[0].member_Id;
            }
            setTimeout(() => {
              this.printPage();
            }, 300);
          }
          if (this.source == "web") {
            this.bin = data[0].web[0].bin;
            this.pcn = data[0].web[0].pcn;
            this.groupno_valid = data[0].gn;
            // group number condition
            if(this.groupno === 'GST1016'){
              this.memberid = '48786';
            } else {
              this.memberid = data[0].web[0].member_Id;
            }
            setTimeout(() => {
              this.printPage();
            }, 300);
          }
          if (this.source == "ecenter") {
            this.bin = data[0].ecenter[0].bin;
            this.pcn = data[0].ecenter[0].pcn;
            this.groupno_valid = data[0].gn;
            // group number condition
            if(this.groupno === 'GST1016'){
              this.memberid = '48786';
            } else {
              this.memberid = data[0].ecenter[0].member_Id;
            }
            setTimeout(() => {
              this.printPage();
            }, 300);
          }
          if (this.source == "api") {
            this.bin = data[0].api[0].bin;
            this.pcn = data[0].api[0].pcn;
            this.groupno_valid = data[0].gn;
            // group number condition
            if(this.groupno === 'GST1016'){
              this.memberid = '48786';
            } else {
              this.memberid = data[0].api[0].member_Id;
            }
            setTimeout(() => {
              this.printPage();
            }, 300);
          }
        }
        else {
          this.router.navigate(['/']);
        }
      }
      )
  }
  printPage() {
    window.print();
  }
}
