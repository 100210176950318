import { Component, OnInit } from '@angular/core';
import { MedicinService } from "../services/medicin.service";
import { map, tap } from "rxjs/operators";
import * as $ from 'jquery';

@Component({
    selector: 'app-recover-access-code',
    templateUrl: './recover-access-code.component.html',
    styleUrls: ['./recover-access-code.component.scss'],
    providers: [MedicinService]
})
export class RecoverAccessCodeComponent implements OnInit {

    emptyEmail: boolean = false;
    loader: boolean = false;
    emailmodel: any = "";
    incorrect: boolean = false;
    errorEmail: any = '';
    constructor(private med: MedicinService) { }

    ngOnInit() {
        $('body').addClass('loginPage access');
    }
    ngOnDestroy() {
        $('body').removeClass('loginPage access');
    }

    validate() {
        this.loader = true;
        var email = this.emailmodel;
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.emailmodel = this.emailmodel.replace(/\s/g, '')

        /* email validation */
        if (!this.emailmodel.match(emailReg)) {
            this.emptyEmail = true;
            this.loader = false;
            $("#email_").addClass('errorIcon');
            this.errorEmail = "Invalid email!"
        }
        if (email == null || email == undefined || email == "") {
            this.emptyEmail = true;
            this.loader = false;
            $("#email_").addClass('errorIcon');
            this.errorEmail = "Field can't be blank!"
        }
        if (
            !(email == null || email == undefined || email == "") &&
            !(emailReg.test(this.emailmodel) == false)
        ) {
            $("#email_").removeClass('errorIcon');
            this.errorEmail = '';
            /*
            this.ms.brokerLogin(this.emailmodel, password)
                // .pipe(map((res) => res.json()))
                .subscribe(data => {
                    try {
                        if (data[0].success == true) {
                            this.loader = false;
                            this.router.navigate(['/enrollmentcenter'])
                        } else {
                            this.incorrect = true;
                            this.loader = false;
                        }
                    } catch (error) {
                        this.incorrect = true;
                        this.loader = false;
                    }
                })
                */
            this.med.recoverAccesscode(this.emailmodel)
            // .pipe(map((res) => res.json()))
                .subscribe(data => {
                    if (data[0].success == true) {
                        this.emailmodel = '';
                        this.emptyEmail = true;
                        this.errorEmail = data[0].detail[0].messagedetail;
                        this.loader = false;
                    }
                    else {
                        this.emailmodel = '';
                        this.emptyEmail = true;
                        this.errorEmail = data[0].detail[0].messagedetail;
                        this.loader = false;
                        $("#email_").addClass('errorIcon');
                    }
                })
        }
    }
    removeerror(event) {
        $("#email_").removeClass('errorIcon');
        this.errorEmail = '';
    }
}
