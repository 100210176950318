<!-- // login section \\ -->
<section class="login-section">
  <div class="comman-width">
    <a routerLink="/enrollmentcenter/login" routerLinkActive="" class="logo">
      <img loading="lazy" src="../assets/images/header_logo.svg"
           alt="hdrx-logo" />
    </a>
    <form method="post" name="recover_access_code" action="javascript:;" id="recoverForm">
      <label>Recover Your Access Code</label>      
      <input type="email" [(ngModel)]="emailmodel" name="email" id="email_" class="form-control" placeholder="Enter your registered agent email address" (keyup)="removeerror($event)" autocomplete="off" required />
      <div class="colorRed" *ngIf="emptyEmail">
        {{errorEmail}}
      </div>
      
      <div class="btn-section">
        <button type="submit" class="btn"
          (click)="validate()">
          Recover Access Code
        </button>
        <div *ngIf="loader" class="load-container"><div class="theme-loader"></div></div>
      </div>
    </form>
  </div>
</section>
